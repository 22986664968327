import React, {forwardRef, useImperativeHandle, useState} from 'react'

import {Form, Input, Radio, Spin, Select, Modal, App} from "antd";
import {FileSelect} from "../../components";
import {useDict, useHttps} from "../../hooks";
import Editor from "../../components/editor";
import {articleCategoryState} from "../../store";
import {useRecoilValue} from "recoil";

const Create = forwardRef((props: { onChange: (e: ArticleType.Data) => void; }, ref) => {
    const { message } = App.useApp();
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const {post} = useHttps();
    const [form] = Form.useForm();
    const category = useRecoilValue<ArticleType.Category[]>(articleCategoryState)
    const {getDict} = useDict();

    useImperativeHandle(ref, () => ({
        onOpen: () => {
            setOpen(true)
        },
    }));


    const onFinish = async () => {
        try {
            let params = await form.validateFields();
            setLoading(true)
        post('admin/article', params)
            .then(async (res:Request.Response<ArticleType.Data>) => {
                if (res.mounted) {
                    setLoading(false)
                    props.onChange(res.data)
                    setOpen(false)
                }

            })
            .catch(async (e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
        } catch (err) {
            return;
        }
    }

    const onCancel = () => {
        setOpen(false)
    }


    return (
        <Modal destroyOnClose title="添加文章" width={960} okText='添加' open={open} onOk={onFinish} centered onCancel={onCancel} okButtonProps={{disabled: loading }} >
            <Spin size="small" spinning={loading}>

                        <Form
                            preserve={false}
                            form={form}
                            labelCol={{span: 4}}
                            wrapperCol={{span: 18}}
                            name="basic"
                            initialValues={{
                                status: '1',
                                content: "",
                                thumb: "",
                                description: '',
                                category_id: ''
                            }}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                label="文章标题"
                                name="title"
                                rules={[{required: true, message: '请输入标题！'}]}
                            >
                                <Input placeholder='请输入文章标题'/>
                            </Form.Item>
                            <Form.Item
                                label="封面图"
                                name='thumb'
                                extra="上传尺寸建议980x460"
                                className="mt-3"
                                rules={[{required: true, message: '请选择图片！'}]}
                            >
                                <div className='w-100'>
                                    <FileSelect
                                        listType='picture-card'
                                        text='上传封面'
                                        size={1}
                                        type={1}
                                        onChange={(e) => {
                                            form.setFieldsValue({thumb: e[0]?.url || ''})
                                        }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{span: 8}}
                                name="category_id"
                                label="选择分类"
                                rules={[{required: true, message: '请选择分类！'}]}
                            >
                                <Select placeholder="选择文章分类" >
                                    <Select.Option key={0} value={''}>全部分类</Select.Option>
                                    {category.map((item) => <Select.Option key={item.id}
                                                                                  value={item.id}>{item.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="status"
                                label="是否显示"
                            >
                                <Radio.Group>
                                    {getDict('is_show').map((item, index) => <Radio key={index}  value={item.value}>{item.label}</Radio>)}
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item name='description' label="内容摘要">
                                <Input.TextArea/>
                            </Form.Item>

                            <Form.Item label="文章内容" name="content"
                                       rules={[{
                                           required: true,
                                           message: '请输入20字符以上内容。！',
                                           validator: async (rule, value) => {
                                               if (value.length <= 20) {
                                                   return Promise.reject()
                                               }
                                           }
                                       }
                                       ]}
                            >
                                <Editor
                                    value=''
                                    height={280}
                                    onChange={(val) => {
                                        form.setFieldsValue({content: val})
                                    }}
                                />
                            </Form.Item>
                        </Form>
            </Spin>
        </Modal>
    )
})
export default Create;
