/**
 * Created by devin on 2021/4/21.
 */

import React, {useEffect, useState} from 'react'


import {App, Card, Col, Descriptions, Row, Skeleton, Space, Typography} from 'antd';
import {useHttps} from "../../../hooks";
import {Liquid} from '@ant-design/plots';
const {Title, Text} = Typography;


const Monitor = () => {
    const { message } = App.useApp();
    const {get} = useHttps();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<any>()


    /**
     * 数据请求
     * @param {Object} params
     */
    useEffect(() => {
        get('admin/monitor', null, true)
            .then((res: Request.Response<any>) => {
                if (res.mounted) {
                    setLoading(false);
                    setData(res.data);
                }

            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    message.error(e.message)
                    setLoading(false);
                }
            });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get])

    if (loading) {
        return (
            <Card className='mb-3'>
            <div className='flex-grow-1'>
                <Skeleton.Input className='mb-4 d-block'/>
                <Skeleton className='mb-4'/>
                <Skeleton.Input className='mb-4 d-block'/>
                <Skeleton paragraph={{rows: 6}}/>
            </div>
            </Card>
        )
    }
    /**
     * 返回视图
     */

    // @ts-ignore
    return (
        <>
            <Card className='mb-3'>
                <Descriptions title="系统信息">
                    <Descriptions.Item span={3}  label="操作系统">{data.sys.os}</Descriptions.Item>
                    <Descriptions.Item label="ip地址">{data.sys.ip}</Descriptions.Item>
                    <Descriptions.Item label="已运行时间">{data.sys.day}</Descriptions.Item>
                </Descriptions>
            </Card>

            <Card className='mb-3'>
                <Row gutter={16} justify='space-between' className='mb-3'>
                    <Col>
                        <Space direction='vertical' align='center'>
                            <Title level={5}>CPU使用率</Title>
                            <Liquid
                                height={180}
                                width={180}
                                autoFit={false}
                                percent={parseFloat(data.cpu.used) / 100}
                                style={{
                                    outlineBorder: 4,
                                    outlineDistance: 8,
                                    waveLength: 128,
                                }}
                            />
                            <Text type='secondary'>{data ? data.cpu.logic : '核心数'}</Text>
                        </Space>
                    </Col>
                    <Col>
                        <Space direction='vertical' align='center'>
                            <Title level={5}>硬盘使用率</Title>
                            <Liquid
                                height={180} width={180}
                                autoFit={false}
                                percent={parseFloat(data.disk.usageRate) / 100}
                                style={{
                                    outlineBorder: 4,
                                    outlineDistance: 8,
                                    waveLength: 128,
                                }}
                            />
                            <Text type='secondary'>硬盘总计{data.disk.total}</Text>
                        </Space>
                    </Col>
                    <Col>
                        <Space direction='vertical' align='center'>
                            <Title level={5}>内存使用率</Title>
                            <Liquid
                                height={180} width={180}
                                autoFit={false}
                                percent={parseFloat(data.memory.usageRate) / 100}
                                style={{
                                    outlineBorder: 4,
                                    outlineDistance: 8,
                                    waveLength: 128,
                                }}
                            />
                            <Text type='secondary'>内存总计{data.memory.total}</Text>
                        </Space>
                    </Col>
                    <Col>
                        <Space direction='vertical' align='center'>
                            <Title level={5}>交换区使用率</Title>
                            <Liquid
                                height={180} width={180}
                                autoFit={false}
                                percent={parseFloat(data.swap.usageRate) / 100}
                                style={{
                                    outlineBorder: 4,
                                    outlineDistance: 8,
                                    waveLength: 128,
                                }}
                            />
                            <Text type='secondary'>交换区总计{data.swap.total}</Text>
                        </Space>
                    </Col>
                </Row>
            </Card>

            <Card className='mb-3'>
                <Descriptions title="CPU信息">
                    <Descriptions.Item label="cup型号">{data.cpu.name}</Descriptions.Item>
                    <Descriptions.Item label="核心数">{data.cpu.core}</Descriptions.Item>
                    <Descriptions.Item label="逻辑cpu">{data.cpu.logic}</Descriptions.Item>
                </Descriptions>
            </Card>
            <Card className='mb-3'>
                <Descriptions title="硬盘信息">
                    <Descriptions.Item label="总大小">{data.disk.total}</Descriptions.Item>
                    <Descriptions.Item label="已使用">{data.disk.used}</Descriptions.Item>
                    <Descriptions.Item label="挂在路径">{data.disk.mount}</Descriptions.Item>
                </Descriptions>
            </Card>
            <Card className='mb-3'>
                <Descriptions title="内存信息">
                    <Descriptions.Item label="内存总计">{data.memory.total}</Descriptions.Item>
                    <Descriptions.Item label="已使用">{data.memory.used}</Descriptions.Item>
                    <Descriptions.Item label="可用内存">{data.memory.available}</Descriptions.Item>
                </Descriptions>
            </Card>
        </>

    )
}
export default Monitor;