/**
 * Created by devin on 2021/4/21.
 */

import React, {useEffect, useState, useCallback, useRef} from 'react'

import {
    Button,
    Divider,
    Input,
    message,
    Select,
    Space,
    Table,
    Typography,
    Card, Tag, DatePicker, Popconfirm
} from 'antd';
import type {ColumnsType, TablePaginationConfig} from 'antd/es/table';

import {useDict, useHttps, usePermission} from "../../hooks";
import {hideMPStrict} from "../../utils/utils";
import dayjs from "dayjs";
import Account from './Account'
import {CheckOutlined, RollbackOutlined, DeleteOutlined, EditOutlined, PrinterOutlined} from "@ant-design/icons";

const {Text} = Typography;


interface ArchivesParams {
    page?: number;
    per_page?: number;
    start_date?: string;
    end_date?: string;
    status?: string;
    search?: string;
}

/*
  用户管理
*/
const Archives = () => {
    const {get, put, destroy} = useHttps();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<ArchivesType.Data[]>([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [search, setSearch] = useState('')
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [selectDate, setSelectDate] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>()
    const [selectStatus, setSelectStatus] = useState('-1')
    const {isPermission} = usePermission();
    const {getDictValue, getDict} = useDict();
    const accountRef =useRef<any>()
    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params:any) => {
        get('admin/archives', params, true)
            .then((res: Request.Response<ArchivesType.Data[]>) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message)
                }
            });
    }, [get])

    useEffect(() => {
        getData({page: 1, per_page: 15});
    }, [getData]);




    /**
     * 表格操作触发
     * @param pagination
     */
    const onPaginationChange = (pagination: TablePaginationConfig) => {

        const params: ArchivesParams = {
            page: pagination.current,
            per_page: pagination.pageSize,
        }
        //按日期区间查询
        if (selectDate) {
            const startDate = selectDate[0]?.format('YYYY-MM-DD HH:mm:ss');
            const endDate = selectDate[1]?.format('YYYY-MM-DD HH:mm:ss');
            params.start_date = startDate
            params.end_date = endDate
        }
        //按状态查询
        if (selectStatus !== '-1') {
            params.status = selectStatus;
        }
        //搜索
        if (search.length !== 0) {
            params.search = search;
        }
        setLoading(true);
        getData(params);
    }



    /**
     * 重置
     */
    const onReset = () => {
        setSelectDate(null)
        setSelectStatus('-1')
        setSearch('')
        setLoading(true)
        getData({page: 1, per_page: perPage});
    }


    /**
     * 删除档案
     * @param keys
     */
    const onDelete = (keys: React.Key[]) => {
    if (keys.length === 0) {
        return;
    }
    setLoading(true);
    destroy('admin/archives', keys)
        .then((res) => {
            if (res.mounted) {
                getData({page: current, per_page: perPage});
            }
        })
        .catch(async (e:Request.Error) => {
            if (e.mounted) {
                setLoading(false);
                message.error(e.message);
            }
        });
}
    /**
     * 自定义表格
     * @type {Object}
     */

    const columns: ColumnsType<ArchivesType.Data> = [
        {
            title: '姓名',
            dataIndex: 'name',
        },
        {
            title: '电话',
            dataIndex: 'tel',
            render: (mobile) => {
                return (
                    <Text>{hideMPStrict(mobile)}</Text>
                )
            }
        },
        {
            title: '学校',
            dataIndex: 'school',
        },
        {
            title: '政治面貌',
            dataIndex: 'politics',
        },

        {
            title: '状态',
            dataIndex: 'status',
            render: (status) => {
                const dict = getDictValue('archives_status', status)
                return (
                    <Tag color={dict?.style}>{dict?.label}</Tag>
                )
            }
        },
        {
            title: '申请日期',
            dataIndex: 'created_at',
        },
        {
            title: '操作',
            dataIndex: 'action',
            hidden: !isPermission('notice.post'),
            render: (key, item) => {
                return (
                    <div>
                        <Button className='me-2'  type="dashed" size='small' icon={<EditOutlined/>} onClick={()=>accountRef.current.onOpen(item.id)}/>
                        <Popconfirm title="确定删除?" onConfirm={() => onDelete([item.id])}>
                            <Button type="dashed" size='small' icon={<DeleteOutlined/>}/>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ];

    /**
     * 更新状态
     * @param status
     */
    const onUpdate = (status:string) => {
        setLoading(true);
        const dataKey = [...selectedRowKeys];
        put('admin/archives?status='+ status , dataKey)
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch(async (e:Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    }


    const showConfirm = () => {

    }


    return (
        <Card>
            <div className='mb-4 w-100'>
                <Space>
                    <Select style={{width:150}} defaultValue={'-1'} value={selectStatus} onChange={setSelectStatus}>
                        <Select.Option value={'-1'}>全部状态</Select.Option>
                        {getDict('archives_status').map((item,i)=> <Select.Option key={i} value={item.value}>{item.label}</Select.Option>)}
                    </Select>
                    <DatePicker.RangePicker value={selectDate} onChange={setSelectDate}/>
                    <Input.Search
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                        onSearch={() => {
                            onPaginationChange( {current: 1, pageSize: perPage})
                        }}
                        placeholder="手机号、姓名"
                        allowClear
                        enterButton="筛选"
                    />
                    <Button onClick={onReset}>
                        重置
                    </Button>
                    <Button type='primary' onClick={onReset}>
                        导出档案
                    </Button>
                </Space>
                <Divider/>
                    {isPermission('archives.post') && <Space className='mb-3'>
                        <Button onClick={() => onUpdate('2')}  icon={<CheckOutlined />}
                                disabled={selectedRowKeys.length === 0}
                                size='small'>通过</Button>
                        <Button onClick={() => onUpdate('0')} icon={<RollbackOutlined />}
                                disabled={selectedRowKeys.length === 0}
                                size='small'>撤回</Button>
                        <Button onClick={showConfirm} icon={<PrinterOutlined />}
                                disabled={selectedRowKeys.length === 0}
                                size='small'>打印</Button>
                        <span className='text-muted ms-3'>
                             {selectedRowKeys.length !== 0 ? `已选择 ${selectedRowKeys.length} 份档案` : ''}
                        </span>
                    </Space>}
                <Table
                    size="middle"
                    rowSelection={isPermission('archives.post') ?{
                        selectedRowKeys,
                        onChange: setSelectedRowKeys,
                    }: undefined}
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        size: 'default',
                        total: total,
                        current: current,
                        showTotal: total => `总计 ${total} 份档案`,
                        pageSize: perPage,
                    }}
                    onChange={(pagination) => onPaginationChange(pagination)}
                    rowKey={(record) => record.id.toString()}
                />
            </div>
            <Account ref={accountRef} />
        </Card>
    )
}

export default Archives
