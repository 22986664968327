import React, {useCallback, useEffect, useRef, useState} from 'react'
import {App, Avatar, Badge, Button, Drawer, List,  Space, Typography} from "antd";
import {BellOutlined, PlusOutlined} from '@ant-design/icons';
import {Icon} from "../../config/Icon";
import {useHttps} from "../../hooks";
import dayjs from "dayjs";
import {TablePaginationConfig} from "antd/es/table";
import NoticeDetails from "../system/notice/Details";

import {useRecoilValue} from "recoil";
import {websocketDataState} from "../../store";

const {Link, Text} = Typography


interface NoticeParams {
    page?: number;
    per_page?: number;
    status?: string;
}




/*
  头部组件
*/
const TopNotice = () => {
    const { message } = App.useApp();
    const websocketData = useRecoilValue<WebSocketType.Data<SystemType.Notice>>(websocketDataState);
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)
    const [total, setTotal] = useState(0)
    const [readState, setReadState] = useState<boolean>(false)
    const [data, setData] = useState<SystemType.Notice[]>([])
    const [hasMore, setHasMore] = useState(false)
    const [current, setCurrent] = useState(1)
    const perPage = 20;
    const noticeRef = useRef<any>()
    const {get} = useHttps();
    const {  notification} = App.useApp()
    useEffect(()=>{
        if(websocketData.code=== 1 && websocketData.action === 'notice'){
            const dataSource = [...data]
            dataSource.unshift(websocketData.message)
            setData(dataSource)
            setReadState(true)
            notification.info({
                message: '您有一条新消息',
                description: websocketData.message.title,
                placement:'topRight',
            })
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[websocketData])
    /*
     * 数据请求
     * @param params
     */
    const getData = useCallback((params: NoticeParams) => {
        get('admin/message', params, true)
            .then((res: Request.Response<SystemType.Notice[]>) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setHasMore(res.data.length < perPage)
                    setTotal(res.meta.total)
                    if (params.page === 1) {
                        setData(res.data);
                        //验证已读状态
                        const storageId = localStorage.getItem("readState");
                        if (res.data.length !== 0) {
                            if (String(res.data[0].id) !== storageId) {
                                setReadState(true)
                            }
                        }
                    } else {
                        setData((r) => r.concat(res.data))
                    }
                }

            })
            .catch(async (e:Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                }
            });
    }, [get])


    /**
     * 初始化数据
     */
    useEffect(() => {
        getData({page: 1, per_page: perPage});
    }, [getData]);


    /**
     * 表格操作触发
     * @param pagination
     */
    const onPaginationChange = (pagination: TablePaginationConfig) => {
        const params: NoticeParams = {
            page: pagination.current,
            per_page: pagination.pageSize,
        }
        getData(params);
    }


    /**
     * 设置已读状态
     */
    const onRead = async () => {
        //设置缓存状态
        if (data.length !== 0) {
            localStorage.setItem("readState", String(data[0].id));
            setReadState(false)
            setOpen(false)
            message.success('消息标记成功！')
        }
    }


    return (
        <>
            <Link onClick={() => setOpen(true)}><Badge dot={readState} className="cursor"><BellOutlined/></Badge></Link>
            <Drawer footer={
                <Space size='large'>
                    <Button
                        onClick={onRead}
                        type="primary"
                        icon={<PlusOutlined/>}
                    >
                        标记已读
                    </Button>
                    <Text>
                        {`总计 ${total} 条通知`}
                    </Text>
                </Space>

            } mask={false} destroyOnClose title="通知公告"
                    placement="right" onClose={() => setOpen(false)} open={open}>
                <div className='px-3'>
                    <List
                        itemLayout="horizontal"
                        loadMore={!loading && !hasMore ? <div className='text-center mt-2'><Link disabled={loading}
                                                                                                 onClick={() => onPaginationChange({
                                                                                                     current: current + 1,
                                                                                                     pageSize: perPage
                                                                                                 })}>查看更多</Link>
                        </div> : null}
                        dataSource={data}
                        renderItem={(item) => (
                            <List.Item className='cursor-pointer' onClick={() => noticeRef.current.onOpen(item)}>
                                <List.Item.Meta
                                    avatar={<Avatar src={item.type === '1' ? Icon.message : Icon.notice}/>}
                                    title={<Text ellipsis>{item.title}</Text>}
                                    description={dayjs(item.created_at).format('YYYY-MM-DD')}
                                />
                            </List.Item>
                        )}
                    />
                </div>


            </Drawer>
            <NoticeDetails ref={noticeRef}/>
        </>
    )
}
export default TopNotice
