import React, {useState, forwardRef, useImperativeHandle} from 'react'
import {
    Form,
    Input,
    Radio,
    Spin,
    Modal,
    Select,
    App,
} from "antd";

import {FileSelect} from "../../components";
import {useDict, useHttps, usePermission} from "../../hooks";
import Editor from "../../components/editor";
import {useRecoilValue} from "recoil";
import {articleCategoryState} from "../../store";


const Edit = forwardRef((props: { onChange: (e: ArticleType.Data) => void; }, ref) => {
    const { message } = App.useApp();
    const [id, setId] = useState(0);
    const [open, setOpen] = useState(false);
    const {put, get} = useHttps();
    const [thumb, setThumb] = useState<StorageType.Data[]>()
    const [loading, setLoading] = useState(true)
    const [content, setContent] = useState('');
    const [form] = Form.useForm()
    const category = useRecoilValue<ArticleType.Category[]>(articleCategoryState)
    const {getDict} = useDict();
    const {isPermission} = usePermission();
    const getData = (id: number) => {
        setLoading(true);
        get('admin/article/' + id, null, true)
            .then((res: Request.Response<ArticleType.Data>) => {
                if (res.mounted) {
                    setLoading(false);
                    form.setFieldsValue(res.data)
                    setThumb([{
                        id: '-1',
                        title: 'avatar.png',
                        type: 1,
                        mini_type: 'jpg',
                        url: res.data.thumb || '',
                        parent_id: '0',
                        size: 100,
                        created_at: new Date()
                    }])
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message)
                    setOpen(false)
                }
            });
    };


    useImperativeHandle(ref, () => ({
        onOpen: (id: number) => {
            setId(id)
            getData(id)
            setOpen(true)

        },
    }));

    /**
     * 更新文章内容
     */
    const onUpdate = async () => {
        try {
            let params = await form.validateFields();
            setLoading(true)
            put('admin/article/' + id, params)
                .then(async (res: Request.Response<ArticleType.Data>) => {
                    if (res.mounted) {
                        setLoading(false)
                        props.onChange(res.data)
                        setOpen(false)
                    }

                })
                .catch(async (e: Request.Error) => {
                    if (e.mounted) {
                        setLoading(false)
                        message.error(e.message);
                    }
                });
        } catch (err) {
            return;
        }

    }


    /**
     * 关闭模态框
     */
    const onCancel = () => {
        setLoading(false)
        setOpen(false)
    }

    return (
        <Modal destroyOnClose title="编辑内容" width={960} okText='保存' open={open} onOk={onUpdate} centered
               footer={!isPermission('article.post') ? null : undefined} onCancel={onCancel} okButtonProps={{disabled: loading}}>
            <Spin size="small" spinning={loading}>
                    <Form
                        disabled={!isPermission('article.post')}
                        form={form}
                        labelCol={{span: 4}}
                        wrapperCol={{span: 18}}
                        name="basic"
                        onFinish={onUpdate}
                    >
                        <Form.Item
                            label="文章标题"
                            name="title"
                            rules={[{required: true, message: '请输入标题！'}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="封面图"
                            name='thumb'
                            extra="上传尺寸建议980x460"
                            className="mt-3"
                            rules={[{required: true, message: '请选择缩略图！'}]}
                        >
                            <div className='w-100'>
                                <FileSelect
                                    defaultFileList={thumb}
                                    listType='picture-card'
                                    text='上传封面'
                                    size={1}
                                    type={1}
                                    onChange={(e) => {
                                        form.setFieldsValue({thumb: e[0]?.url || ''})
                                    }}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{span: 8}}
                            name="category_id"
                            label="选择分类"
                            rules={[{required: true, message: '请选择分类！'}]}
                        >
                            <Select placeholder="选择文章分类" fieldNames={{label: 'name', value: 'id'}}  options={category} />
                        </Form.Item>

                        <Form.Item
                            name="status"
                            label="是否显示"
                        >
                            <Radio.Group>
                                {getDict('is_show').map((item, index) => <Radio key={index}
                                                                                value={item.value}>{item.label}</Radio>)}
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name='description' label="摘要">
                            <Input.TextArea/>
                        </Form.Item>
                        <Form.Item label="文章内容" name="content"
                                   rules={[{
                                       required: true,
                                       message: '请输入20字符以上内容。！',
                                       validator: async (rule, value) => {
                                           if (value.length <= 20) {
                                               return Promise.reject()
                                           }
                                       }
                                   }
                                   ]}
                        >
                            <Editor
                                height={280}
                                disabled={!isPermission('article.post')}
                                value={content}
                                onChange={(val) => {
                                    setContent(val)
                                    form.setFieldsValue({content: val})
                                }}
                            />
                        </Form.Item>

                    </Form>
            </Spin>
        </Modal>
    )
})
export default Edit;
