/**
 * Created by devin on 2021/4/21.
 */

import React, {useCallback, useEffect, useRef, useState} from 'react'

import {useRecoilState} from 'recoil';

import {
    Button,
    Card,
    Col,
    Divider,
    Input,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Typography
} from 'antd';
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    PlusOutlined
} from '@ant-design/icons';
import type {ColumnsType, TablePaginationConfig} from 'antd/es/table';
import {useDict, useHttps, usePermission} from "../../hooks";
import {articleCategoryState} from "../../store";
import Edit from "./Edit";
import Create from "./Create";
const {Paragraph} = Typography;

interface ArticleParams {
    page?: number;
    per_page?: number;
    status?: string;
    search?: string;
    category_id?: number;

}

const Article = () => {
    const {put, get, destroy} = useHttps();
    const [loading, setLoading] = useState(true)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [data, setData] = useState<ArticleType.Data[]>([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [selectCategory, setSelectCategory] = useState(0)
    const [selectStatus, setSelectStatus] = useState('-1')
    const [search, setSearch] = useState('')
    const {isPermission} = usePermission();
    const {getDictValue, getDict} = useDict();
    const [category, setCategory] = useRecoilState<ArticleType.Category[]>(articleCategoryState);
    const createRef = useRef<any>()
    const editRef = useRef<any>()
    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params: any) => {
        get('admin/article', params, true)
            .then((res:Request.Response<ArticleType.Data[]>) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                    setSelectedRowKeys([])
                }

            })
            .catch(async (e) => {
                if (e.mounted) {
                    message.error(e.message)
                    setLoading(false);
                }
            });
    }, [get])


    useEffect(() => {
        getData({page: 1, per_page: 15});
    }, [getData]);



    /**
     * 分类数据
     * @param {Object} params
     */

    useEffect(() => {
        get('admin/article/category', null, true)
            .then((res:Request.Response<ArticleType.Category[]>) => {
                if (res.mounted) {
                    setCategory(res.data);
                }
            })
            .catch(async (e:Request.Error) => {
                if (e.mounted) {
                    message.error(e.message)
                }
            });
    }, [get, setCategory]);


    /**
     * 自定义表格
     * @type {Object}
     */

    const columns: ColumnsType<ArticleType.Data> = [
        {
            title: '标题',
            dataIndex: 'title',
            width: 360,
            render: (tags, item) => {
                return (

                        <Space className='cursor-pointer' onClick={() => editRef.current.onOpen(item.id)} >
                            <img
                                width={60}
                                height={60}
                                src={item.thumb + "@240x240_r.jpg"}
                                alt='img'
                            />
                            <Paragraph className='mb-0 me-5' ellipsis={{rows: 2}}>{tags}</Paragraph>
                        </ Space>

                )
            }
        },
        {
            title: '发布者',
            dataIndex: 'nickname',
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (tags) => {
                 const dict = getDictValue('is_show' , tags);
                return (
                    <Tag color={dict?.style}>{dict?.label}</Tag>
                )
            }
        },
        {
            title: '发布日期',
            dataIndex: 'created_at',
        },
        {
            title: '操作',
            dataIndex: 'action',
            hidden:!isPermission('article.post'),
            render: (key, item) => {
                return (
                    <Space>
                        <Button onClick={() => editRef.current.onOpen(item.id)}   type="dashed" size='small' icon={<EditOutlined/>}/>
                        <Popconfirm title="确定删除?" onConfirm={() => onDelete([item.id])}>
                            <Button  type="dashed" size='small' icon={<DeleteOutlined/>}/>
                        </Popconfirm>
                    </Space>
                )
            }
        },
    ];

    /**
     * 删除列表操作
     * @param {Array} keys  数组id
     */
    const onDelete = (keys: React.Key[]) => {
        if (keys.length === 0) {
            return;
        }
        setLoading(true);
        destroy('admin/article', keys)
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch(async (e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });

    };

    /**
     * 更新列表操作
     * @param status
     */

    const onUpdate = (status: number) => {
        setLoading(true);
        const dataKey = [...selectedRowKeys];
        put('admin/article', {ids: dataKey, status: status})
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch(async (e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    };

    /**
     * 表格操作触发
     * @param pagination
     */
    const onPaginationChange = (pagination: TablePaginationConfig) => {
        setLoading(true);
        const params: ArticleParams = {
            page: pagination.current,
            per_page: pagination.pageSize,
        }
        if (selectStatus !== '-1') {
            params.status = selectStatus;
        }

        if (selectCategory !== 0) {
            params.category_id = selectCategory;
        }

        if (search.length !== 0) {
            params.search = search;
        }
        getData(params);
    }


    /**
     * 操作提示信息
     */
    const showConfirm = () => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要删除选中项目吗？</div>,
            onOk() {
                onDelete(selectedRowKeys)
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }

    /**
     * 选择列表
     * @param {Array} selectedRowKeys
     */
    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys)
    };

    /**
     * 重置
     */
    const onReset = () => {
        setSelectStatus('-1')
        setSelectCategory(0)
        setSearch('')
        setLoading(true)
        getData({page: 1, per_page: perPage});
    }


    /**
     * 添加文章
     * @param params
     */
    const onCreate = (params: ArticleType.Data) => {
        const dataSource = [...data]
        dataSource.unshift(params)
        setData(dataSource)
    }

    /**
     * 编辑文章
     * @param params
     */

    const onEdit = (params:ArticleType.Data) => {
        const dataSource = [...data]
        const index = dataSource.findIndex((item) => item.id === params.id)
        params.created_at = dataSource[index].created_at
        dataSource[index] = params;
        setData(dataSource)
    }


    /**
     * 返回视图
     */

    return (
        <Card>
            <div className='mb-4'>
                <Row justify='space-between'>
                    {isPermission('article.post') &&  <Col>
                        <Button
                            onClick={() =>createRef.current.onOpen()}
                            type="primary"
                            icon={<PlusOutlined/>}
                        >
                            添加文章
                        </Button></Col>}
                    <Col>
                            <Space>
                                <Select style={{width: 200}} defaultValue={0} value={selectCategory}
                                        onChange={setSelectCategory}>
                                    <Select.Option key={0} value={0}>全部分类</Select.Option>
                                    {category.map((item) => <Select.Option key={item.id}
                                                                                  value={item.id}>{item.name}</Select.Option>)}
                                </Select>
                                <Select style={{width: 100}} defaultValue={'-1'} value={selectStatus}   onChange={setSelectStatus}>
                                    <Select.Option key={'-1'} value={'-1'}>全部状态</Select.Option>
                                    {getDict('is_show').map((item, index) => <Select.Option key={index}    value={item.value}>{item.label}</Select.Option>)}
                                </Select>

                                <Input.Search
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                    onSearch={() => {
                                        onPaginationChange({current: 1, pageSize: perPage})
                                    }}
                                    placeholder="输入关键词搜索"
                                    allowClear
                                    enterButton="搜索"
                                />

                                <Button onClick={onReset}>
                                    重置
                                </Button>
                            </Space>
                    </Col>
                </Row>

                <Divider/>
                <div className='mt-4'>
                    <div className='mb-3'>
                        <Button onClick={() => onUpdate(0)} className='me-2' icon={<ArrowDownOutlined/>}
                                disabled={selectedRowKeys.length === 0 || !isPermission('article.put')}
                                size='small'>隐藏</Button>
                        <Button onClick={() => onUpdate(1)} className='me-2' icon={<ArrowUpOutlined/>}
                                disabled={selectedRowKeys.length === 0 || !isPermission('article.put')}
                                size='small'>显示</Button>
                        <Button onClick={showConfirm} className='me-2' icon={<DeleteOutlined/>}
                                disabled={selectedRowKeys.length === 0 || !isPermission('article.delete')}
                                size='small'>删除</Button>
                        <span className='text-muted ms-3'>
                             {selectedRowKeys.length !== 0 ? `已选择 ${selectedRowKeys.length} 篇文章` : ''}
                        </span>
                    </div>

                    <Table
                        size="middle"
                        loading={loading}
                        rowSelection={isPermission('article.post') ?{
                            selectedRowKeys,
                            onChange: onSelectChange,
                        }: undefined}
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            size: 'default',
                            total: total,
                            current: current,
                            showTotal: total => `总计 ${total} 篇文章`,
                            pageSize: perPage,
                        }}
                        onChange={onPaginationChange}
                        rowKey={(record) => {
                            return record.id
                        }}
                    />
                </div>
            </div>
          <Create ref={createRef} onChange={onCreate}/>
          <Edit ref={editRef} onChange={onEdit}/>
        </Card>
    )
}
export default Article;
