/**
 * Created by devin on 2021/4/23.
 */

import React, {useRef, useState, SyntheticEvent, useMemo, useEffect} from 'react'
import {closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors,} from '@dnd-kit/core';
import {
    arrayMove,
    rectSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import {Image, Button} from "antd";
import {UploadOutlined, PlusOutlined} from "@ant-design/icons";
import {isImageUrl} from 'antd/es/upload/utils';
import ListItem from 'antd/es/upload/UploadList/ListItem';
import DisabledContext from 'antd/es/config-provider/DisabledContext';
import useStyle from 'antd/es/upload/style';
import {File, Video} from "../index";
import {ConfigContext} from 'antd/es/config-provider';
import {useFile} from "../../hooks";
import './style.css'
import type {UploadFile, UploadListType} from 'antd/es/upload/interface';
import type {ButtonProps} from 'antd/es/button';



interface ThumbProps {
    defaultFileList?: Array<StorageType.Data>
    type?: number;
    draggable?: boolean;
    listType?: UploadListType;
    text?: string;
    disabled?: boolean;
    size?: number;
    onChange?: (e: Array<StorageType.Data>) => void;
}

interface ListItemProps {
    id: string;
    file: StorageType.Data
    prefixCls?: string;
    listType: UploadListType;
    disabled?: boolean;
    onClose: (file: UploadFile) => void;
    onPreview: (file: UploadFile, event: SyntheticEvent<HTMLElement>) => void;
    onDownload: (file: UploadFile) => void;
}

const SortableListItem = (props: ListItemProps) => {

    const {file, prefixCls, listType, disabled, id, onClose, onDownload, onPreview} = props;
    const {getIcon} =useFile()
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id});
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: 10000,
        display: 'inline-block'
    };

    const isDownload = file.type !== 1 && file.type !== 2 && file.type !== 4;

    /**
     * 根据文件类型获取不同的url地址
     */
    const imgUrl = useMemo(() => {
        if (file.type === 1) {
            return  file.url + '@240x240_r.jpg';
        }
        if (file.type === 2) {
            return file.url + "@thumb.jpg";
        }
        return getIcon(file.mini_type)
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file.mini_type, file.type, file.url])


    /**
     * 预览文件按钮触发
     * @param customIcon
     * @param callback
     * @param prefixCls
     * @param title
     */
    const actionIconRender = (
        customIcon: React.ReactNode,
        callback: () => void,
        prefixCls: string,
        title?: string,
    ) => {
        const btnProps: ButtonProps = {
            type: 'text',
            size: 'small',
            title,
            onClick: () => {
                callback();
            },
            className: `${prefixCls}-list-item-action ant-btn-icon-only`,
        };
        return (
            <Button  {...btnProps}>
                <span>{customIcon}</span>
            </Button>
        );
    };


    return (
        <div
            className={`${prefixCls}-item-draggable`}
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            key={file.id}
        >
            <ListItem
                listType={listType}
                file={{
                    status: 'done',
                    id: file.id,
                    uid: file.id,
                    file_type: file.type,
                    mini_type: file.mini_type,
                    name: file.title,
                    full_url: file.url,
                    url: imgUrl,
                } as any}
                items={[]}
                isImgUrl={isImageUrl}
                actionIconRender={actionIconRender}
                iconRender={() => null}
                locale={{previewFile: '预览图片', removeFile: '删除图片'}}
                showRemoveIcon={!disabled}
                showPreviewIcon={!isDownload}
                showDownloadIcon={isDownload}
                onClose={onClose}
                onDownload={onDownload}
                onPreview={onPreview}
                prefixCls={prefixCls || ''}/>
        </div>
    )

}

const Thumb = (props: ThumbProps) => {
    const {
        onChange,
        defaultFileList,
        disabled = false,
        text = '上传',
        size = 1,
        listType = 'text',
        draggable = false,
        type = 1
    } = props
    const {getPrefixCls} = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('upload');
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [fileList, setFileList] = useState<Array<StorageType.Data>>([])
    const selectRef = useRef<any>();
    const [wrapSSR, hashId] = useStyle(prefixCls)
    const disabledContext = React.useContext(DisabledContext);
    const isDisabled = disabledContext || disabled;
    const playerRef = useRef<any>();
    const disabledClass = isDisabled ? `${prefixCls}-disabled` : '';
    const draggableClass = draggable ? `${prefixCls}-draggable` : '';



    useEffect(()=>{
            setFileList(defaultFileList || [])
    },[defaultFileList])



    /**
     * 附加文件
     * @param params
     */
    const onInsert = (params: Array<StorageType.Data>) => {
        let dataSource = fileList.concat(params)
        if (dataSource.length > size) {
            dataSource.length = size;
        }
        setFileList(dataSource);
        onChange?.(dataSource);
    }

    /**
     * 删除文件
     * @param params
     */
    const onDelete = (params: UploadFile) => {
        let dataSource = fileList.filter((i) => i.id !== params.uid);

        setFileList(dataSource)
        onChange?.(dataSource);
    }

    /**
     * 下载文件
     * @param params
     */
    const onDownload = (params: UploadFile) => {
        // @ts-ignore
        window.open(params.full_url, params.name)
    }
    /**
     * 预览文件
     * @param params
     * @param event
     */
    const onPreview = (params: UploadFile, event: SyntheticEvent<HTMLElement>) => {
        event.preventDefault()
        // @ts-ignore
        //判断文件类型
        if (params.file_type === 1) {
            // @ts-ignore
            setPreviewUrl(params.full_url)
            return
        }
        // @ts-ignore
        if (params.file_type === 2) {
            // @ts-ignore
            playerRef.current.onOpen(params.full_url, params.name)
            return
        }
        // @ts-ignore
        if (params.file_type === 4) {
            // @ts-ignore
            playerRef.current.onOpen(params.full_url, params.name, 0)
            return
        }
        // @ts-ignore
        window.open(params.full_url, params.name)
    }


    /**
     * 拖拽阻止点击冒泡
     */
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    )

    /**
     * 排序后的数据
     * @param event
     */
    const handleDragEnd = (event: { active: any; over: any; }) => {
        const {active, over} = event;
        if (!over) return null;
        if (active.id !== over.id) {
            setFileList((items) => {
                const oldIndex = items.findIndex(({id}: any) => id === active.id);
                const newIndex = items.findIndex(({id}: any) => id === over.id);
                const newItems = arrayMove(items, oldIndex, newIndex);
                onChange?.(newItems)
                return newItems;
            });
        }
    }


    return (
        <>
            {(() => {
                return wrapSSR(
                    <div className={`${prefixCls}-wrapper ${prefixCls}-${listType}-wrapper ${hashId}`}>
                        <div className={`${prefixCls}-list ${prefixCls}-list-${listType} ${draggableClass}`}>
                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={handleDragEnd}
                            >
                                <SortableContext
                                    disabled={isDisabled || !draggable}
                                    items={fileList.map((item) => item.id)}
                                    strategy={rectSortingStrategy}
                                >
                                    {fileList.map((item, index) => {
                                        return <SortableListItem onDownload={onDownload}
                                                                 onPreview={onPreview}
                                                                 onClose={onDelete} key={index} file={item}
                                                                 prefixCls={prefixCls}
                                                                 listType={listType} id={item.id}
                                                                 disabled={isDisabled}/>
                                    })}
                                    {listType === 'picture-card' && fileList.length < size &&
                                        <div className={`${prefixCls} ${prefixCls}-select ${disabledClass}`}>
                                            <div className={`${prefixCls}-button`}
                                                 onClick={!isDisabled ? () => selectRef.current.onOpen(size > 1, type) : () => {
                                                 }}>
                                                <div className={`${disabledClass} ${prefixCls}-button-content`}>
                                                    <PlusOutlined/>
                                                    <div style={{marginTop: 8}}>{text}</div>
                                                </div>
                                            </div>
                                        </div>}
                                </SortableContext>
                            </DndContext>
                        </div>
                        {listType !== 'picture-card' && fileList.length < size &&
                            <div className={`${prefixCls} ${prefixCls}-select`}>
                                <Button icon={<UploadOutlined/>} onClick={() => selectRef.current.onOpen(size > 1, type)}>{text}</Button>
                            </div>}

                    </div>
                )
            })()}
            <Image
                className='d-none'
                src={previewUrl || ''}
                preview={{
                    visible: previewUrl !== null,
                    src: previewUrl || '',
                    onVisibleChange: () => {
                        setPreviewUrl(null)
                    },
                }}
            />
            <Video autoPlay={true} ref={playerRef}/>
            <File ref={selectRef} onChange={onInsert}/>
        </>
    )

}

export default Thumb;
