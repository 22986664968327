import React, {Suspense} from 'react';
import { HashRouter as Router, Routes, Route} from "react-router-dom";
import {RecoilRoot} from 'recoil';

import Admin from './pages/layout'
import Login from './pages/login'
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import 'antd/dist/reset.css';
import './App.css';

dayjs.locale('zh-cn');

function App() {
  return (
        <RecoilRoot>
          <Router>
            <Routes>
              <Route path='/' element={<Login />}/>
              <Route path="/*" element={
                <Suspense fallback={<div>Loading</div>}>
                  <Admin/>
                </Suspense>
              }/>
           </Routes>
          </Router>
        </RecoilRoot>
  );
}

export default App;