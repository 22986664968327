import React, {forwardRef, useCallback,useImperativeHandle, useMemo, useState} from 'react'
import {
    App,
    Badge,
    Button,
    Col,
    Divider,
    Form,
    Input,
    List,
    Modal,
    Popconfirm,
    Popover,
    Progress,
    Row,
    Space,
    Spin,
    Typography,
    Upload,
    UploadProps
} from "antd";
import {Item, Menu, useContextMenu} from 'react-contexify';
import "react-contexify/dist/ReactContexify.css";
import {useFile, useHttps, usePermission} from "../../hooks";
import {UploadFile} from "antd/es/upload/interface";
import {
    CheckCircleFilled,
    CloseCircleFilled,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    FolderAddOutlined,
    PlayCircleFilled, RightOutlined,
    UploadOutlined,
    VerticalAlignTopOutlined
} from "@ant-design/icons";
import {randomNumInteger, subText} from "../../utils/utils";

import './style.css'
import dayjs from "dayjs";
import {UploadApi} from "../../config/Config";
import {useRecoilValue} from "recoil";
import {algorithmState, tokenState} from "../../store";

const {Text, Paragraph, Link} = Typography


interface FileSelectProps {
    onChange: (e: StorageType.Data[], key?:number) => void;
}

const File = forwardRef((props: FileSelectProps, ref) => {
    const {getIcon} =useFile()
    const { message } = App.useApp();
    const algorithm = useRecoilValue(algorithmState);
    const token = useRecoilValue<string | null>(tokenState);
    const {get, post, destroy, put} = useHttps();
    const [key, setKey] = useState<number>()
    const [multiple, setMultiple] = useState(true)
    const [type, setType] = useState(-1)
    const [open, setOpen] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState('0');
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [editId, setEditId] = useState('0')
    const [selectData, setSelectData] = useState<Array<StorageType.Data>>([])
    const [data, setData] = useState<Array<StorageType.Data>>([])
    const [loading, setLoading] = useState(true)
    const [refreshing, setRefreshing] = useState(false);
    const [hasMore, setHasMore] = useState(false)
    const [current, setCurrent] = useState(1)
    const [uploadParentId, setUploadParentId] = useState('0');
    const [uploadOpen, setUploadOpen] = useState(false)
    const {show} = useContextMenu();
    const [form] = Form.useForm();
    const {isPermission} = usePermission();
    const [storageRouter, setStorageRouter] = useState<Array<StorageType.Data>>([{
        id: '0',
        title: '全部',
        url: '#',
        size: 0,
        type: 0,
        sort: 1,
        mini_type: 'file',
        parent_id: '0',
        filename: '#',
        created_at: new Date()
    }]);
    const perPage = 54;
    let dblclick: NodeJS.Timeout | null;

    /**
     * 获取数据
     */
    const getData = useCallback((params: any) => {
        params.per_page = perPage;
        setRefreshing(true)
        get('admin/storage/query', params, true)
            .then((res: Request.Response<Array<StorageType.Data>>) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setHasMore(res.data.length < perPage)
                    setRefreshing(false)
                    if (params.page === 1) {
                        setData(res.data);
                    } else {
                        setData((r) => r.concat(res.data))
                    }
                }

            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    message.error(e.message)
                    setLoading(false);
                }
            });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[get])


    /**
     * 获取当前面包屑位置的ID
     */
    const getParentId = useMemo((): string => {
        const endData = storageRouter[storageRouter.length - 1];
        return endData.id;
    }, [storageRouter])


    const getAccept = useMemo(() => {
        if (!type || type <= 0 || type > 5) {
            return ''
        }
        const accept = ['*', '.bmp,.jpg,.jpeg,.png,.tiff,.gif,.svg,.webp', '.mp4,.avi,.mov,.wmv,.asf,.navi,.3gp,.mkv,.f4v,.rmvb,.webm', '.txt,.doc,.docx,.xls,.rtf,.wpd,.pdf,.ppt,.csv,.pptx', '.mp3,.wma,.wav, mod,.ra,.cd,.md,.asf,.aac,.vqf,.ape,.mid,.ogg,.m4a,.vqf', '.zip,.rar,.7z,.bz']
        return accept[type];

    }, [type])

    /**
     * 根据类型展示不同标题
     */
    const getTitle = useMemo((): string => {
        if (!type || type < 0 || type > 4) {
            return '选择文件或文件夹'
        }
        const title = ['选择文件夹', '选择图片', '选择视频', '选择文档', '选择音乐']
        return title[type];
    }, [type])
    /**
     * 提供给外部组件调用的方法
     *
     */
    useImperativeHandle(ref, () => ({
        // changeVal 就是暴露给父组件的方法
            onOpen: (multiple = false, type = 1, key: number = 0) => {
            setMultiple(multiple)
            setType(type)
            setOpen(true);
            setData([])
            setLoading(true)
            setSelectData([])
            setKey(key)
            getData({
                page: 1, parent_id: '0'
            })
        },
    }));


    /**
     * 鼠标右键
     * @param event
     * @param params
     */
    const onItemMenu = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, params: StorageType.Data) => {
        event.stopPropagation();
        //验证权限
        if (!isPermission('storage.post')) {
            return;
        }
        //如果是未保存的新建文件夹将返回
        if (params.cache) {
            return
        }

        //根据文件类型判断是否展示鼠标右键
        if (type !== -1 && params.type !== 0 && params.type !== type) {
            event.preventDefault();
            return;
        }
        // 选中效果
        show({
            event, props: params, id: "selectFileItemId"
        })
    }

    /**
     * 鼠标右键点击方法
     * @param params
     */

    const onItemClick = (params: any) => {
        //如果是编辑标题
        if (params.id === 'edit') {
            onEdit(params.props)
            return
        }
        //如果是删除
        if (params.id === 'remove') {
            setDeleteItemId(params.props.id)
            return
        }
    }

    /**
     * 面包屑导航
     * @param params
     * @param index
     */
    const onRouterChange = (params: StorageType.Data, index: number) => {
        let dataSource = [...storageRouter];
        dataSource.splice(index + 1, dataSource.length)
        const parentId = dataSource[dataSource.length - 1];
        setStorageRouter(dataSource)
        setSelectData([])
        setLoading(true)
        getData({page: 1, parent_id: parentId.id})
    }


    /**
     * 关闭模态框
     */
    const onCancel = () => {
        setOpen(false);
        setSelectData([])
    };

    /**
     * 确认选择
     */
    const onOk = () => {
        props.onChange(selectData, key);
        setSelectData([])
        setOpen(false);
    }

    /**
     * 标记标题
     * @param params
     */
    const onEdit = (params: StorageType.Data) => {
        deleteCacheFile()
        setEditId(params.id)
        form.setFieldsValue({title: params.title})
    }


    /**
     * 删除已上传的文件
     * @param params
     */
    const onDeleteFileList = (params: UploadFile) => {
        let dataSource = fileList.filter((i) => i.uid !== params.uid)
        setFileList(dataSource)
    }

    /**
     * 删除缓存的新建文件夹
     */
    const deleteCacheFile = () => {
        let dataSource = [...data];
        const select = dataSource.filter((i) => !i.cache);
        setData(select)
        setEditId('0')
    }


    /**
     * 验证文件是否已选择
     * @returns {boolean}
     * @param params
     */
    const isSelect = (params: StorageType.Data): boolean => {
        const select = selectData.findIndex((value) => value.id === params.id);
        return select > -1
    }


    /**
     * 选择文件触发
     * @param params
     */

    const onSelectChange = (params: StorageType.Data) => {
        //删除缓存id
        setEditId('0')
        if (params.type === 0) {
            return;
        }

        if (type !== -1 && type !== params.type) {
            return;
        }

        //验证选择数量
        const select = selectData.findIndex((value) => value.id === params.id);
        let dataSource = [...selectData];
        if (select > -1) {
            if (multiple) {
                dataSource.splice(select, 1);
            } else {
                dataSource = [];
            }
        } else {
            if (multiple) {
                dataSource.push(params);
            } else {
                dataSource = [params]
            }
        }
        setSelectData(dataSource)
    }


    /**
     * 保存新建文件夹
     * @param params
     * @param index
     */
    const onFinish = async (params: StorageType.Data, index: number) => {
        try {
            setLoading(true)
            let values = await form.validateFields();
            params.title = values.title
            post('admin/storage', {...params,  ...{created_at :dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')}})
                .then(async (res: Request.Response<StorageType.Data>) => {
                    if (res.mounted) {
                        setLoading(false)
                        message.success('添加成功！');
                        const dataSource = [...data];
                        dataSource[index] = res.data;
                        setData(dataSource)
                    }
                })
                .catch(async (e: Request.Error) => {
                    if (e.mounted) {
                        setLoading(false)
                        message.error(e.message);
                    }
                });

        } catch (err) {
            return;
        }
    }
    /**
     * 更新文件夹
     * @param params
     * @param index
     */
    const onUpdate = async (params: StorageType.Data, index: number) => {
        try {
            let values = await form.validateFields();
            setLoading(true)
            put('private/admin/storage/' + params.id, values.title)
                .then(async (res: Request.Response<StorageType.Data>) => {
                    if (res.mounted) {
                        setLoading(false)
                        message.success('更新成功！');
                        const dataSource = [...data];
                        dataSource[index] = res.data;
                        setEditId('0')
                        setData(dataSource)
                    }
                })
                .catch(async (e: Request.Error) => {
                    if (e.mounted) {
                        setLoading(false)
                        message.error(e.message);
                    }
                });

        } catch (err) {
            return;
        }
    }

    /**
     * 创建新建文件夹
     */
    const onCreate = () => {
        const dataSource = [...data];
        //先判断是否存在缓存文件夹如果存在返回
        const isFile = dataSource.some((i) => i.cache)
        if (isFile) {
            return;
        }
        const id = randomNumInteger(1000000, 9999999).toString();
        //获取面包屑最后一条父路径
        //  const endData = storageRouter[storageRouter.length -1];
        //插入最顶部
        dataSource.unshift({
            id: id,
            title: '新建文件夹',
            url: '#',
            size: 0,
            type: 0,
            sort: 1,
            filename: '#',
            mini_type: 'file',
            parent_id: getParentId === '-1' ? '0' : getParentId,
            created_at: new Date(),
            cache: true
        })
        form.setFieldValue('title', '新建文件夹')
        setEditId(id);
        setData(dataSource)
    }


    /**
     * 从服务端删除文件
     */
    const onDelete = (params: Array<StorageType.Data>) => {
        if (params.length === 0) {
            return
        }
        setLoading(true);
        //获取全部id
        let ids = params.map((item) => item.id);
        //提交删除
        destroy('private/admin/storage', ids)
            .then((res) => {
                if (res.mounted) {
                    setSelectData([])
                    getData({page: 1, parent_id: getParentId})
                }
            })
            .catch(async (e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    }


    /**
     * 上拉加载数据
     */
    const onRefresh = useCallback((e: React.UIEvent<HTMLElement, UIEvent>) => {

        //如果数据正在请求中或无更多数据返回
        if (hasMore || refreshing) {
            return;
        }
        if (e.currentTarget.clientHeight + e.currentTarget.scrollTop >= e.currentTarget.scrollHeight) {
            const pageNum = current + 1
            //判断是否选中文件类型
            getData({
                page: pageNum, parent_id: getParentId
            });
        }

    }, [current, getData, getParentId, hasMore, refreshing])


    /**
     * 预览文件夹
     * @param params
     */

    const onPreviewType = (params: StorageType.Data) => {
        if (params.cache) {
            return
        }
        if (dblclick) {
            clearTimeout(dblclick);
        }
        if (params.type === 0) {
            setEditId('0');
            //处理鼠标双击事件
            dblclick = setTimeout(() => {
                let dataSource = [...storageRouter];
                dataSource.push(params)
                setStorageRouter(dataSource)
                setLoading(true)
                setSelectData([])
                getData({page: 1, parent_id: params.id})
            }, 300)
            return;
        }
    }

    /**
     * 获取已上传完成的文件数
     */
    const getUploadCount = useMemo((): number => {
        let dataSource = fileList.filter((i) => i.status === 'uploading')
        return dataSource.length
    }, [fileList])


    /**
     * OSS上传数据
     * @returns {*}
     */
    const getExtraData: UploadProps['data'] = () => {
        return {
            parent_id : getParentId === '-1' ? '0' : getParentId,
        };
    };

    /**
     * 上传前回调
     * @param file
     * @returns {*}
     */
    const beforeUpload: UploadProps['beforeUpload'] = async file => {
        if (file.size > 104857600) {
            message.error('上传文件不能大于 100MB!');
            return Upload.LIST_IGNORE;
        }
        //上传前写入当前页面父ID
        setUploadParentId(getParentId)
        setUploadOpen(true)
    }

    /**
     * 刷新文件
     */
    const getRefreshUpload =  () => {
        if (!loading) {
            setLoading(true)
            setTimeout(() => {
                get('admin/storage/query', {page:1,per_page:perPage, parent_id: getParentId}, true)
                    .then((res: Request.Response<Array<StorageType.Data>>) => {
                        if (res.mounted) {
                            setLoading(false);
                            setCurrent(res.meta.current_page)
                            setData(res.data);
                        }

                    })
            }, 3000);
        }
    }


    /**
     * 上传后回调
     * @returns {*}
     * @param info
     */
    const uploadChange: UploadProps['onChange'] = ({file, fileList}) => {
        setFileList([...fileList])
        //如果成功刷新数据
        if (file.response && file.response.code !== 0 && file.response.data) {
            if (uploadParentId !== getParentId) {
                return;
            }
            if (file.status !== 'done') {
                return;
            }
            getRefreshUpload();
        }
    }

    /**
     *
     * 搜索文件
     * @param value
     */
    const onSearch = (value: string) => {
        if (value.length === 0) {
            return
        }
        let dataSource = [...storageRouter];
        dataSource.length = 1;
        //修改给面包屑
        dataSource.push({
            id: '-1',
            title: '搜索：' + value,
            url: '#',
            size: 0,
            type: 0,
            sort: 1,
            filename: '#',
            mini_type: 'search',
            parent_id: '0',
            created_at: new Date(),
            cache: true
        })

        setSelectData([])
        setStorageRouter(dataSource)
        //发起请求
        setLoading(true)
        getData({page: 1, title: value});
    };

    return (
        <Modal destroyOnClose title={getTitle} width={860} maskClosable={false} centered open={open} onCancel={onCancel}
               onOk={onOk} okButtonProps={{disabled: selectData.length === 0}}>
             <div className='mt-3'>
                <Row justify="space-between">

                     <Col>
                           <Space size="middle">
                               {isPermission('storage.post') &&  <Upload
                                multiple
                                maxCount={24}
                                headers={{
                                    authorization: 'Bearer ' + token,
                                }}
                                fileList={fileList}
                                showUploadList={false}
                                accept={getAccept}
                                action={UploadApi}
                                onChange={uploadChange}
                                data={getExtraData}
                                beforeUpload={beforeUpload}
                            >
                                <Button
                                    type="primary"
                                    icon={<VerticalAlignTopOutlined/>}
                                >
                                    上传文件
                                </Button>
                            </Upload>}
                               {isPermission('storage.post') &&  <Button
                                onClick={onCreate}
                                type="dashed"
                                icon={<FolderAddOutlined/>}
                            >
                                新建文件夹
                            </Button>}
                            <Text type='secondary'> 已选择{selectData.length}项</Text>
                        </Space>

                 </Col>
                    <Col>
                        <Space>

                            <Input.Search  placeholder="搜索我的文件" allowClear enterButton onSearch={onSearch}/>

                            {isPermission('storage.post') &&   <Popover
                                placement="bottomRight"
                                content={<div className='file-upload-list'>
                                    <List
                                        split
                                        dataSource={fileList}
                                        renderItem={(item, index) => {
                                            return (<List.Item
                                                key={index}
                                                actions={[<Button type="dashed" shape="circle" size='small'
                                                                  onClick={() => onDeleteFileList(item)}
                                                                  icon={<CloseOutlined/>}/>]}
                                            >
                                                <List.Item.Meta
                                                    avatar={<img alt='img' src={getIcon(item.name, true)}
                                                                 style={{
                                                                     width: 38, height: 38, marginTop: 7
                                                                 }}/>}
                                                    title={<Text ellipsis
                                                                 style={{marginTop: 0}}>{item.name}</Text>}
                                                    description={<Progress
                                                        status={item.status === 'error' ? 'exception' : undefined}
                                                        style={{marginInlineEnd: 0, marginBottom: 0}}
                                                        percent={parseInt(item.percent?.toString() || '0', 10)}
                                                        size="small"/>}
                                                />
                                            </List.Item>)
                                        }}

                                    />
                                </div>}
                                title='上传列表'
                                trigger="click"
                                open={uploadOpen}
                                onOpenChange={(e) => setUploadOpen(e)}
                            >
                                <Badge size="small" count={getUploadCount}>
                                    <Button type={uploadOpen ? 'primary' : 'dashed'} shape="circle"
                                            icon={<UploadOutlined/>}/>
                                </Badge>
                            </Popover>}

                        </Space>
                    </Col>
                </Row>
            </div>
            <Divider className='mb-0'/>
            <div>
                <div className='my-2'>
                    <Space>
                        {storageRouter.map((item: any, index: number) => {
                            const isRoot = storageRouter.length  === index + 1;
                            return (
                                <div key={index}> {isRoot ? <Text type='secondary'>{subText(item.title, 8)}</Text> : <Space align="center"><Link onClick={() => onRouterChange(item, index)}>{subText(item.title, 8)}</Link><RightOutlined className='mx-2' /></Space>}</div>
                            )
                        })}
                    </Space>
                </div>
                <Spin size="small" spinning={loading}>
                    <div style={{height: 460}} onScroll={onRefresh} className='file-select-content'>
                        <Form form={form} component={false}>
                            <List
                                grid={{gutter: 16, column: 6}}
                                split
                                loadMore={!loading && !hasMore ?
                                    <div className='text-center pb-4'><Spin size="small"/></div> : null}
                                dataSource={data}
                                renderItem={(item, index) => {
                                    // @ts-ignore
                                    return (<List.Item
                                        key={index}
                                        style={{
                                            padding: 10,
                                            position: 'relative',
                                            borderRadius: 8,
                                            cursor: type !== -1 && type !== item.type && item.type !== 0 ? 'default' : 'pointer',
                                            backgroundColor: isSelect(item) || item.cache || editId === item.id ?  algorithm?'rgba(255, 255, 255, 0.08)': '#f2faff' : algorithm?'#000000':'#ffffff',
                                            opacity: type === item.type || item.type === 0 || type === -1 ? 1 : 0.2,
                                            height: 130
                                        }}
                                        onContextMenu={(e) => onItemMenu(e, item)}
                                        onClick={() => item.type === 0 ? onPreviewType(item) : onSelectChange(item)}
                                    >
                                        <Popconfirm
                                            className='w-100'
                                            open={deleteItemId === item.id}
                                            onCancel={(event) => {
                                                event?.stopPropagation()
                                                setDeleteItemId('0')
                                            }}
                                            onConfirm={(event) => {
                                                event?.stopPropagation()
                                                onDelete([item])
                                            }}
                                            getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                                            title={"确定要删除这项吗?"}
                                        >
                                            <Space align='center' direction='vertical'>
                                                <div style={{height: 64, width: 64}}
                                                     className='d-flex justify-content-center align-items-center'>
                                                    {(() => {
                                                        if (item.type === 1) {
                                                            return <img
                                                                alt='img'
                                                                src={item.url + "@240x240_r.jpg"}
                                                                className='w-auto h-auto mh-100 mw-100'/>
                                                        }
                                                        if (item.type === 2) {
                                                            return (<div
                                                                className='position-relative h-100 d-flex justify-content-center align-items-center'>
                                                                <div
                                                                    className='position-absolute d-table w-100 h-auto'>
                                                                    <PlayCircleFilled
                                                                        style={{fontSize: 10}}
                                                                        className="d-table-cell align-middle text-white opacity-75"/>
                                                                </div>
                                                                <img alt='img' src={item.url + "@thumb.jpg"}
                                                                     className='w-auto h-auto mh-100 mw-100'/>
                                                            </div>)
                                                        }
                                                        return <img
                                                            alt='img'
                                                            src={getIcon(item.mini_type)}
                                                            style={{width: 104, height: 64}}
                                                        />
                                                    })()}
                                                </div>
                                                {editId === item.id || item.cache ? <div>
                                                    <Form.Item
                                                        name="title"
                                                        style={{margin: 0}}
                                                        initialValue={item.title}
                                                        rules={[{
                                                            required: true, message: '文件夹名称必须填写',
                                                        },]}
                                                    >
                                                        <Input onClick={(e) => e.stopPropagation()}
                                                               style={{width: '100%'}} value={item.title}
                                                               autoFocus/>
                                                    </Form.Item>
                                                    <div className='position-absolute top-0 end-0'>
                                                        <div className='px-1 rounded-1 mt-1 me-1' style={{backgroundColor:algorithm? '#000':'#fff'}}>
                                                            <Space>
                                                                <CloseCircleFilled
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        deleteCacheFile()
                                                                    }}/>
                                                                <CheckCircleFilled
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        item.cache ? onFinish(item, index) : onUpdate(item, index)
                                                                    }}/>
                                                            </Space>
                                                        </div>


                                                    </div>
                                                </div> : <Paragraph
                                                    ellipsis={{rows: 2}}
                                                    onClick={() => onPreviewType(item)}
                                                    className='text-center mb-1'> {item.title}</Paragraph>}

                                            </Space>
                                        </Popconfirm>
                                    </List.Item>)
                                }}/>
                        </Form>
                    </div>
                </Spin>


            </div>
            {isPermission('storage.post') && <Menu id='selectFileItemId' className='file-select-item-menu'>
                    <Item id="edit" onClick={onItemClick}><Space size='middle'><EditOutlined/>重命名</Space></Item>
                    <Item id="remove" onClick={onItemClick}><Space size='middle'><DeleteOutlined/>删除</Space></Item>
            </Menu>}
        </Modal>)
})


export default File;
