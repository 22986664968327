/**
 * Created by devin on 2021/4/21.
 */

import React, {useCallback, useEffect, useState} from 'react'

import {
    Card,
    Divider,
    Input,
    message,
    Space,
    Table,
    Typography
} from 'antd';
import type {ColumnsType, TablePaginationConfig} from 'antd/es/table';
import {useHttps} from "../../../hooks";
const {Paragraph} = Typography;

interface VerifyParams {
    page?: number;
    per_page?: number;
    status?: string;
    search?: string;
}

const OrderVerify = () => {
    const {get} = useHttps();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<GoodsType.Verify[]>([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [search, setSearch] = useState('')
    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params: any) => {
        get('admin/goods/order/verify', params, true)
            .then((res:Request.Response<GoodsType.Verify[]>) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }

            })
            .catch(async (e) => {
                if (e.mounted) {
                    message.error(e.message)
                    setLoading(false);
                }
            });
    }, [get])


    useEffect(() => {
        getData({page: 1, per_page: 15});
    }, [getData]);





    /**
     * 自定义表格
     * @type {Object}
     */

    const columns: ColumnsType<GoodsType.Verify> = [
        {
            title: '用户',
            dataIndex: 'nickname',
        },
        {
            title: '联系电话',
            dataIndex: 'mobile',
        },
        {
            title: '核销商品',
            dataIndex: 'meta_data',
            width: 360,
            render: (tags) => {
                return (
                        <Space className='cursor-pointer'>
                            <img
                                width={60}
                                height={60}
                                src={tags.thumb + "@240x240_r.jpg"}
                                alt='img'
                            />
                            <Paragraph className='mb-0 me-5' ellipsis={{rows: 2}}>{tags.title}</Paragraph>
                        </ Space>

                )
            }
        },
        {
            title: '核销日期',
            dataIndex: 'created_at',
        },
    ];



    /**
     * 表格操作触发
     * @param pagination
     */
    const onPaginationChange = (pagination: TablePaginationConfig) => {
        setLoading(true);
        const params: VerifyParams  = {
            page: pagination.current,
            per_page: pagination.pageSize,
        }
        if (search.length !== 0) {
            params.search = search;
        }
        getData(params);
    }




    /**
     * 返回视图
     */

    return (
        <Card>
            <div className='mb-4'>

                            <Space>
                                <Input.Search
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                    onSearch={() => {
                                        onPaginationChange({current: 1, pageSize: perPage})
                                    }}
                                    placeholder="输入手机号搜索"
                                    allowClear
                                    enterButton="搜索"
                                />
                            </Space>
                <Divider/>
                <div className='mt-4'>
                    <Table
                        size="middle"
                        loading={loading}
                        dataSource={data}
                        columns={columns}
                        pagination={{
                            size: 'default',
                            total: total,
                            current: current,
                            showTotal: total => `总计 ${total} 条记录`,
                            pageSize: perPage,
                        }}
                        onChange={onPaginationChange}
                        rowKey={(record) => {
                            return record.id
                        }}
                    />
                </div>
            </div>
        </Card>
    )
}
export default OrderVerify;
