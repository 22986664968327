import React, {useCallback, useEffect, useRef, useState} from 'react'
import {
    Button,
    Input,
    Card,
    Space,
    Row,
    Col,
    Tag,
    List,
    Typography,
    Select, App
} from 'antd';

import {useDict, useHttps, usePermission} from "../../hooks";
import {
    ExclamationCircleOutlined,
    DeleteOutlined, PlusOutlined, EditOutlined, EllipsisOutlined
} from "@ant-design/icons";

import Create from "./Create";
import Edit from "./Edit";
import Log from "./Log";
import dayjs from "dayjs";
const {Text} = Typography

interface CourseParams {
    page?: number;
    per_page?: number;
    status?: string;
    search?: string;
}

const Course = () => {
    const { message, modal } = App.useApp();
    const {get, destroy} = useHttps();
    const [search, setSearch] = useState('')
    const [data, setData] = useState<CourseType.Data[]>([]);
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(20)
    const [current, setCurrent] = useState(1)
    const [loading, setLoading] = useState(true)
    const [selectStatus, setSelectStatus] = useState('-1')
    const {getDict, getDictValue} = useDict();
    const createRef = useRef<any>()
    const editRef = useRef<any>()
    const logRef = useRef<any>()
    const {isPermission} = usePermission();


    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params: CourseParams) => {
        get('admin/course', params, true)
            .then((res: Request.Response<CourseType.Data[]>) => {
                if (res.mounted) {
                    setLoading(false)
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    message.error(e.message)
                    setLoading(false)
                }
            });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get])



    useEffect(() => {
        getData({page: 1, per_page: 20})
    }, [get, getData])










    /**
     * 翻页操作触发
     * @param current
     * @param pageSize
     */

    const onPaginationChange = (current: number, pageSize: number) => {
        setLoading(true);
        const params: CourseParams = {
            page: current,
            per_page: pageSize,
        }
        if (selectStatus !== '-1') {
            params.status = selectStatus;
        }
        if (search.length !== 0) {
            params.search = search;
        }
        getData(params);
    }


    const onDelete = (id: string) => {
        modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: "确定要删除此课程吗？",
            onOk() {
                setLoading(true)
                destroy('admin/course/' + id)
                    .then((res) => {
                        if (res.mounted) {
                            getData({page: current, per_page: perPage})
                        }
                    })
                    .catch(async (e: Request.Error) => {
                        if (e.mounted) {
                            message.error(e.message)
                            setLoading(false);
                        }
                    });
            },
        });
    }





    const onCreate = (params: CourseType.Data) => {
        const dataSource = [...data]
        dataSource.unshift(params)
        setData(dataSource)
    }


    /**
     * 编辑文章
     * @param params
     */

    const onEdit = (params:CourseType.Data) => {
        const dataSource = [...data]
        const index = dataSource.findIndex((item) => item.id === params.id)
        params.created_at = dataSource[index].created_at
        dataSource[index] = params;
        setData(dataSource)
    }


    return (
        <>
            <div className='mt-4'>
                <Row justify='space-between'>
                    <Col>
                        <Space size='large'>
                            {isPermission('course.post')  &&  <Button
                                onClick={() =>createRef.current.onOpen()}
                                type="primary"
                                icon={<PlusOutlined/>}
                            >
                                添加课程
                            </Button>}
                            <Text type='secondary'>总计{total}门课程</Text>
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Select style={{width: 100}} defaultValue={'-1'} value={selectStatus}   onChange={setSelectStatus}>
                                <Select.Option key={'-1'} value={'-1'}>全部状态</Select.Option>
                                {getDict('is_show').map((item, index) => <Select.Option key={index}    value={item.value}>{item.label}</Select.Option>)}
                            </Select>

                            <Input.Search
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                                onSearch={() => {
                                    onPaginationChange( 1, perPage)
                                }}
                                placeholder="输入关键词搜索"
                                allowClear
                                enterButton="搜索"
                            />


                        </Space>
                    </Col>
                </Row>
                <List
                    className='mt-4'
                    loading={loading}
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 1,
                        md: 3,
                        lg: 4,
                        xl: 5,
                        xxl: 5,
                    }}
                    pagination={{
                        defaultCurrent: 1,
                        total: total,
                        current: current,
                        pageSize: perPage,
                        onChange: onPaginationChange
                    }}
                    dataSource={data}
                    renderItem={(item, index) =>{
                        const dict = getDictValue('is_show' , item.status);
                        return (
                        <List.Item key={index} className='px-0 '>
                            <Card
                                actions={isPermission('course.post') ? [
                                    <EditOutlined onClick={()=>editRef.current.onOpen(item)} key="edit" />,
                                    <DeleteOutlined onClick={()=>onDelete(item.id)} key="delete" />,
                                    <EllipsisOutlined onClick={()=>logRef.current.onOpen(item.id)} key="ellipsis" />,
                                ]: undefined}
                                cover={
                                    <img
                                        onClick={()=>editRef.current.onOpen(item)}
                                        style={{width:'100%',height:240,objectFit:'cover'}}
                                        className={'cursor-pointer'}
                                        alt="example"
                                        src={item.thumb}
                                    />
                                }
                            >
                                <div className={'position-absolute top-0 end-0 p-3'}>
                                    <Tag color={dict?.style}>{dict?.label}</Tag>
                                </div>
                                <Card.Meta
                                    title={item.title}
                                    description={'发布时间：'+dayjs(item.created_at).format('YYYY-MM-DD')}
                                />
                            </Card>
                        </List.Item>
                    )}}
                />
            </div>
            <Create ref={createRef} onChange={onCreate}/>
            <Edit  ref={editRef} onChange={onEdit}/>
            <Log  ref={logRef} />
        </>
    )
}

export default Course;
