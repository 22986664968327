/**
 * Notes: 管理员权限
 * User: 李强
 * DateTime: 2021/12/8 15:47
 * Ide: PhpStorm
 */


import React, {useState, useRef, useEffect} from 'react'
import {
    Button,
    Form,
    Input,
    Table,
    Popconfirm,
    Space,
    Row,
    Col,
    Typography,
    Modal,
    InputNumber, Spin, App
} from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    CheckOutlined,
    CloseOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import type {ColumnsType} from 'antd/es/table';
import {useRecoilState} from "recoil";
import {dictTypeState} from "../../../../store";
import {useHttps, useDict} from "../../../../hooks";



const {Text} = Typography

interface DictProps {
    dictId: number
    type: string,
}

const DictType = (props: DictProps) => {
    const { message, modal } = App.useApp();
    const [dictType, setDictType] = useRecoilState(dictTypeState);
    const [data, setData] = useState<SystemType.DictTypeData[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [loading, setLoading] = useState(false)
    const [current, setCurrent] = useState(1)
    const [spin, setSpin] = useState(false)
    const [open, setOpen] = useState(false)
    const {put, destroy, post} = useHttps();
    const [editItem, setEditItem] = useState(0)
    const formRef = useRef<any>();
    const editFormRef = useRef<any>();
    const {getDict} = useDict();

    useEffect(() => {
        setData([...getDict(props.type)])
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dictType, props.type])


    /**
     * 更新权限
     */

    const onUpdate = async (params: SystemType.DictTypeData) => {
        try {
            let values = await editFormRef.current.validateFields();
            setSpin(true)
            put('admin/dict/type/' + params.id, {...params, ...values})
                .then((res: Request.Response<SystemType.DictTypeData>) => {
                    if (res.mounted) {
                        const dataSource = {...dictType};
                        const index: number = dataSource[props.type].findIndex((v) => v.id === params.id);
                        dataSource[res.data.type][index] = res.data;
                        setDictType(dataSource)
                        message.success('数据已更新！');
                        editFormRef.current.setFieldsValue({label: '', value: '', style:'', sort: 0});
                        setEditItem(0)
                        setSpin(false)
                    }
                })
                .catch((e) => {
                    if (e.mounted) {
                        setSpin(false)
                        message.error(e.message);
                    }
                });
        } catch (err) {
            return;
        }
    };

    /**
     * 删除权限
     * @param ids
     */


    const onDelete = (ids: React.Key[]) => {
        setSpin(true)
        destroy('admin/dict/type',  ids)
            .then(async (res) => {
                if (res.mounted) {
                    setSpin(false)
                    message.success('删除数据成功！');
                    const dataSource = {...dictType};
                    dataSource[props.type]  = dataSource[props.type].filter((item) => !ids.includes(item.id));
                    setDictType(dataSource)
                }
            })
            .catch(async (e) => {
                if (e.mounted) {
                    setSpin(false)
                    message.error(e.message);
                }
            });
    }

    /**
     * 添加权限
     */

    const onFinish = async () => {

        try {
            let values = await formRef.current.validateFields();
            setLoading(true)
            post('admin/dict/type', {...values, ...{dict_id: props.dictId, type: props.type}})
                .then((res: Request.Response<SystemType.DictTypeData>) => {
                    if (res.mounted) {
                        setLoading(false)
                        const dataArr = [...data]
                        dataArr.push(res.data)
                        const dataSource: { [index: string]:SystemType.DictTypeData[] } = {
                            [res.data.type] : [...dataArr]
                        };
                        setDictType({...dictType , ...dataSource})
                        setOpen(false)
                        formRef.current.setFieldsValue({label: '', value: '', sort: 0, style:''})
                        message.success('数据添加成功！');

                    }
                })
                .catch(async (e) => {
                    if (e.mounted) {
                        setLoading(false)
                        message.error(e.message);
                    }
                });
        } catch (err) {
            return;
        }
    }


    /**
     * 表格设置
     * @param item
     */

    const columns: ColumnsType<SystemType.DictTypeData> = [
        {
            title: '键名',
            dataIndex: 'label',
            render: (text, item) => {
                return (
                    <div>
                        {editItem === item.id ?
                            <Form.Item
                                name='label'
                                style={{margin: 0}}
                                rules={[
                                    {
                                        required: true,
                                        message: `请输入键名`,
                                    },
                                ]}
                            >
                                <Input maxLength={16}/>
                            </Form.Item>

                            : <div>{text}</div>}
                    </div>
                )

            },
        },
        {
            title: '键值',
            dataIndex: 'value',
            render: (text, item) => {
                return (
                    <div>
                        {editItem === item.id ?
                            <Form.Item
                                name="value"
                                style={{margin: 0}}
                                rules={[
                                    {
                                        required: true,
                                        message: `请输入键值`,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>

                            : <div>{text}</div>}
                    </div>
                )

            },
        },
        {
            title: '样式',
            dataIndex: 'style',
            render: (text, item) => {
                return (
                    <div>
                        {editItem === item.id ?
                            <Form.Item
                                name="style"
                                style={{margin: 0}}
                            >
                                <Input/>
                            </Form.Item>

                            : <div>{text ? text : "无"}</div>}
                    </div>
                )

            },
        },
        {
            title: '排序',
            dataIndex: 'sort',
            width: 80,
            render: (text, item) => {
                return (
                    <div>
                        {editItem === item.id ?
                            <Form.Item
                                name='sort'
                                style={{margin: 0}}
                            >
                                <InputNumber min={0}/>
                            </Form.Item>

                            : <div>{text}</div>}
                    </div>
                )

            },
        },

        {
            title: '操作',
            key: 'action',
            width: 100,
            render: (key, item) => {
                return (editItem !== item.id ?
                        <Space>
                            <Button
                                onClick={() => {
                                    editFormRef.current.setFieldsValue({
                                        label: item.label,
                                        value: item.value,
                                        style: item.style,
                                        sort: item.sort
                                    });
                                    setEditItem(item.id)
                                }} type="dashed" size='small' icon={<EditOutlined/>}/>
                            <Popconfirm title="确定删除?"
                                        onConfirm={() => onDelete([item.id])}>
                                <Button type="dashed" size='small'
                                        icon={<DeleteOutlined/>}/>
                            </Popconfirm>
                        </Space> :
                        <Space>
                            <Button type="primary" danger size='small' onClick={() => {
                                editFormRef.current.setFieldsValue({
                                    label: item.label,
                                    value: item.value,
                                    style: item.style,
                                    sort: item.sort
                                });
                                setEditItem(0)
                            }} icon={<CloseOutlined/>}/>
                            <Button type="primary" size='small' onClick={() => onUpdate(item)} icon={<CheckOutlined/>}/>
                        </Space>
                )
            }
        },
    ];
    /**
     * 操作提示信息
     */
    const showConfirm = () => {
        modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要删除这些数据吗？</div>,
            onOk() {
                  onDelete(selectedRowKeys)
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }



    const onSearch = (e:string) => {
        setCurrent(1)
        if (e.length === 0) {
            setData(getDict(props.type))
            return
        }
        const searchData: SystemType.DictTypeData[] = data.filter((el) => el.label.toLowerCase().indexOf(e.toLowerCase()) > -1)
        setData(searchData)
    }

    const onCancel = () => {
        setOpen(false)
        //  form.resetFields()

    }

    /**
     * 选择列表
     * @param {Array} selectedRowKeys
     */
    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys)
    };
    return (
        <>
            <Row className='mb-4' justify='space-between'>
                <Col>
                    <Space>
                        <Button disabled={props.type.length === 0 } type="primary" onClick={() => setOpen(true)}>
                            添加
                        </Button>
                        <Button disabled={selectedRowKeys.length === 0} danger onClick={showConfirm}>
                            删除
                        </Button>
                        <Text> {`已选择 ${selectedRowKeys.length} 条数据`} </Text>
                    </Space>
                </Col>
                <Col>
                    <Input.Search
                        allowClear
                        onSearch={onSearch}
                        placeholder="输入名称搜索"
                        enterButton="搜索"
                    />
                </Col>
            </Row>

            <Form ref={editFormRef} component={false}>
                <Table
                    loading={spin}
                    rowSelection={ {
                        selectedRowKeys,
                        onChange: onSelectChange,
                    }}
                    rowKey={(record) => record.id}
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        current: current,
                        total: data.length,
                        defaultCurrent: 1,
                        onChange: (page) => {
                            setCurrent(page)
                        },
                        showTotal: total => `总计 ${total} 条数据`,
                    }}/>
            </Form>
            <Modal destroyOnClose title="添加数据" okText='添加' open={open} onOk={onFinish} centered
                   onCancel={onCancel} okButtonProps={{disabled: loading}}>
                    <Spin spinning={loading}>
                        <Form
                            ref={formRef}
                            labelCol={{span: 4}}
                            wrapperCol={{span: 16}}
                            name="basic_dict_type"
                            initialValues={{
                                sort: 0
                            }}
                        >
                            <Form.Item
                                label="键名"
                                name="label"
                                rules={[{required: true, message: '请输入键名！'}]}
                            >
                                <Input  placeholder='输入键名'/>
                            </Form.Item>
                            <Form.Item
                                label="键值"
                                name="value"
                                rules={[{required: true, message: '请输入键值！'}]}
                            >
                                <Input placeholder='输入键值'/>
                            </Form.Item>
                            <Form.Item
                                label="样式"
                                name="style"
                            >
                                <Input placeholder='输入样式'/>
                            </Form.Item>
                            <Form.Item
                                label="排序"
                                name="sort"
                            >
                                <InputNumber min={0}/>
                            </Form.Item>
                        </Form>
                    </Spin>
            </Modal>
        </>
    )
}
export default DictType;
