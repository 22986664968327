/**
 * Notes: 管理员权限
 * User: 李强
 * DateTime: 2021/12/8 15:47
 * Ide: PhpStorm
 */


import React, {useState, useRef, useEffect} from 'react'
import {
    Button,
    Typography,
    Input,
    List,
    Card,
    Tooltip,
    Row,
    Col, Popconfirm, Space, App,
} from "antd";
import {DeleteFilled, PlusOutlined} from "@ant-design/icons";
import {useRecoilState, useSetRecoilState} from "recoil";
import {permissionState, rolesState} from "../../../store";
import {useHttps, usePermission} from "../../../hooks";
import Create from './Create'
import Edit from "./Edit";
const {Text, Paragraph} = Typography
const Roles = () => {
    const {message} = App.useApp();
    const [roles, setRoles] = useRecoilState(rolesState);
    const setPermission = useSetRecoilState(permissionState);
    const [loading, setLoading] = useState(false)
    const {destroy, get} = useHttps();
    const [searchVisible, setSearchVisible] = useState(false)
    const [data, setData] = useState<SystemType.Roles[]>([]);
    const [current, setCurrent] = useState(1)
    const {isPermission} = usePermission();
    const createRef = useRef<any>()
    const editRef = useRef<any>()


    useEffect(() => {
        get('admin/roles', null, true)
            .then((res:Request.Response<SystemType.Roles[]>) => {
                if (res.mounted) {
                    setLoading(false)
                    setRoles(res.data);
                }
            })
            .catch(async (e:Request.Error) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message)
                }
            });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get, setRoles]);


    /**
     * 获取权限
     */
    useEffect(() => {
        get('admin/permission', null, true)
            .then((res:Request.Response<SystemType.Permission[]>) => {
                if (res.mounted) {
                    setPermission(res.data);
                }
            })
            .catch(async (e:Request.Error) => {
                if (e.mounted) {
                    message.error(e.message)
                }
            });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get, setPermission]);


    /**
     * 删除角色
     * @param id
     */


    const onDelete = (id: number) => {
        setLoading(true)
        destroy('admin/roles/' + id)
            .then(async (res: Request.Response<SystemType.Roles>) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('删除角色成功！');
                    const dataSource = [...roles];
                    const select = dataSource.findIndex((value) => value.id === id);
                    dataSource.splice(select, 1);
                    setRoles(dataSource)
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    }


    /**
     * 搜索
     * @param e
     */
    const onSearch = (e: string) => {
        setCurrent(1)
        if (e.length === 0) {
            setData([])
            setSearchVisible(false)
            return
        }
        const search: Array<SystemType.Roles> = roles.filter((el) => el.name.toLowerCase().indexOf(e.toLowerCase()) > -1)
        setSearchVisible(true)
        setData(search)
    }


    /**
     * 添加角色
     * @param params
     */
    const onCreate = (params: SystemType.Roles) => {
        const dataSource = [...roles]
        dataSource.unshift(params)
        setRoles(dataSource)
        setSearchVisible(false)
        setCurrent(1)
    }

    /**
     * 编辑角色
     * @param params
     */

    const onEdit = (params: SystemType.Roles) => {
        const dataSource = [...roles]
        const index = dataSource.findIndex((item) => item.id === params.id)
        dataSource[index] = params;
        setRoles(dataSource)
        if(searchVisible){
            setSearchVisible(false)
            setCurrent(1)
        }
    }
    return (
        <>
            <div className='mb-3'>
                <Row justify="space-between">
                     <Col>
                           <Button
                            disabled={!isPermission('roles.post')}
                            onClick={() => createRef.current.onOpen()}
                            type="primary"
                            icon={<PlusOutlined/>}> 添加角色 </Button>
                    </Col>
                    <Col>
                        <Tooltip color='red' open={searchVisible} title="暂无搜索内容">
                            <Input.Search
                                allowClear
                                onSearch={onSearch}
                                placeholder="输入角色名搜索"
                                enterButton="搜索"
                            />
                        </Tooltip>
                    </Col>
                </Row>
            </div>
            <div>
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 2,
                        xl: 3,
                        xxl: 5,
                    }}
                    pagination={{
                        total: searchVisible ?  data.length: roles.length ,
                        defaultCurrent: 1,
                        current: current,
                        showSizeChanger: true,
                        pageSize: 30,
                        showTotal: total => `总计 ${total} 个角色`,
                        onChange: (page) => setCurrent(page)
                    }}
                    loading={loading}
                    dataSource={searchVisible ? data : roles}
                    renderItem={item => (
                        <List.Item className='p-0' >
                                <Card  key={item.id} size="small" title={item.name}
                                      extra={
                                          isPermission('roles.post') && item.id !== 2 && <Popconfirm title="确定删除?" onConfirm={(e) => {
                                                e?.stopPropagation()
                                                onDelete(item.id)
                                            }}>
                                                <DeleteFilled/>
                                            </Popconfirm>
                                }
                                >
                                   <div style={{height: 80, cursor:'pointer'}} onClick={()=>editRef.current.onOpen(item.id)}>
                                       <Space direction='vertical'>
                                           <Text type='secondary'>角色标识：{item.slug}</Text>
                                           <Paragraph ellipsis={{rows: 2, tooltip: true}}> {item.description}</Paragraph>
                                       </Space>
                                   </div>
                                </Card>
                        </List.Item>
                    )}
                />
            </div>
            <Create ref={createRef} onChange={onCreate}/>
            <Edit ref={editRef} onChange={onEdit}/>
        </>
    )
}
export default Roles;
