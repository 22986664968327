import React, {useState, forwardRef, useImperativeHandle} from 'react'
import {
    Modal,
    App, Table,
} from "antd";

import {useHttps} from "../../hooks";
import type {ColumnsType, TablePaginationConfig} from "antd/es/table";

interface CourseParams {
    page?: number;
    per_page?: number;
    course_id?: number;
}
const Edit = forwardRef((props, ref) => {
    const { message } = App.useApp();
    const [id, setId] = useState(0);
    const [open, setOpen] = useState(false);
    const {get} = useHttps();
    const [data, setData] = useState<CourseType.Log[]>([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const [loading, setLoading] = useState(true)


    useImperativeHandle(ref, () => ({
        onOpen: (id: number) => {
            setId(id)
            setOpen(true)
            getData({page: 1, per_page: 20, course_id:id})

        },
    }));

    /**
     * 更新文章内容
     */
    const getData = (params:CourseParams) => {
        get('admin/course/logs', params, true)
            .then((res:Request.Response<CourseType.Log[]>) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }

            })
            .catch(async (e) => {
                if (e.mounted) {
                    message.error(e.message)
                    setLoading(false);
                }
            });
    }


    /**
     * 关闭模态框
     */
    const onCancel = () => {
        setLoading(false)
        setOpen(false)
    }
    /**
     * 自定义表格
     * @type {Object}
     */

    const columns: ColumnsType<CourseType.Log> = [
        {
            title: '用户',
            dataIndex: 'nickname',
        },

        {
            title: '学习日期',
            dataIndex: 'created_at',
        },
    ];
    /**
     * 表格操作触发
     * @param pagination
     */
    const onPaginationChange = (pagination: TablePaginationConfig) => {
        setLoading(true);
        const params: CourseParams = {
            page: pagination.current,
            per_page: pagination.pageSize,
            course_id:id,
        }
        getData(params);
    }


    return (
        <Modal destroyOnClose title="学习记录" width={720}  open={open} centered footer={null} onCancel={onCancel} >
            <Table
                size="small"
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={{
                    size: 'default',
                    total: total,
                    current: current,
                    showTotal: total => `总计 ${total} 条记录`,
                    pageSize: perPage,
                }}
                onChange={onPaginationChange}
                rowKey={(record) => {
                    return record.id
                }}
            />
        </Modal>
    )
})
export default Edit;
