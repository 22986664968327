import React, {forwardRef, useImperativeHandle, useState} from "react";
import {
    Form,
    Input,
    InputNumber,
    Modal,
    Radio,
    Spin,
    Switch,
    TreeSelect,
    Select,
    Space,
    Typography, App,
} from "antd";
import {useDict, useHttps} from "../../../hooks";
import {useRecoilState, useRecoilValue} from "recoil";
import {permissionState, treeMenuState} from "../../../store";
import menuIcons from "../../../routers/icons";
const {Text} = Typography

const Edit = forwardRef((prop, ref) => {
    const [loading, setLoading] = useState(false)
    const [id, setId] = useState(0)
    const [editOpen, setEditOpen] = useState(false)
    const {put , get} = useHttps();
    const [form] = Form.useForm();
    const [permission, setPermission] = useRecoilState<SystemType.Permission[]>(permissionState);
    const treeMenus = useRecoilValue<SystemType.Permission[]>(treeMenuState);
    const {getDict} = useDict();
    const { message } = App.useApp();

    useImperativeHandle(ref, () => ({
        onOpen: (id : number) => {
            setId(id)
            getData(id)
            setEditOpen(true)
        },
    }));

    const  getData = (id:number) => {
        setLoading(true)
        get('admin/permission/' + id, null, true)
            .then((res: Request.Response<SystemType.Permission>) => {
                if (res.mounted) {
                    res.data.parent_id = res.data.parent_id === 0 ? undefined : res.data.parent_id;
                    form.setFieldsValue(res.data)
                    setLoading(false);
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                }

            });
    };
    /**
     * 添加菜单操作
     */

    const onUpdate = async () => {
        try {
            let values = await form.validateFields();
            setLoading(true);
            values.parent_id = values.parent_id ? values.parent_id : 0
            put('admin/permission/' + id, values)
                .then((res: Request.Response<SystemType.Permission>) => {
                    if (res.mounted) {
                        let dataSource = [...permission]
                        const select = dataSource.findIndex((value) => value.id === id);
                        dataSource[select] = res.data ;
                        setPermission(dataSource)
                        setLoading(false)
                        setEditOpen(false)
                        form.resetFields()
                    }
                })
                .catch(async (e:Request.Error) => {
                    if (e.mounted) {
                        message.error(e.message);
                        setLoading(false);
                    }
                });
        } catch (err) {
            return;
        }

    };

    /**
     * 关闭模态框
     */
    const onCancel = () => {
        setEditOpen(false)
        form.resetFields()
    }


    return (
        <Modal destroyOnClose title="编辑菜单" width={820} okText='更新' open={editOpen} onOk={onUpdate} centered
               onCancel={onCancel}  okButtonProps={{ disabled: loading }}>
            <Spin spinning={loading}>
                <Form
                    form={form}
                    labelCol={{span: 4}}
                    wrapperCol={{span: 16}}
                    name="basic_permission_edit"
                    initialValues={{
                        show: false,
                        type: 0,
                        sort:0
                    }}
                >
                    <Form.Item
                        label="菜单名称"
                        name="label"
                        rules={[{required: true, message: '请输入菜单名称！'}]}
                    >
                        <Input style={{width: 200}} placeholder='输入菜单名称'/>
                    </Form.Item>
                    <Form.Item
                        label="菜单类型"
                        name="type"
                    >
                        <Radio.Group value={0}>
                            {getDict('menus').map((item) => <Radio key={item.value} value={item.value}>{item.label}</Radio>)}
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="排序"
                        name="sort"
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                        label="上级菜单"
                        name="parent_id"
                        rules={[{
                            required: false, validator: async (rule, value) => {
                                if (id === value) {
                                    await Promise.reject('不能选择自己的上级菜单！')
                                }
                                await Promise.resolve()
                            }
                        }]}
                    >
                        <TreeSelect
                            style={{width: 400}}
                            showSearch
                            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                            placeholder="选择上层菜单"
                            allowClear
                            fieldNames={{value: 'id', label: 'label', children: 'children'}}
                            treeData={treeMenus}
                        />
                    </Form.Item>

                    <Form.Item
                        label="链接地址"
                        name="url"
                        rules={[{
                            required: true,
                            message: '请输入链接地址！',
                        }
                        ]}
                    >
                        <Input placeholder='输入链接地址'/>
                    </Form.Item>
                    <Form.Item
                        label="菜单图标"
                        name="icon"
                    >
                        <Select
                            showSearch
                            allowClear
                            style={{width: 220}}
                            placeholder="选择图标"
                            optionLabelProp="label"
                        > {Object.keys(menuIcons).map((item, index) =>
                            <Select.Option key={index} value={item} label={<span>{menuIcons[item]} {item}</span>}>
                                <Space direction={'horizontal'}> {menuIcons[item]} <Text> {item} </Text></Space>
                            </Select.Option>
                        )}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        valuePropName="checked"
                        label="是否显示"
                        name="is_show"
                    >
                        <Switch checkedChildren="显示" unCheckedChildren="隐藏"/>
                    </Form.Item>
                    <Form.Item
                        label="权限标识"
                        name="slug"
                        rules={[{
                            required: true,
                            message: '请输入权限标识！',
                        }
                        ]}
                    >
                        <Input style={{width: 200}} placeholder="请输入权限标识"/>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )


})

export default Edit;
