import React, {useState} from 'react'

import {useSetRecoilState} from "recoil";
import {Button, Form, Image, Input, Alert, Typography, Row, Col, Card, Space, Flex} from 'antd';
import {CopyrightOutlined, LockOutlined, UserOutlined, MinusOutlined, CloseOutlined, BorderOutlined} from '@ant-design/icons';
import {Copyright, Logo} from '../../config/Config';
import {authState, tokenState} from '../../store';
import {useNavigate} from "react-router-dom";
import Background from '../../assets/loginBg.png';
import {useHttps, useElectron} from "../../hooks";
const {Text, Title} = Typography

const Login = () => {
    const navigate = useNavigate();
    const setToken = useSetRecoilState(tokenState);
    const setUser = useSetRecoilState(authState)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState<string | null>()
    const {post} = useHttps();
    const {electron, isElectron} = useElectron()


    const onFinish = (values: [index: string]) => {
        setLoading(true)
        setError(false)
        post('web/admin/login', values, false)
            .then((res: Request.Response<AuthType.Data>) => {
                if (res.mounted) {
                        setLoading(false);
                        sessionStorage.setItem("auth", JSON.stringify(res.data.user));
                        sessionStorage.setItem("token", res.data.token);
                        sessionStorage.setItem("role", JSON.stringify(res.data.user.roles));
                        setUser(res.data.user);
                        setToken(res.data.token);
                        navigate('/home')
                }
            })
            .catch((e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    setError(true)
                    setMessage(e.message);
                }
            });
    };

    return (
        <div style={{backgroundColor:"#f8f9fa", backgroundImage: `url(${Background})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',}} className='vh-100'>

            {isElectron && <Row justify='end'>
                    <Col className='electron-container p-3' flex='1' ></Col>
                    <Col  className='p-3'>
                        <Space size='large'>
                            <Button size='small' type="text" icon={<MinusOutlined />} onClick={()=>  electron.ipcRenderer.send('windows-control', 'minimize')} />
                            <Button size='small' type="text" icon={<BorderOutlined />} onClick={()=> {
                                electron.ipcRenderer.send('windows-control','restore')
                            }}/>
                            <Button size='small'  type="text" icon={<CloseOutlined />} onClick={()=>  electron.ipcRenderer.send('windows-control', 'quit')} />
                        </Space>
                    </Col>
                </Row>}


            <Flex justify='center' align='center' className='h-75'>
                <Card style={{width:350}}>
                <header className='text-center'>
                    <Image
                        className='mb-4'
                        preview={false}
                        width={160}
                        src={Logo.full}
                    /></header>
                {error && <Alert message={message} type="error" showIcon className='mb-3' style={{width: 320}}/>}
                <section className='bg-white p-3'>
                    <Title className="mt-0 mb-3" level={5}>管理员登录</Title>
                    <Form
                        name="normal_login"
                        className="login-form"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="username"
                            validateFirst
                            rules={[
                                {
                                    // 必填
                                    required: true,
                                    // 校验错误，就不会继续往下校验
                                    whitespace: true,
                                    message: '请输入用户名',
                                },
                                {
                                    min: 4,
                                    message: '用户名最少4位',
                                },
                                {
                                    max: 12,
                                    message: '用户名最多12位',
                                },
                                {
                                    pattern: /^[a-zA-Z0-9_]+$/,
                                    message: '用户名必须是英文、数字或下划线组成',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="用户名"/>
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '请输入密码',
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon"/>}
                                type="password"
                                placeholder="密码"
                            />
                        </Form.Item>


                        <Form.Item>
                            <Button
                                style={{backgroundColor:'#5ca900'}}
                                type="primary"
                                htmlType="submit"
                                className="w-100"
                                loading={loading}
                            >
                                登录
                            </Button>
                        </Form.Item>
                    </Form>
                    <Text type="secondary">如遇其他问题请联系管理员</Text>
                </section>
                <footer  className="mt-4 text-center">
                    <Text type="secondary"><CopyrightOutlined/> {Copyright}</Text>
                </footer>
                </Card>
            </Flex>
        </div>
    )
}

export default Login
