import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    CloseOutlined,
    MinusCircleOutlined,
    HomeOutlined,
    SyncOutlined,
    RightOutlined,
    LeftOutlined,
    CloseCircleOutlined
} from "@ant-design/icons";
import {Space, Tabs} from "antd";
import {Item, Menu, Separator, useContextMenu} from 'react-contexify';

import {useRecoilValue} from "recoil";
import {algorithmState, roleState} from "../../store";
import {subText} from "../../utils/utils";


type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

const TabMenu = () => {
    const algorithm = useRecoilValue(algorithmState);
    const { menus }= useRecoilValue(roleState)
    let {pathname} = useLocation();
    const [activeKey, setActiveKey] = useState('/');
    const {show} = useContextMenu();
    const [items, setItems] = useState([
        {
            key: '/home',
            label: '首页',
            closeIcon: <HomeOutlined/>,
            state: undefined
        }
    ]);
    const navigate = useNavigate();


    /**
     * 触发重新载入页面
     * @param newActiveKey
     */

    const onChange = (newActiveKey: string) => {
        const data = items.find((item) => item.key === newActiveKey)
        if (data && data.key) {
            setActiveKey(newActiveKey);
            navigate(newActiveKey)
        }
    };

    useEffect(() => {
        const isKey = items.find((item) => item.key === pathname)
        if (!isKey) {
            const newPanes = [...items];
            console.log(menus)
                const data = menus.find((item) => item.url === pathname)
                if (data) {
                    newPanes.push({closeIcon: <CloseOutlined/>, label: subText(data?.label, 6)  || '404页', key: pathname, state: undefined});
                    setItems(newPanes);
                    setActiveKey(pathname);
                }
        } else {
            setActiveKey(isKey.key);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    /**
     * 删除标签
     * @param targetKey
     */
    const onRemove = async (targetKey: TargetKey) => {
        if (targetKey === '/home') {
            return
        }


        let newActiveKey = activeKey;
        let lastIndex = -1;
        items.forEach((item, i) => {
            if (item.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = items.filter((item) => item.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
            } else {
                newActiveKey = newPanes[0].key;
            }
        }
        navigate(newActiveKey)
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };


    /**
     * 删除菜单
     * @param targetKey
     * @param action
     */
    const onEdit = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: 'add' | 'remove',) => {
        if (action === 'remove') {
            onRemove(targetKey);
        }
    };

    /**
     * 空白区右键菜单
     * @param event
     * @param params
     */
    const onMenu = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, params: any) => {
        event.stopPropagation();
        // 选中效果
        show({
            event,
            props: params,
            id: "menuTabId"
        })
    }

    /**
     * 右键点击事件
     * @param params
     */
    const onItemClick = async (params: any) => {
        const home = {
            key: '/home',
            label: '首页',
            closeIcon: <HomeOutlined/>,
            state:undefined
        }
        const dataSource = [...items];
        const index = items.findIndex((item) => item.key === params.props)
        const activeIndex = items.findIndex((item) => item.key === activeKey)
        //关闭当前页
        if (params.id === 'menuSelf') {
            onRemove(params.props);
        }
        //关闭左侧
        if (params.id === 'menuLeft') {
            if (activeKey !== '/home') {
                const rightItems = dataSource.slice(index)
                rightItems.unshift(home)
                if (activeIndex < index) {
                    navigate(params.props)
                }
                setItems(rightItems)
            }
        }
        //关闭右侧
        if (params.id === 'menuRight') {
            dataSource.length = index + 1;
            if (activeIndex > index) {
               navigate(params.props)
            }
            setItems(dataSource)
        }
        //关闭全部
        if (params.id === 'menuAll') {
            const item = dataSource.find((item) => item.key === params.props)
            if (item) {
                setItems([home])
                navigate('/home')
            }
        }
        //关闭其他
        if (params.id === 'menuOther') {
            const item = dataSource.find((item) => item.key === params.props)
            if (item) {
                setItems([...[home, ...[item]]])
                navigate(params.props)
            }
        }
        // 刷新当前页面
        if (params.id === 'refresh') {
            //刷新
        }
    }


    return (
        <>
            <Tabs
                renderTabBar={(tabBarProps, DefaultTabBar) => (
                    <DefaultTabBar {...tabBarProps}>
                        {(node) => (
                            <div style={{marginLeft: 2}} onContextMenu={(e) => onMenu(e, node.key)} key={node.key}>
                                {node}
                            </div>
                        )}
                    </DefaultTabBar>
                )}
                onChange={onChange}
                activeKey={activeKey}
                onEdit={onEdit}
                type='editable-card'
                hideAdd
                tabBarStyle={{marginBottom: 0, paddingLeft: 20, paddingRight: 20, borderBottomColor: '#FFFFFF'}}
                defaultActiveKey="1"
                items={items}
            />
            <Menu id='menuTabId' className={`${algorithm ? 'contexify-dark' : 'contexify'}`}>
                <Item id="menuSelf" disabled={activeKey === '/home'} onClick={onItemClick}><Space
                    size='middle'><CloseOutlined/>关闭当前页</Space></Item>
                <Item id="menuLeft" onClick={onItemClick}><Space size='middle'><LeftOutlined/>关闭左侧</Space></Item>
                <Item id="menuRight" onClick={onItemClick}><Space size='middle'><RightOutlined/>关闭右侧</Space></Item>
                <Item id="menuOther" onClick={onItemClick}><Space
                    size='middle'><MinusCircleOutlined/>关闭其他</Space></Item>
                <Item id="menuAll" onClick={onItemClick}><Space
                    size='middle'><CloseCircleOutlined />关闭全部</Space></Item>
                <Separator/>
                <Item id="refresh" onClick={onItemClick}><Space size='middle'><SyncOutlined/>刷新</Space></Item>
            </Menu>
        </>

    )
}
export default TabMenu;