/**
 * Created by devin on 2021/4/21.
 */

import React, {useState,useEffect} from 'react'
import {
    Button,
    Card,
    Form,
    Input,
    InputNumber,
    message,
    Spin,
    Switch,
    Space
} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {useHttps} from "../../../hooks";
import {FileSelect} from "../../../components";



const Options = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const {get, post} = useHttps();
    const [loading, setLoading] = useState(true)
    const [shareImage, setShareImage] = useState<StorageType.Data[]>()
    const [memberAd, setMemberAd] = useState<StorageType.Data[]>()
    const [form] = Form.useForm();



    useEffect(() => {
        get('admin/options', null, true)
            .then((res: Request.Response<OptionsType.Data>) => {
                if (res.mounted) {
                    setLoading(false);
                    setShareImage(res.data.share_image ? [{
                        id: '-1',
                        title: res.data.share_title,
                        type: 1,
                        mini_type: 'png',
                        url: res.data.share_image,
                        parent_id: '0',
                        size: 0,
                        created_at: new Date()
                    }] : [])
                    setMemberAd(res.data.member_ad ? [{
                        id: '-2',
                        title: res.data.app_name,
                        type: 1,
                        mini_type: 'jpg',
                        url: res.data.member_ad,
                        parent_id: '0',
                        size: 0,
                        created_at: new Date()
                    }] : [])
                    form.setFieldsValue(res.data)
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    messageApi.error(e.message)
                }
            });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, get]);



    /**
     * 表格操作触发
     * @param params
     */
    const onFinish  = (params: OptionsType.Data) => {
        setLoading(true);
        post('admin/options', params)
            .then(async (res: Request.Response<OptionsType.Data>) => {
                if (res.mounted) {
                    messageApi.success("设置已保存！")
                    setLoading(false);
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    messageApi.error(e.message)
                }
            });
    }



    return (
        <div style={{maxWidth:1200}}>
            {contextHolder}
            <Spin size="large" spinning={loading}>
                <Form
                    labelCol={{span: 4}}
                    wrapperCol={{span: 16}}
                    form={form}
                    name="basic"
                    initialValues={{
                        app_name: '',
                        pay_note: '',
                        is_pay: true,
                        chat_image_note: '',
                        is_online_service: false,
                        member_ad: '',
                        context_count: 5,
                        share_title: '',
                        share_image: '',
                        share_path: '',
                        question_study: [],
                        question_work: [],
                        question_writes: [],
                    }}
                    onFinish={onFinish}
                >

                    <Card title='基本设置' className='mb-3'>

                            <Form.Item
                                label="APP名称"
                                name="app_name"
                                rules={[{required: true, message: '请输入APP名称！'}, {
                                    whitespace: true,
                                    message: '请输入APP名称！'
                                }]}
                            >
                                <Input placeholder='请输入APP名称' maxLength={10}/>
                            </Form.Item>
                            <Form.Item
                                label="支付备注"
                                name="pay_note"
                                rules={[{required: true, message: '请输入支付备注！'}, {
                                    message: '请输入支付备注信息！'
                                }]}
                            >
                                <Input.TextArea  placeholder='输入支付备注信息'/>
                            </Form.Item>
                        <Form.Item
                            label="是否开启支付"
                            name="is_pay"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                        <Form.Item
                            label="会员中心广告"
                            name='member_ad'
                            extra="上传尺寸建议980x460"
                            className="mt-3"
                            rules={[{required: true, message: '请选择缩略图！'}]}
                        >
                            <div className='w-100'>
                                <FileSelect
                                    defaultFileList={memberAd}
                                    listType='picture-card'
                                    text='上传广告'
                                    size={1}
                                    type={1}
                                    onChange={(e) => {
                                        form.setFieldsValue({member_ad: e[0]?.url || ''})
                                    }}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="是否开启客服"
                            name="is_online_service"
                            valuePropName="checked"
                        >
                            <Switch  />
                        </Form.Item>
                    </Card>
                    <Card title='小程序设置' className='mb-3'>

                        <Form.Item
                            label="分享标题"
                            name="share_title"
                            rules={[{required: true, message: '请输入分享标题！'}]}
                        >
                            <Input placeholder='请输入分享标题' />
                        </Form.Item>

                        <Form.Item
                            label="分享封面"
                            name='share_image'
                            extra="上传尺寸建议500x400"
                            className="mt-3"
                            rules={[{required: true, message: '请选择封面图！'}]}
                        >
                            <div className='w-100'>
                                <FileSelect
                                    defaultFileList={shareImage}
                                    listType='picture-card'
                                    text='上传封面'
                                    size={1}
                                    type={1}
                                    onChange={(e) => {
                                        form.setFieldsValue({share_image: e[0]?.url || ''})
                                    }}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="分享链接"
                            name="share_path"
                        >
                            <Input placeholder='请输入分享链接' />
                        </Form.Item>
                    </Card>
                    <Card title='ChatGPT设置' className='mb-3'>

                        <Form.Item
                            label="上下文数量"
                            name="context_count"
                        >
                            <InputNumber min={3} max={9}  />
                        </Form.Item>
                        <Form.Item
                            label="生成图片备注"
                            name="chat_image_note"
                            rules={[{required: true, message: '请输入生成图片备注！'}]}
                        >
                            <Input.TextArea  placeholder='输入生成图片备注信息'/>
                        </Form.Item>
                        <Form.Item
                            label="预设工作问题"
                            className='mb-0'
                        >
                            <Form.List name="question_work">
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map(({key, name, ...restField}) => (
                                            <div className='d-flex align-items-center align-content-center mb-3'
                                                 key={key}>
                                                <Form.Item
                                                    className='w-100 me-3 mb-0'
                                                    {...restField}
                                                    name={[name]}
                                                    rules={[{required: true, message: '请输入工作问题'}]}
                                                >
                                                    <Input maxLength={200} placeholder="输入工作问题"/>
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)}/>
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button onClick={() => add()} icon={<PlusOutlined/>}>
                                                添加信息
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>
                        <Form.Item
                            label="预设学习问题"
                            className='mb-0'
                        >
                            <Form.List name="question_study">
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map(({key, name, ...restField}) => (
                                            <div className='d-flex align-items-center align-content-center mb-3'
                                                 key={key}>
                                                <Form.Item
                                                    className='w-100 me-3 mb-0'
                                                    {...restField}
                                                    name={[name]}
                                                    rules={[{required: true, message: '请输入学习问题'}]}
                                                >
                                                    <Input maxLength={200} placeholder="输入学习问题"/>
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)}/>
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button onClick={() => add()} icon={<PlusOutlined/>}>
                                                添加信息
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>
                        <Form.Item
                            label="预设写作问题"
                            className='mb-0'
                        >
                            <Form.List name="question_writes">
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map(({key, name, ...restField}) => (
                                            <div className='d-flex align-items-center align-content-center mb-3'
                                                 key={key}>
                                                <Form.Item
                                                    className='w-100 me-3 mb-0'
                                                    {...restField}
                                                    name={[name]}
                                                    rules={[{required: true, message: '请输入写作问题'}]}
                                                >
                                                    <Input maxLength={200} placeholder="输入写作问题"/>
                                                </Form.Item>
                                                <MinusCircleOutlined onClick={() => remove(name)}/>
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button onClick={() => add()} icon={<PlusOutlined/>}>
                                                添加信息
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            <Space className='mt-3'>
                                <Button type="primary" htmlType="submit">
                                    保存
                                </Button>
                                <Button type="dashed" htmlType="reset">重置</Button>
                            </Space>
                        </Form.Item>
                    </Card>
                </Form>
            </Spin>
        </div>
    )
}

export default Options
