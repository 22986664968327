/**
 * Created by devin on 2021/4/21.
 */

import React, {useEffect, useRef, useState} from 'react'
import {useRecoilState, useRecoilValue} from 'recoil';
import {App, Button, Card, Col, Input, Popconfirm, Row, Select, Space, Table, Tag,} from 'antd';
import {DeleteOutlined, EditOutlined, PlusOutlined,} from '@ant-design/icons';
import type {ColumnsType} from 'antd/es/table';
import {useDict, useHttps} from "../../../hooks";
import {permissionState, treeMenuState} from "../../../store";
import Create from "./Create";
import Edit from "./Edit";

const Menus = () => {
    const {message} = App.useApp();
    const {destroy, get} = useHttps();
    const [loading, setLoading] = useState(true)
    const [permission, setPermission] = useRecoilState<SystemType.Permission[]>(permissionState);
    const treeMenus = useRecoilValue<SystemType.Permission[]>(treeMenuState);
    const [searchOpen, setSearchOpen] = useState(false)
    const [current, setCurrent] = useState(1)
    const [selectType, setSelectType] = useState('-1')
    const [search, setSearch] = useState<SystemType.Permission[]>([])
    const createRef = useRef<any>(null);
    const editRef = useRef<any>(null);
    const {getDictValue, getDict} = useDict();


    useEffect(() => {
        get('admin/permission', null, true)
            .then((res: Request.Response<SystemType.Permission[]>) => {
                if (res.mounted) {
                    setLoading(false)
                    setPermission(res.data);
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message)
                }
            });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get, setPermission]);

    /**
     * 自定义表格
     * @type {Object}
     */

    const columns: ColumnsType<SystemType.Permission> = [{
        title: '菜单名称', dataIndex: 'label',
    }, {
        title: '排序',
        dataIndex: 'sort',
        width: 120,
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.sort - b.sort,
    }, {
        title: '链接地址', dataIndex: 'url',
    }, {
        title: '权限标识', dataIndex: 'slug',
    }, {
        title: '是否显示', dataIndex: 'is_show', render: (tags) => {
            const dict = getDictValue('is_show', tags ? '1' : '0');
            return (<Tag color={dict?.style}>{dict?.label}</Tag>)
        }
    }, {
        title: '类型', dataIndex: 'type', render: (tags) => {
            const dict = getDictValue('menus', tags);
            return (<Tag color={dict?.style}>{dict?.label}</Tag>)
        }
    }, {
        title: '操作', dataIndex: 'action', render: (key, item) => {
            return (<Space>
                <Button onClick={() => editRef.current.onOpen(item.id)}
                        type="dashed" size='small' icon={<EditOutlined/>}/>
                <Popconfirm title="确定删除?"
                            onConfirm={() => onDelete(item.id)}>
                    <Button type="dashed"
                            size='small' icon={<DeleteOutlined/>}/>
                </Popconfirm>
            </Space>)
        }
    },];

    /**
     * 删除列表操作
     * @param {number} id  数组id
     */
    const onDelete = (id: number) => {
        setLoading(true);
        destroy('admin/permission/' + id)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    let dataSource = [...permission]
                    const select = dataSource.findIndex((value) => value.id === id);
                    dataSource.splice(select, 1);
                    setPermission(dataSource)
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });

    };


    /**
     * 搜索
     * @param e
     */
    const onSearch = (e: string) => {
        setCurrent(1)
        if (e.length === 0) {
            setSearch([])
            setSearchOpen(false)
            return
        }
        const searchData: SystemType.Permission[] = permission.filter((el) => el.label.toLowerCase().indexOf(e.toLowerCase()) > -1)
        setSearchOpen(true)
        setSearch(searchData)
    }


    /**
     * 按类型过滤
     */
    const onType = () => {
        setCurrent(1)
        if (selectType === '-1') {
            setSearch([])
            setSearchOpen(false)
            return
        }
        const searchData: SystemType.Permission[] = permission.filter((el) => el.type === selectType)
        setSearchOpen(true)
        setSearch(searchData)
    }

    /**
     * 返回视图
     */

    return (<>
        <Card>
            <div className='mb-4'>
                <Row justify='space-between'>
                    <Col>
                        <Button
                            onClick={() => createRef.current.onOpen()}
                            type="primary"
                            icon={<PlusOutlined/>}
                        >
                            添加菜单
                        </Button>

                    </Col>
                    <Col>

                        <Space>
                            <Space.Compact>
                                <Select style={{width: 100}} value={selectType} onChange={setSelectType}>
                                    <Select.Option key={'-1'} value={'-1'}>全部类型</Select.Option>
                                    {getDict('menus').map((item) => <Select.Option key={item.value}
                                                                                   value={item.value}>{item.label}</Select.Option>)}
                                </Select>
                                <Button onClick={onType}>过滤</Button>
                            </Space.Compact>
                            <Input.Search
                                onSearch={onSearch}
                                placeholder="输入关键词搜索"
                                allowClear
                                enterButton="搜索"
                            />
                        </Space>

                    </Col>
                </Row>

                <div className='mt-4'>
                    <Table
                        loading={loading}
                        rowKey={(record) => record.id}
                        columns={columns}
                        dataSource={searchOpen ? search : treeMenus}
                        pagination={{
                            total: searchOpen ? search.length : treeMenus.length,
                            defaultCurrent: 1,
                            current: current,
                            showSizeChanger: true,
                            pageSize: 30,
                            showTotal: total => `总计 ${total} 个菜单`,
                            onChange: (page) => setCurrent(page)
                        }}/>
                </div>
            </div>
        </Card>
        <Create ref={createRef}/>
        <Edit ref={editRef}/>
    </>)
}
export default Menus;
