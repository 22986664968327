import React, {useState, forwardRef, useImperativeHandle} from 'react'
import {
    Form,
    Input,
    Radio,
    Spin,
    Modal,
    App, InputNumber,
} from "antd";

import {FileSelect} from "../../components";
import {useDict, useHttps, usePermission} from "../../hooks";
import {isNumber} from "../../utils/check";




const Edit = forwardRef((props: { onChange: (e: GoodsType.Data) => void; }, ref) => {
    const { message } = App.useApp();
    const [id, setId] = useState(0);
    const [open, setOpen] = useState(false);
    const {put} = useHttps();
    const [thumb, setThumb] = useState<StorageType.Data[]>()
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const {getDict} = useDict();

    const {isPermission} = usePermission();



    useImperativeHandle(ref, () => ({
        onOpen: (item: GoodsType.Data) => {
            setId(item.id)
            form.setFieldsValue(item)
            setThumb([{
                id: '-1',
                title: 'avatar.png',
                type: 1,
                mini_type: 'jpg',
                url: item.thumb || '',
                parent_id: '0',
                size: 100,
                created_at: new Date()
            }])
            setOpen(true)

        },
    }));

    /**
     * 更新文章内容
     */
    const onUpdate = async () => {
        try {
            let params = await form.validateFields();
            setLoading(true)
            put('admin/goods/' + id, params)
                .then(async (res: Request.Response<GoodsType.Data>) => {
                    if (res.mounted) {
                        setLoading(false)
                        props.onChange(res.data)
                        setOpen(false)
                    }

                })
                .catch(async (e: Request.Error) => {
                    if (e.mounted) {
                        setLoading(false)
                        message.error(e.message);
                    }
                });
        } catch (err) {
            return;
        }

    }


    /**
     * 关闭模态框
     */
    const onCancel = () => {
        setLoading(false)
        setOpen(false)
    }

    return (
        <Modal destroyOnClose title="编辑商品" width={960} okText='保存' open={open} onOk={onUpdate} centered
               footer={!isPermission('goods.post') ? null : undefined} onCancel={onCancel} okButtonProps={{disabled: loading}}>
            <Spin size="small" spinning={loading}>
                    <Form
                        disabled={!isPermission('goods.post')}
                        form={form}
                        labelCol={{span: 4}}
                        wrapperCol={{span: 18}}
                        name="basic"
                        onFinish={onUpdate}
                    >
                        <Form.Item
                            label="商品名称"
                            name="title"
                            rules={[{required: true, message: '请输入标题！'}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="封面图"
                            name='thumb'
                            extra="上传尺寸建议980x460"
                            className="mt-3"
                            rules={[{required: true, message: '请选择缩略图！'}]}
                        >
                            <div className='w-100'>
                                <FileSelect
                                    defaultFileList={thumb}
                                    listType='picture-card'
                                    text='上传封面'
                                    size={1}
                                    type={1}
                                    onChange={(e) => {
                                        form.setFieldsValue({thumb: e[0]?.url || ''})
                                    }}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="商品价格"
                            name="price"
                            rules={[{
                                required: true,
                                message: '请输入商品价格。！',
                                validator: async (rule, value) => {
                                    if (!isNumber(value)) {
                                        return Promise.reject()
                                    }
                                }
                            }
                            ]}
                        >
                            <Input prefix="￥" suffix="RMB" style={{ width: 200}} />
                        </Form.Item>

                        <Form.Item
                            label="库存"
                            name="total"
                            rules={[{required: true, message: '请输入商品库存！'}]}
                        >
                            <InputNumber min={1}  />
                        </Form.Item>
                        <Form.Item
                            label="核销地址"
                            name="address"
                            rules={[{required: true, message: '请输入核销地址！'}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item label="商品介绍" name="content"
                                   rules={[{
                                       required: true,
                                       message: '请输入20字符以上内容。！'
                                   }
                                   ]}
                        >
                            <Input.TextArea/>
                        </Form.Item>
                        <Form.Item name='description' label="核销规则">
                            <Input.TextArea/>
                        </Form.Item>
                        <Form.Item
                            name="status"
                            label="是否上架"
                        >
                            <Radio.Group>
                                {getDict('goods_status').map((item, index) => <Radio key={index}
                                                                                     value={item.value}>{item.label}</Radio>)}
                            </Radio.Group>
                        </Form.Item>

                    </Form>
            </Spin>
        </Modal>
    )
})
export default Edit;
