/**
 * Notes: 个人资料
 * User: 李强
 * DateTime: 2021/12/10 10:29
 * Ide: PhpStorm
 */

import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {
    Descriptions,
    message,
    Modal,
    Space,
    Skeleton,
    Avatar
} from 'antd';

import {Icon} from '../../config/Icon';
import {useDict, useHttps} from "../../hooks";
import {cityData} from "../../utils/city";
import dayjs from "dayjs";


/*
  用户管理
*/
const Account = forwardRef((props: { onChange?: (e: UserType.Data) => void; }, ref) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<UserType.Data>({
        area: 0,
        avatar: Icon.avatar,
        birth: new Date(),
        city: undefined,
        created_at: new Date(),
        device: "-",
        gender: '0',
        id: "-",
        mobile: "-",
        name: "",
        nickname: "-",
        platform: "-",
        status: '1',
        tt_openid: "-",
        wx_openid: "-",
        tags: []
    })
    const [loading, setLoading] = useState(false)
    const {get} = useHttps();

    const {getDictValue} = useDict();

    const getData = (id: number) => {
        setLoading(true)
        get('admin/users/' + id, null, true)
            .then((res: Request.Response<UserType.Data>) => {
                if (res.mounted) {
                    setData(res.data);
                    setLoading(false)
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    setOpen(false)
                    message.error(e.message)
                }
            });
    };


    useImperativeHandle(ref, () => ({
        onOpen: (id: number) => {
            getData(id)
            setOpen(true)

        },
    }));


    /**
     * 关闭模态框
     */
    const onCancel = () => {
        setLoading(false)
        setOpen(false)
    }

    return (
        <Modal destroyOnClose width={1100} open={open} footer={null} centered onCancel={onCancel}>

            {loading ?
                <div className='d-flex align-content-start'>
                    <div style={{width: 320}}>
                        <Skeleton.Input className='mb-4 d-block'/>
                        <Skeleton.Avatar size={110} shape='square' className='mb-4'/>
                        <Skeleton/>
                    </div>
                    <div className='px-5 flex-grow-1'>
                        <Skeleton.Input className='mb-4 d-block'/>
                        <Skeleton className='mb-4'/>
                        <Skeleton.Input className='mb-4 d-block'/>
                        <Skeleton paragraph={{rows: 6}}/>
                    </div>
                    <div className='px-5'>
                        <Skeleton.Input className='mb-4 d-block'/>
                    </div>
                </div>
                :


                <Space align='start'>
                    <div style={{width: 320}}>
                        <Descriptions title="基本信息" column={1} labelStyle={{
                            width: 80,
                            marginBottom: 10
                        }}>
                            <Descriptions.Item contentStyle={{marginBottom: 30}}> <Avatar shape='square'
                                                                                          className='mt-3' size={110}
                                                                                          src={data.avatar ? data.avatar : Icon.avatar}/></Descriptions.Item>
                            <Descriptions.Item label="用户ID">{data.id}</Descriptions.Item>
                            <Descriptions.Item label="用户昵称">{data.nickname}</Descriptions.Item>
                            <Descriptions.Item label="手机号码">{data.mobile}</Descriptions.Item>
                            <Descriptions.Item
                                label="性别"> {getDictValue('gender', data.gender)?.label || '未知'} </Descriptions.Item>
                            <Descriptions.Item
                                label="位置"> {data.city ? cityData[data.city.toString() as never] : "未知"}</Descriptions.Item>
                        </Descriptions>
                    </div>


                    <div className='border-start px-4'>
                        <Descriptions title="认证信息" column={1} labelStyle={{
                            width: 80,
                            marginBottom: 10
                        }} className='mb-4'>
                            <Descriptions.Item label="真实姓名">{data.card_id || '未认证'}</Descriptions.Item>
                            <Descriptions.Item label="证件号">{data.card_id || '未认证'}</Descriptions.Item>
                            <Descriptions.Item label="证件地址">{data.address || '未认证'}</Descriptions.Item>
                        </Descriptions>


                        <Descriptions title="其他信息" column={1} labelStyle={{
                            width: 100,
                            marginBottom: 10
                        }}>
                            <Descriptions.Item label="设备ID">{data.device ? data.device : '未知'}</Descriptions.Item>
                            <Descriptions.Item label="设备型号">未知</Descriptions.Item>
                            <Descriptions.Item
                                label="住址">{data.address ? data.address : '未知'}</Descriptions.Item>
                            <Descriptions.Item
                                label="注册日期">{dayjs(data.created_at).format('YYYY-MM-DD HH:mm')}</Descriptions.Item>
                        </Descriptions>

                    </div>
                </Space>
            }
        </Modal>

    )
})

export default Account

