/**
 * Created by devin on 2021/4/23.
 */

import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {App, Form, Input, Modal, Spin} from "antd";
import {useHttps} from "../../../hooks";
import {isCorrectFormatPassword} from "../../../utils/check";

const Password = forwardRef((props, ref) => {
    const { message } = App.useApp();
    const [id, setId] = useState(0);
    const {put} = useHttps();
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();


    useImperativeHandle(ref, () => ({
        onOpen: (id: number) => {
            setId(id)
            setOpen(true)
        },
    }));

    const onPassword = async () => {
        try {
            let params = await form.validateFields();
            setLoading(true)
            put('admin/manage/password/' + id, params)
                .then(async (res) => {
                    if (res.mounted) {
                        setLoading(false)
                        setOpen(false)
                    }
                })
                .catch(async (e: Request.Error) => {
                    if (e.mounted) {
                        setLoading(false)
                        message.error(e.message);
                    }
                });
        } catch (err) {
            return;
        }
    }


    const onCancel = () => {
        setLoading(false)
        setOpen(false)
    }

    return (
        <Modal destroyOnClose title="修改密码" okText='保存' open={open} onOk={onPassword} centered onCancel={onCancel}
               confirmLoading={loading}>
            <Spin size="small" spinning={loading}>

                    <Form
                        form={form}
                        name="basic_admin_password"
                        layout='vertical'
                    >
                        <Form.Item
                            label="修改登录密码"
                            name="password"
                            extra="最少6位，包括大小写字母，数字，特殊字符"
                            rules={[{
                                required: true, validator: async (rule, value) => {
                                    const isValue = isCorrectFormatPassword(value)
                                    if (!isValue) {
                                        await Promise.reject('请输入正确密码格式！')
                                    }
                                    await Promise.resolve()
                                }
                            }]}
                        >
                            <Input.Password placeholder="输入密码"/>
                        </Form.Item>
                    </Form>

            </Spin>
        </Modal>)
})
export default Password;
