/**
 * Created by devin on 2021/4/21.
 */

import React, {useCallback, useEffect, useRef, useState} from 'react'

import {
    App,
    Button,
    Card,
    Col,
    Divider,
    Input,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Typography
} from 'antd';
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    PlusOutlined
} from '@ant-design/icons';
import type {ColumnsType, TablePaginationConfig} from 'antd/es/table';
import {useDict, useHttps, usePermission} from "../../../hooks";
import Create from './Create'
import Edit from './Edit'
const {Text} = Typography;

interface NoticeParams {
    page?: number;
    per_page?: number;
    status?: string;
    search?: string;
    type?: string;

}

const Notice = () => {
    const { message, modal } = App.useApp();
    const {put, get, destroy} = useHttps();
    const [loading, setLoading] = useState(true)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [data, setData] = useState<Array<SystemType.Notice>>([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [selectType, setSelectType] = useState('-1')
    const [selectStatus, setSelectStatus] = useState('-1')
    const [search, setSearch] = useState('')
    const {isPermission} = usePermission();
    const {getDictValue, getDict} = useDict();
    const createRef = useRef<any>()
    const editRef = useRef<any>()
    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params: any) => {
        get('admin/notice', params, true)
            .then((res:Request.Response<SystemType.Notice[]>) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                    setSelectedRowKeys([])
                }

            })
            .catch(async (e:Request.Error) => {
                if (e.mounted) {
                    message.error(e.message)
                    setLoading(false);
                }
            });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get])


    useEffect(() => {
        getData({page: 1, per_page: 15});
    }, [getData]);


    /**
     * 自定义表格
     * @type {Object}
     */

    const columns: ColumnsType<SystemType.Notice> = [
        {
            title: '标题',
            dataIndex: 'title',
            render: (tags, item) => {
                return (
                 <Text onClick={() => editRef.current.onOpen(item.id)}>{tags}</Text>
                )
            }
        },
        {
            title: '类型',
            dataIndex: 'type',
            render: (type) => {
                const dict = getDictValue('notice' , type);
                return (
                    <Tag color={dict?.style}>{dict?.label}</Tag>
                )
            }
        },
        {
            title: '发布者',
            dataIndex: 'nickname',
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (tags) => {
                const dict = getDictValue('is_show' , tags);
                return (
                    <Tag color={dict?.style}>{dict?.label}</Tag>
                )
            }
        },
        {
            title: '发布日期',
            dataIndex: 'created_at',
        },
        {
            title: '操作',
            dataIndex: 'action',
            hidden: !isPermission('notice.post'),
            render: (key, item) => {
                return (
                    <div>
                        <Button onClick={() => editRef.current.onOpen(item.id)} className='me-2'
                                type="dashed" size='small' icon={<EditOutlined/>}/>
                        <Popconfirm title="确定删除?" onConfirm={() => onDelete([item.id])}>
                            <Button type="dashed" size='small' icon={<DeleteOutlined/>}/>
                        </Popconfirm>
                    </div>
                )
            }
        },
    ];

    /**
     * 删除列表操作
     * @param {Array} keys  数组id
     */
    const onDelete = (keys: React.Key[]) => {
        if (keys.length === 0) {
            return;
        }
        setLoading(true);
        destroy('admin/notice', keys)
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch(async (e:Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });

    };

    /**
     * 更新列表操作
     * @param status
     */

    const onUpdate = (status: number) => {
        setLoading(true);
        const dataKey = [...selectedRowKeys];
        put('admin/notice?status='+ status , dataKey)
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch(async (e:Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    };

    /**
     * 表格操作触发
     * @param pagination
     */
    const onPaginationChange = (pagination: TablePaginationConfig) => {

        setLoading(true);

        const params: NoticeParams = {
            page: pagination.current,
            per_page: pagination.pageSize,
        }
        if (selectStatus !== '-1') {
            params.status = selectStatus;
        }

        if (selectType !== '-1') {
            params.type = selectType;
        }

        if (search.length !== 0) {
            params.search = search;
        }

        getData(params);
    }


    /**
     * 操作提示信息
     */
    const showConfirm = () => {
        modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要删除选中项目吗？</div>,
            onOk() {
                onDelete(selectedRowKeys)
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }

    /**
     * 选择列表
     * @param {Array} selectedRowKeys
     */
    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys)
    };

    /**
     * 重置
     */
    const onReset = () => {
        setSelectStatus('-1')
        setSelectType('-1')
        setSearch('')
        setLoading(true)
        getData({page: 1, per_page: perPage});
    }


    /**
     * 添加通知
     * @param params
     */
    const onCreate = (params: SystemType.Notice) => {
        const dataSource = [...data]
        dataSource.unshift(params)
        setData(dataSource)
    }

    /**
     * 编辑公告
     * @param params
     */

    const onEdit = (params: SystemType.Notice) => {
        const dataSource = [...data]
        const index = dataSource.findIndex((item) => item.id === params.id)
        params.created_at = dataSource[index].created_at
        dataSource[index] = params;
        setData(dataSource)
    }


    /**
     * 返回视图
     */

    return (
        <Card>
            <div className='mb-4'>
                <Row justify='space-between'>
                    {isPermission('notice.post') && <Col>
                        <Button
                            disabled={!isPermission('notice.post')}
                            onClick={() => createRef.current.onOpen()}
                            type="primary"
                            icon={<PlusOutlined/>}
                        >
                            添加
                        </Button></Col>}
                    <Col>

                            <Space>
                                <Select style={{width: 200}} defaultValue={'-1'} value={selectType}
                                        onChange={setSelectType}>
                                    <Select.Option key={'-1'} value={'-1'}>全部类型</Select.Option>
                                    {getDict('notice').map((item, index) => <Select.Option key={index}    value={item.value}>{item.label}</Select.Option>)}
                                </Select>
                                <Select style={{width: 100}} defaultValue={'-1'} value={selectStatus}   onChange={setSelectStatus}>
                                    <Select.Option key={'-1'} value={'-1'}>全部状态</Select.Option>
                                    {getDict('is_show').map((item, index) => <Select.Option key={index}    value={item.value}>{item.label}</Select.Option>)}
                                </Select>

                                <Input.Search
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                    onSearch={() => {
                                        onPaginationChange({current: 1, pageSize: perPage})
                                    }}
                                    placeholder="输入关键词搜索"
                                    allowClear
                                    enterButton="搜索"
                                />

                                <Button onClick={onReset}>
                                    重置
                                </Button>
                            </Space>

                    </Col>
                </Row>

                <Divider/>
                 <div className='mt-4'>
                     {isPermission('notice.post') && <div className='mb-3'>
                        <Button onClick={() => onUpdate(0)} className='me-2' icon={<ArrowDownOutlined/>}
                                disabled={selectedRowKeys.length === 0}
                                size='small'>隐藏</Button>
                        <Button onClick={() => onUpdate(1)} className='me-2' icon={<ArrowUpOutlined/>}
                                disabled={selectedRowKeys.length === 0}
                                size='small'>显示</Button>
                        <Button onClick={showConfirm} className='me-2' icon={<DeleteOutlined/>}
                                disabled={selectedRowKeys.length === 0}
                                size='small'>删除</Button>
                        <span className='text-muted ms-3'>
                             {selectedRowKeys.length !== 0 ? `已选择 ${selectedRowKeys.length} 条通知` : ''}
                        </span>
                    </div>}

                    <Table
                        size="middle"
                        loading={loading}
                        rowSelection={isPermission('notice.post') ?{
                            selectedRowKeys,
                            onChange: onSelectChange,
                        }: undefined}
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            size: 'default',
                            total: total,
                            current: current,
                            showTotal: total => `总计 ${total} 篇通知`,
                            pageSize: perPage,
                        }}
                        onChange={onPaginationChange}
                        rowKey={(record) => {
                            return record.id
                        }}
                    />
                </div>
            </div>
            <Create ref={createRef} onChange={onCreate}/>
            <Edit ref={editRef} onChange={onEdit}/>
        </Card>
    )
}
export default Notice;
