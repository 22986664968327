import React, {forwardRef, useImperativeHandle, useState} from 'react'

import {Form, Input, Radio, Spin, Modal, App} from "antd";
import {FileSelect} from "../../components";
import {useDict, useHttps} from "../../hooks";

const Create = forwardRef((props: { onChange: (e: CourseType.Data) => void; }, ref) => {
    const { message } = App.useApp();
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const {post} = useHttps();
    const [form] = Form.useForm();
    const {getDict} = useDict();

    useImperativeHandle(ref, () => ({
        onOpen: () => {
            setOpen(true)
        },
    }));


    const onFinish = async () => {
        try {
            let params = await form.validateFields();
            setLoading(true)
        post('admin/course', params)
            .then(async (res:Request.Response<CourseType.Data>) => {
                if (res.mounted) {
                    setLoading(false)
                    props.onChange(res.data)
                    setOpen(false)
                }

            })
            .catch(async (e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
        } catch (err) {
            return;
        }
    }

    const onCancel = () => {
        setOpen(false)
    }


    return (
        <Modal destroyOnClose title="添加课程" width={960} okText='添加' open={open} onOk={onFinish} centered onCancel={onCancel} okButtonProps={{disabled: loading }} >
            <Spin size="small" spinning={loading}>

                        <Form
                            preserve={false}
                            form={form}
                            labelCol={{span: 4}}
                            wrapperCol={{span: 18}}
                            name="basic"
                            initialValues={{
                                status: '1',
                                title: "",
                                thumb: "",
                                description: '',
                                video: ''
                            }}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                label="课程标题"
                                name="title"
                                rules={[{required: true, message: '请输入标题！'}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="封面图"
                                name='thumb'
                                extra="上传尺寸建议980x460"
                                className="mt-3"
                                rules={[{required: true, message: '请选择缩略图！'}]}
                            >
                                <div className='w-100'>
                                    <FileSelect
                                        listType='picture-card'
                                        text='上传封面'
                                        size={1}
                                        type={1}
                                        onChange={(e) => {
                                            form.setFieldsValue({thumb: e[0]?.url || ''})
                                        }}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                                label="课程视频"
                                name='video'
                                extra="上传尺寸建议720x576"
                                className="mt-3"
                                rules={[{required: true, message: '请选择课程视频！'}]}
                            >
                                <div className='w-100'>
                                    <FileSelect
                                        listType='picture-card'
                                        text='上传视频'
                                        size={1}
                                        type={2}
                                        onChange={(e) => {
                                            form.setFieldsValue({video: e[0]?.url || ''})
                                        }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item name='description' label="课程介绍">
                                <Input.TextArea/>
                            </Form.Item>
                            <Form.Item
                                name="status"
                                label="是否显示"
                            >
                                <Radio.Group>
                                    {getDict('is_show').map((item, index) => <Radio key={index}
                                                                                    value={item.value}>{item.label}</Radio>)}
                                </Radio.Group>
                            </Form.Item>

                        </Form>
            </Spin>
        </Modal>
    )
})
export default Create;
