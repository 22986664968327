/**
 * Created by devin on 2021/4/21.
 */

import React, {useCallback, useEffect, useState} from 'react'

import {
    Button,
    Card, DatePicker,
    Input,
    Space,
    Table, Tag,
    App
} from 'antd';
import type {ColumnsType, TablePaginationConfig} from 'antd/es/table';

import {useDict, useHttps} from "../../../hooks";
import dayjs from "dayjs";


interface LogParams {
    page?: number;
    per_page?: number;
    start_date?: string;
    end_date?: string;
    type?: string;
    search?: string;

}

const Log = () => {
    const { message } = App.useApp();
    const {get} = useHttps();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<SystemType.Log[]>([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [search, setSearch] = useState('')
    const {getDictValue} = useDict();
    const [selectDate, setSelectDate] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>(null)
    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params: any) => {
        get('admin/login/logs', params, true)
            .then((res: Request.Response<SystemType.Log[]>) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message)
                }
            });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get])


    useEffect(() => {
        getData({type:'1' ,page: 1, per_page: 15});
    }, [getData]);



    /**
     * 表格操作触发
     * @param pagination
     */
    const onPaginationChange = (pagination: TablePaginationConfig) => {

        const params: LogParams = {
            page: pagination.current,
            per_page: pagination.pageSize,
            type:'1'
        }
        if (selectDate) {
            params.start_date = dayjs(selectDate[0]).format('YYYY-MM-DD');
            params.end_date = dayjs(selectDate[1]).format('YYYY-MM-DD');
        }
        //搜索
        if (search.length !== 0) {
            params.search = search;
        }
        setLoading(true);
        getData(params);
    }


    /**
     * 重置
     */
    const onReset = () => {
        setSelectDate(null)
        setSearch('')
        setLoading(true)
        getData({page: 1,type:1, per_page: perPage});
    }

    /**
     * 自定义表格
     * @type {Object}
     */

    const columns: ColumnsType<SystemType.Log> = [
        {
            title: '用户',
            dataIndex: 'nickname',
        },
        {
            title: '客户端',
            dataIndex: 'platform',
            render: (tags) => {
                const dict = getDictValue('platform' , tags);
                return (
                    <Tag color={dict?.style}>{dict?.label}</Tag>
                )
            }
        },
        {
            title: 'ip地址',
            dataIndex: 'ip',
        },
        {
            title: '登录日期',
            dataIndex: 'created_at',
        },
    ];

    return (
        <>
            <Card className='mb-3'>
                <Space>
                    <DatePicker.RangePicker value={selectDate} onChange={setSelectDate}/>
                    <Input.Search
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                        onSearch={() => {
                            onPaginationChange({current: 1, pageSize: perPage})
                        }}
                        placeholder="输入操作员姓名"
                        allowClear
                        enterButton="搜索"
                    />
                    <Button onClick={onReset}>
                        重置
                    </Button>
                </Space>

            </Card>

            <Table
                size="middle"
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={{
                    size: 'default',
                    total: total,
                    current: current,
                    showTotal: total => `总计 ${total} 条`,
                    pageSize: perPage,
                }}
                onChange={(pagination) => onPaginationChange(pagination)}
                rowKey={(record) => record.id}
            />
        </>
    )
}

export default Log
