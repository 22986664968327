/**
 * Created by devin on 2021/4/23.
 */

import React, {forwardRef,  useImperativeHandle, useState} from 'react'

import {
    Form,
    Input,
    Spin,
    Modal,
    TreeSelect,
    Row,
    Col, Select, App
} from "antd";
import {useDict, useHttps, usePermission} from "../../../hooks";
import {useRecoilValue} from "recoil";
import {permissionState, treeMenuState} from "../../../store";



const Edit = forwardRef((props: { onChange: (e: SystemType.Roles) => void; }, ref) => {
    const {message} = App.useApp();
    const [id, setId] = useState(0);
    const {put, get} = useHttps();
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false);
    const permission = useRecoilValue<SystemType.Permission[]>(permissionState);
    const [form] = Form.useForm()
    const {isPermission} = usePermission();
    const treeMenus = useRecoilValue<SystemType.Permission[]>(treeMenuState);
    const {getDict} = useDict();
    /**
     * 权限数据数据
     * @param id
     */
    const getData = (id: number) => {
        setLoading(true)
        get('admin/roles/' + id, null, true)
            .then((res: Request.Response<SystemType.Roles>) => {
                if (res.mounted) {
                    setLoading(false)
                    const permissionIds: number[] = res.data.permission?.map(item => item.id) || []
                    form.setFieldsValue({...res.data, ...{permission: permissionIds}})
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false)
                    setOpen(false)
                    message.error(e.message)
                }
            });
    };

    useImperativeHandle(ref, () => ({
        onOpen: (id: number) => {
            setId(id)
            setOpen(true)
            getData(id)
        },
    }));


    const onUpdate = async () => {
        try {
            let params = await form.validateFields();
            setLoading(true)
            params.permission = permission.filter((item) => params.permission?.includes(item.id))
            setLoading(true)
            put('admin/roles/' + id, params)
                .then(async (res: Request.Response<SystemType.Roles>) => {
                    if (res.mounted) {
                        setLoading(false)
                        props.onChange(res.data)
                        setOpen(false)
                    }
                })
                .catch(async (e: Request.Error) => {
                    if (e.mounted) {
                        setLoading(false)
                        message.error(e.message);
                    }
                });
        } catch (err) {
            return;
        }
    }


    const onCancel = () => {
        setLoading(false)
        setOpen(false)
    }


    return (
        <Modal destroyOnClose title="编辑角色" width={960} okText='保存' open={open} onOk={onUpdate} centered
               onCancel={onCancel} footer={!isPermission('roles.post') ? null : undefined} okButtonProps={{ disabled: loading}} >
            <Spin size="small" spinning={loading}>
                    <Form
                        disabled={!isPermission('roles.post')}
                        labelCol={{span: 6}}
                        wrapperCol={{span: 18}}
                        name="basic_roles_edit"
                        initialValues={{data_scope: '0'}}
                        onFinish={onUpdate}
                        form={form}
                    >
                        <Row gutter={32}>
                            <Col span={10}>

                                <Form.Item
                                    label="角色名称"
                                    name="name"
                                    rules={[{required: true, message: '请输入角色名称！'}]}
                                >
                                    <Input placeholder='输入角色名称'/>
                                </Form.Item>

                                <Form.Item
                                    label="角色标识"
                                    name="slug"
                                    rules={[{required: true, message: '请输入角色标识！'}]}
                                >
                                    <Input placeholder='输入角色标识'/>
                                </Form.Item>
                                <Form.Item
                                    label="角色级别"
                                    name="level"
                                    rules={[{required: true, message: '请输入角色级别！'}]}
                                >
                                    <Input placeholder='输入角色级别'/>
                                </Form.Item>

                                <Form.Item
                                    label="角色说明"
                                    name="description"
                                >
                                    <Input.TextArea rows={4} showCount placeholder="输入角色说明" maxLength={50}/>
                                </Form.Item>
                            </Col>
                            <Col span={14}>
                                <Form.Item
                                    label="数据授权"
                                    name="data_scope"
                                >
                                    <Select placeholder='选择数据授权'>
                                        {getDict('data_scope').map((item) => {
                                            return (
                                                <Select.Option key={item.value} value={item.value}
                                                               label={item.label}> {item.label}</Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="授权菜单"
                                    name="permission"
                                    rules={[{required: true, message: '请选择授权菜单！'}]}
                                >
                                    <TreeSelect
                                        multiple
                                        showSearch
                                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                        placeholder="选择菜单"
                                        allowClear
                                        fieldNames={{value: 'id', label: 'label', children: 'children'}}
                                        treeData={treeMenus}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
            </Spin>
        </Modal>
    )
})
export default Edit;
