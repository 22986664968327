import React, {forwardRef, useCallback,  useImperativeHandle, useState} from 'react'
import {App, Empty, List, Modal, Space, Spin, Typography} from "antd";
import {useHttps} from "../../hooks";
import {subText} from "../../utils/utils";
import file from '../../assets/icon/type-file.png';
import {RightOutlined} from "@ant-design/icons";

const {Text, Link} = Typography

const FileMove =forwardRef((props:  { onChange: (e: StorageType.Data, item: StorageType.Data[]) => void; }, ref) => {
    const { message } = App.useApp();
    const [open, setOpen] = useState(false);
    const {get} = useHttps();
    const [data, setData] = useState<StorageType.Data[]>([])
    const [loading, setLoading] = useState(true)
    const [refreshing, setRefreshing] = useState(false);
    const [current, setCurrent] = useState(1)
    const [hasMore, setHasMore] = useState(false)
    const [storageRouter, setStorageRouter] = useState<StorageType.Data[]>([{
        id: '0',
        title: '全部',
        url: '#',
        size: 0,
        type: 0,
        sort: 1,
        mini_type: 'file',
        parent_id: '0',
        filename: '#',
        created_at: new Date()
    }]);
    const perPage = 50;

    let dblclick: NodeJS.Timeout | null;

    /**
     * 获取数据
     */
    const getData = useCallback((params: any) => {
        params.per_page = perPage;
        params.type = 0;
        setRefreshing(true)
        get('admin/storage/query', params, true)
            .then((res: Request.Response<StorageType.Data[]>) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setHasMore(res.data.length < perPage)
                    setRefreshing(false)
                    if (params.page === 1) {
                        setData(res.data);
                    } else {
                        setData((r) => r.concat(res.data))
                    }
                }

            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    message.error(e.message)
                    setLoading(false);
                }
            });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get])


    useImperativeHandle(ref, () => ({
        onOpen: () => {
            getData({page: 1, parent_id: '0'})
            setOpen(true)
        },
        onCancel: () => {
            setOpen(false)
        },
    }));

    /**
     * 面包屑导航
     * @param params
     * @param index
     */
    const onRouterChange = (params: StorageType.Data, index: number) => {
        let dataSource = [...storageRouter];
        dataSource.splice(index + 1, dataSource.length)
        const parentId = dataSource[dataSource.length - 1];
        setStorageRouter(dataSource)
        setLoading(true)
        getData({page: 1, parent_id: parentId.id})
    }


    /**
     * 返回数据
     */
    const onOk = () => {
        const endData = storageRouter[storageRouter.length - 1];
        props.onChange(endData, storageRouter);

    }

    /**
     * 上拉加载数据
     */
    const onRefresh = useCallback((e: React.UIEvent<HTMLElement, UIEvent>) => {
        //如果数据正在请求中或无更多数据返回
        if (hasMore || refreshing) {
            return;
        }
        if (e.currentTarget.clientHeight + e.currentTarget.scrollTop >= e.currentTarget.scrollHeight) {
            const pageNum = current + 1
            const endData = storageRouter[storageRouter.length - 1];
            //判断是否选中文件类型
            getData({
                page: pageNum,
                parent_id: endData.id
            });
        }

    }, [current, getData, hasMore, refreshing, storageRouter])


    /**
     * 预览文件夹
     * @param params
     */

    const onPreview = (params: StorageType.Data) => {

        if (dblclick) {
            clearTimeout(dblclick);
        }
        //处理鼠标双击事件
        dblclick = setTimeout(() => {
            let dataSource = [...storageRouter];
            dataSource.push(params)
            setStorageRouter(dataSource)
            setLoading(true)
            getData({page: 1, parent_id: params.id})
        }, 300)
    }

    return (
        <Modal destroyOnClose title='选择文件夹' width={540} maskClosable={false} centered open={open}
               onCancel={()=>setOpen(false)} onOk={onOk}>
            <div className='my-4'>
                <Space>
                    {storageRouter.map((item: any, index: number) => {
                        const isRoot = storageRouter.length  === index + 1;
                        return (
                            <div key={index}> {isRoot ? <Text type='secondary'>{subText(item.title, 8)}</Text> : <Space align="center"><Link onClick={() => onRouterChange(item, index)}>{subText(item.title, 8)}</Link><RightOutlined className='mx-2' /></Space>}</div>
                        )
                    })}
                </Space>
            </div>
            <Spin size="small" spinning={loading}>
                <div style={{height: 400}} onScroll={onRefresh} className='overflow-y-scroll overflow-y-hidden'>
                    <List
                        locale={{
                            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='移动到这里'/>
                        }}
                        split
                        loadMore={!loading && !hasMore ?
                            <div className='text-center py-4'><Spin size="small"/></div> : null}
                        dataSource={data}
                        renderItem={(item, index) => {
                            return (
                                <List.Item className='pe-2 ps-0' key={index}>
                                   <Space className='cursor-pointer' onClick={() => onPreview(item)}>
                                        <img src={file} style={{width: 36, height: 36}} alt={'img'}/>
                                        <Text ellipsis>{item.title}</Text>
                                    </Space>
                                </List.Item>
                            )
                        }}
                        />

                </div>
            </Spin>
        </Modal>
    )
})

    export default FileMove;