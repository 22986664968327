import {Routes, Route} from "react-router-dom";
import  routers  from '../../routers/routers';
import Error404 from "../error/Error404";
const MainContent = () => {
    //请求初始化数据
  return (
      <Routes>
          {routers.map(
              (ele) => {
                      return (
                          <Route element={<ele.component/>} key={ele.path} path={ele.path} />
                      )
              })}
          <Route path="/404" element={<Error404 />} />
      </Routes>

  )
}

export default MainContent;
