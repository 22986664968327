import {Layout, Typography, ConfigProvider, theme, App} from 'antd';
import {Copyright, Version} from "../../config/Config";
import {useRecoilValue, useSetRecoilState} from 'recoil';


import TopHeader from './TopHeader'
import MainContent from './MainContent';
import TabMenu from './TabMenu';
import {algorithmState, dictTypeState} from '../../store'
import React, {useEffect} from "react";
import {useHttps, useElectron} from "../../hooks";
import zhCN from "antd/lib/locale/zh_CN";

const {Footer, Header, Content} = Layout;
const {Text} = Typography;


const Admin = () => {
    const algorithm = useRecoilValue(algorithmState);
    const setDictType = useSetRecoilState(dictTypeState)
    const {electron} = useElectron()
    const {get} = useHttps();


    /**
     * 初始化字典数据
     */
    useEffect(() => {
        get('admin/dict/type', null, true).then((res: Request.Response<SystemType.DictType>) => {
            setDictType(res.data)
            localStorage.setItem("dictType", JSON.stringify(res.data))
        }).catch(() => {

        });
    }, [get, setDictType])


    useEffect(() => {
        if (electron) {
            //electron鼠标右键
            window.addEventListener('contextmenu', (e: any) => {
                e.preventDefault()
                let selectText = window.getSelection()?.toString();
                if((e.target.tagName === 'SPAN' && !!selectText) || (e.target.tagName === 'DIV' && !!selectText)){
                    electron.ipcRenderer.send('show-context-menu',
                        [
                            {label: '复制', role: 'copy'},
                        ]
                    )
                    return
                }
                if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA' || !!selectText) {
                    electron.ipcRenderer.send('show-context-menu',
                        [
                            {label: '复制', role: 'copy'},
                            {label: '粘贴', role: 'paste'},
                            {label: '剪切', role: 'cut'},
                            {label: '全选', role: 'selectall'}
                        ]
                    )
                }
            })
        }
    }, [electron])


    return (
        <ConfigProvider locale={zhCN} theme={{
            algorithm: algorithm ? theme.darkAlgorithm : theme.defaultAlgorithm, token: { colorPrimary: '#5ca900' },
        }}>
            <App>
                <Layout style={{minHeight: '100vh'}}>
                    <Layout>
                        <div className='position-fixed  top-0 w-100 z-2'
                             style={{backgroundColor: algorithm ? '#141414' : '#fff'}}>
                            <Header className='position-relative'>
                                <TopHeader/>
                                {!algorithm && <div className='w-100 position-absolute start-0 bg-light'
                                                    style={{bottom: 0, height: 1, zIndex: 5}}/>}
                            </Header>
                            <div className='pt-2'><TabMenu/></div>

                        </div>

                        <Content className='mx-2' style={{paddingTop: 125}}>
                            <MainContent/>
                        </Content>
                        <Footer className='px-3 py-2'> <Text
                            type="secondary">{Copyright}-AI管理系统 {Version}</Text></Footer>
                    </Layout>
                </Layout>
            </App>
        </ConfigProvider>
    );
}
export default Admin;
