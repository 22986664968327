import Error404 from '../pages/error/Error404';
import Admin from '../pages/system/admin';
import Log from '../pages/system/log';
import LoginLog from '../pages/system/log/Login';
import Options from '../pages/system/options';
import Monitor from '../pages/system/monitor';
import Profile from '../pages/system/profile';
import Dict from '../pages/system/dict';
import Notice from '../pages/system/notice';
import Department from '../pages/system/department';
import Storage from "../pages/storage";
import Permission from "../pages/system/permission";
import Roles from "../pages/system/roles";
import Home from "../pages/home";
import User from "../pages/users";
import Archives from "../pages/archives";
import ArticleCategory from "../pages/article/category";
import Article from "../pages/article";
import Course from "../pages/course";
import Activity from "../pages/activity";
import Goods from "../pages/goods";
import OrderVerify from "../pages/goods/verify"

const routes = [
    {
        path: '/home',
        component: Home
    },
    {
        path: '/404',
        component: Error404
    },
    {
        path: '/storage',
        component: Storage
    },
    {
        path: '/system/admin',
        component: Admin,
    },

    {
        path: '/system/profile',
        component: Profile,
    },
    {
        path: '/system/dict',
        component: Dict,
    },
    {
        path: '/system/notice/all',
        component: Notice,
    },
    {
        path: '/system/department',
        component: Department,
    },
    {
        path: '/system/roles',
        component: Roles,
    },
    {
        path: '/system/permission',
        component: Permission,

    },
    {
        path: '/system/log',
        component: Log,
    },
    {
        path: '/login/logs',
        component: LoginLog,
    },

    {
        path: '/system/monitor',
        component: Monitor,
    },
    {
        path: '/options',
        component: Options,
    },
    {
        path: '/users',
        component: User,
    },
    {
        path: '/article/all',
        component: Article,
    },
    {
        path: '/article/category',
        component: ArticleCategory,
    },
    {
        path: '/archives',
        component: Archives,
    },

    {
        path: '/course',
        component: Course,
    },
    {
        path: '/activity',
        component: Activity,
    },
    {
        path: '/goods/all',
        component: Goods,
    },
    {
        path: '/order/verify',
        component:OrderVerify,
    },




]
export default routes
