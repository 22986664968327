import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {Form, Input, Radio, Spin, Modal, App} from "antd";
import {useDict, useHttps} from "../../../hooks";
import Editor from "../../../components/editor";


const Create = forwardRef((prop: { onChange: (e: SystemType.Notice) => void; }, ref) => {
    const { message } = App.useApp();
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const {post} = useHttps();
    const [form] = Form.useForm();
    const {getDict} = useDict();


    useImperativeHandle(ref, () => ({
        onOpen: () => {
            setOpen(true)
        },
    }));


    const onFinish = async () => {

        try {
            let params = await form.validateFields();
            setLoading(true)
            post('admin/notice', params)
                .then(async (res) => {
                    if (res.mounted) {
                        setLoading(false)
                        prop.onChange(res.data)
                        setOpen(false)
                    }

                })
                .catch(async (e) => {
                    if (e.mounted) {
                        setLoading(false)
                        message.error(e.message);
                    }
                });
        } catch (err) {
            return;
        }
    }

    const onCancel = () => {
        setOpen(false)
    }


    return (
        <Modal destroyOnClose title="添加通知" width={1100} okText='添加' open={open} onOk={onFinish} centered
               onCancel={onCancel} okButtonProps={{ disabled: loading }}>
            <Spin size="large" spinning={loading}>
                    <Form
                        preserve={false}
                        form={form}
                        labelCol={{span: 4}}
                        wrapperCol={{span: 16}}
                        name="basic_notice_create"
                        initialValues={{
                            status: '0',
                            content: "",
                            thumb: "",
                            link: '',
                            description: '',
                            type: '0'
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="标题"
                            name="title"
                            rules={[{required: true, message: '请输入标题！'}]}
                        >
                            <Input placeholder='请输入文章标题'/>
                        </Form.Item>
                        <Form.Item
                            name="type"
                            label="选择类型"
                        >
                            <Radio.Group>
                                {getDict('notice').map((item, index) => <Radio key={index}
                                                                               value={item.value}>{item.label}</Radio>)}
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="内容详情" name="content"
                                   rules={[{
                                       required: true,
                                       message: '请输入20字符以上内容。！',
                                       validator: async (rule, value) => {
                                           if (value.length <= 20) {
                                               return Promise.reject()
                                           }
                                       }
                                   }
                                   ]}
                        >
                            <Editor
                                height={280}
                                value={''}
                                onChange={(val) => {
                                    form.setFieldsValue({content: val})
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="status"
                            label="是否显示"
                        >
                            <Radio.Group>
                                {getDict('is_show').map((item, index) => <Radio key={index}  value={item.value}>{item.label}</Radio>)}
                            </Radio.Group>
                        </Form.Item>
                    </Form>
            </Spin>
        </Modal>
    )
})
export default Create;
