import React, {useState, forwardRef, useImperativeHandle} from 'react'
import {
    Modal,
    App, Table, Space, Button,  Tag,
} from "antd";

import {useDict, useHttps, usePermission} from "../../hooks";
import type {ColumnsType, TablePaginationConfig} from "antd/es/table";
import {CloseOutlined, CheckOutlined} from "@ant-design/icons";

interface ApplyParams {
    page?: number;
    per_page?: number;
    activity_id?: number;
}
const Edit = forwardRef((props, ref) => {
    const { message } = App.useApp();
    const [id, setId] = useState(0);
    const [open, setOpen] = useState(false);
    const {get, put} = useHttps();
    const [data, setData] = useState<ActivityType.Apply[]>([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(15)
    const [loading, setLoading] = useState(true)
    const {isPermission} = usePermission();
    const {getDictValue, getDict} = useDict();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])


    useImperativeHandle(ref, () => ({
        onOpen: (id: number) => {
            setId(id)
            setOpen(true)
            getData({page: 1, per_page: 15, activity_id:id})

        },
    }));

    /**
     * 更新文章内容
     */
    const getData = (params:ApplyParams) => {
        get('admin/activity/apply', params, true)
            .then((res:Request.Response<ActivityType.Apply[]>) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                    setSelectedRowKeys([])
                }

            })
            .catch(async (e) => {
                if (e.mounted) {
                    message.error(e.message)
                    setLoading(false);
                }
            });
    }


    /**
     * 关闭模态框
     */
    const onCancel = () => {
        setLoading(false)
        setOpen(false)
    }
    /**
     * 自定义表格
     * @type {Object}
     */

    const columns: ColumnsType<ActivityType.Apply> = [
        {
            title: '用户',
            dataIndex: 'nickname',
        },
        {
            title: '联系电话',
            dataIndex: 'mobile',
        },
        {
            title: '报名日期',
            dataIndex: 'created_at',
        },
        {
            title: '状态',
            dataIndex: 'status',
            render: (tags) => {
                const dict = getDictValue('activity_sign' , tags);
                return (
                    <Tag color={dict?.style}>{dict?.label}</Tag>
                )
            }
        },
    ];
    /**
     * 表格操作触发
     * @param pagination
     */
    const onPaginationChange = (pagination: TablePaginationConfig) => {
        setLoading(true);
        const params: ApplyParams = {
            page: pagination.current,
            per_page: pagination.pageSize,
            activity_id:id,
        }
        getData(params);
    }

    /**
     * 选择列表
     * @param {Array} selectedRowKeys
     */
    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys)
    };


    /**
     * 更新列表操作
     * @param status
     */

    const onUpdate = (status: string) => {
        setLoading(true);
        const dataKey = [...selectedRowKeys];
        put('admin/activity/apply?status='+ status, dataKey)
            .then((res) => {
                if (res.mounted) {
                    getData({page: current, per_page: perPage});
                }
            })
            .catch(async (e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    };


    return (
        <Modal destroyOnClose title="报名记录" width={920} open={open} centered footer={null} onCancel={onCancel}>
            {isPermission('activity.post') &&  <Space className='mb-3'>
                <Button onClick={() => onUpdate('0')}  icon={<CloseOutlined />}
                        disabled={selectedRowKeys.length === 0 }
                        size='small'>未签到</Button>
                <Button onClick={() => onUpdate('1')}  icon={<CheckOutlined />}
                        disabled={selectedRowKeys.length === 0 }
                        size='small'>已签到</Button>
                <span className='text-muted ms-3'>
                             {selectedRowKeys.length !== 0 ? `已选择 ${selectedRowKeys.length} 条记录` : ''}
                        </span>
            </Space>}
            <Table
                size="small"
                loading={loading}
                columns={columns}
                dataSource={data}
                rowSelection={isPermission('activity.post') ? {
                    selectedRowKeys,
                    onChange: onSelectChange,
                } : undefined}
                pagination={{
                    size: 'default',
                    total: total,
                    current: current,
                    showTotal: total => `总计 ${total} 条记录`,
                    pageSize: perPage,
                }}
                onChange={onPaginationChange}
                rowKey={(record) => {
                    return record.id
                }}
            />
        </Modal>
    )
})
export default Edit;
