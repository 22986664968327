/**
 * Created by devin on 2021/4/23.
 */

import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {App, Cascader, Col, Form, Input,Modal, Radio, Row, Select, Spin, TreeSelect} from "antd";
import {rolesState, treeDepartmentState} from "../../../store";
import {useRecoilValue} from "recoil";
import {FileSelect} from "../../../components";
import {useDict, useHttps, usePermission} from "../../../hooks";
import {isCorrectFormatUsername} from "../../../utils/check";
import {cityTreeData} from "../../../utils/city";

const Edit = forwardRef((props: { onChange: (e: SystemType.Data) => void; }, ref) => {
    const { message } = App.useApp();
    const [id, setId] = useState(0);
    const {put, get} = useHttps();
    const [avatar, setAvatar] = useState<Array<StorageType.Data>>([])
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false);
    const roles = useRecoilValue(rolesState)
    const {getDict} = useDict();
    const [form] = Form.useForm();
    const {isPermission} = usePermission();
    const treeDepartment = useRecoilValue<SystemType.Department[]>(treeDepartmentState);

    /**
     * 获取数据
     * @param id
     */

    const getData = (id: number) => {
        setLoading(true)
        get('admin/manage/' + id, null, true)
            .then((res: Request.Response<SystemType.Data>) => {
                if (res.mounted) {
                    if (res.data.avatar && res.data.avatar.length !== 0) {
                        setAvatar([{
                            id: '-1',
                            title: 'avatar.png',
                            type: 1,
                            mini_type: 'jpg',
                            url: res.data.avatar,
                            parent_id: '0',
                            size: 100,
                            created_at: new Date()
                        }])
                    }
                    res.data.roles = res.data.roles?.map((i) => i.id.toString()) || [] as any
                    form.setFieldsValue(res.data)
                    setLoading(false);
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    setOpen(false)
                    message.error(e.message)
                }

            });
    };


    useImperativeHandle(ref, () => ({
        onOpen: (id: number) => {
            setId(id)
            setOpen(true)
            getData(id)
        },
    }));


    /**
     * 更新员工内容
     */

    const onUpdate = async () => {
        try {
            let params = await form.validateFields();
            setLoading(true)
            params.roles = roles.filter((item) => params.roles?.includes(item.id))
            put('admin/manage/' + id, params)
                .then(async (res: Request.Response<SystemType.Data>) => {
                    if (res.mounted) {
                        setLoading(false)
                        props.onChange(res.data)
                        setOpen(false)
                    }
                })
                .catch(async (e: Request.Error) => {
                    if (e.mounted) {
                        setLoading(false)
                        message.error(e.message);
                    }
                });
        } catch (err) {
            return;
        }
    }

    /**
     * 关闭模态框
     */
    const onCancel = () => {
        setLoading(false)
        setOpen(false)
    }

    return (<Modal destroyOnClose title="编辑员工" width={920} okText='保存' footer={!isPermission('admin.post') ? null : undefined} open={open} onOk={onUpdate} centered
                   onCancel={onCancel} >
        <Spin size="small" spinning={loading}>

                <Form
                    disabled={!isPermission('admin.post')}
                    form={form}
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    name="basic_admin_edit"
                    initialValues={{
                        status: '0',
                        gender: '0',
                    }}
                >
                    <Row gutter={32}>
                        <Col span={10}>
                            <Form.Item

                                label="登录名"
                                name="username"
                                extra="登录名不可修改"
                                rules={[{
                                    required: true, validator: async (rule, value) => {
                                        const isValue = isCorrectFormatUsername(value) //isCorrectFormatUsername(value)
                                        if (!isValue) {
                                            await Promise.reject('请输入正确用户名！')
                                        }
                                        await Promise.resolve()
                                    }
                                }]}
                            >
                                <Input disabled placeholder='请输登陆名'/>
                            </Form.Item>

                            <Form.Item
                                label="姓名"
                                name="name"
                                rules={[{required: true, message: '请输入姓名！'}]}
                            >
                                <Input placeholder='输入真实姓名'/>
                            </Form.Item>
                            <Form.Item
                                label="性别"
                                name="gender"
                            >
                                <Radio.Group value={'0'}>
                                    {getDict('gender').map((item) => <Radio key={item.value}
                                                                            value={item.value}>{item.label}</Radio>)}
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="头像"
                                name='avatar'
                                extra="上传尺寸建议200x200"
                                className="mt-3"
                            >
                                <div className='w-100'>
                                    <FileSelect
                                        defaultFileList={avatar}
                                        listType='picture-card'
                                        text='上传头像'
                                        size={1}
                                        type={1}
                                        onChange={(e) => {
                                            form.setFieldsValue({avatar: e[0]?.url || ''})
                                        }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item
                                label="手机号"
                                name="mobile"
                                rules={[{required: true, message: '请输入手机号！'}]}
                            >
                                <Input placeholder='输入手机号码'/>
                            </Form.Item>
                            <Form.Item
                                label="身份证号码"
                                name="card_id"
                            >
                                <Input maxLength={20} placeholder='请填写身份证号码！'/>
                            </Form.Item>

                        </Col>
                        <Col span={14}>
                            <Form.Item
                                name="roles"
                                label="选择角色"
                                rules={[{required: true, message: '请指定员工角色！'}]}
                            >
                                <Select mode='multiple' placeholder="选择角色">
                                    {roles.map((tag) => {
                                        return (<Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>);
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="所属部门"
                                name="department_id"
                            >
                                <TreeSelect
                                    showSearch
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    placeholder="选择部门"
                                    allowClear
                                    fieldNames={{value: 'id', label: 'name', children: 'children'}}
                                    treeData={treeDepartment}
                                />
                            </Form.Item>
                            <Form.Item
                                label="职称"
                                name="position"
                                rules={[{required: true, message: '请输入职称！'}]}
                            >
                                <Input placeholder='输入职位或职称'/>
                            </Form.Item>
                            <Form.Item
                                label="所在城市"
                                name="city"
                            >
                                <Cascader
                                    options={cityTreeData}
                                />
                            </Form.Item>

                            <Form.Item
                                label="详细地址"
                                name="address"
                            >
                                <Input maxLength={50} placeholder="请输入精准到门牌号"/>
                            </Form.Item>
                            <Form.Item
                                label="企业邮箱"
                                name="email"
                            >
                                <Input type='email' maxLength={20} placeholder='请填写邮箱地址！'/>
                            </Form.Item>
                            <Form.Item name='description' label="个人介绍">
                                <Input.TextArea/>
                            </Form.Item>
                            <Form.Item
                                name="status"
                                label="职位状态"
                                extra="只有在职中和已休假的员工才可以登陆！"
                            >
                                <Radio.Group>
                                    {getDict('admin_status').map((item) => <Radio key={item.value}
                                                                                  value={item.value}>{item.label}</Radio>)}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
        </Spin>
    </Modal>)
})
export default Edit;
