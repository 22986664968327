/**
 * Created by devin on 2021/4/23.
 */

import React, {useEffect, useMemo, useRef, useState} from 'react'
import 'react-quill/dist/quill.snow.css';
import {File} from "../../components";
import ReactQuill from "react-quill";

interface EditorProps {
    onChange: (e: string) => void;
    disabled?:boolean;
    value?:string;
    height?:number;
}


const Editor = (props:EditorProps) => {
    const {onChange , disabled, value, height=360 } = props
    const [content, setContent] = useState('')
    const [type, setType] = useState(1)
    const selectRef = useRef<any>();
    const quillRef = useRef<any>();

    useEffect(() => {
        setContent(value || '')
        if(quillRef.current){
            quillRef.current.editor.enable(!disabled)
        }
    }, [disabled, value]);



    /**
     * 编辑器配置
     * @type {{toolbar: {container, handlers: {image: function(): void}}, clipboard: {matchVisual: boolean}}}
     */
    const module = useMemo(() => {
        return {
            toolbar: {
                container: !disabled ? [
                    [{size: []}],
                    [{color: []}, {background: []}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                    ['image', 'video'],
                    ['clean'],
                ] : [
                    [{size: []}],
                    [{color: []}, {background: []}],
                    ['clean'],
                ],
                handlers: {
                    image: () => {
                        setType(1)
                        selectRef.current.onOpen(true,1)
                    },
                    video: () => {
                        setType(2)
                        selectRef.current.onOpen(false ,2)
                    }
                }
            },
            clipboard: {
                matchVisual: false,
            }
        }
    }, [disabled]);




    /**
     * 附加图片处理
     * @param item
     */

    const imageInsert = (item:Array<StorageType.Data>) => {
        if(type === 1){
            let quill = quillRef.current.editor;//获取到编辑器本身
            const cursorPosition = quill.selection.savedRange.index;//获取当前光标位置
            //循环添加
            for (let i = 0; i < item.length; i++) {
                quill.insertEmbed(cursorPosition, "image", item[i].url, 'user');//插入图片
            }
            quill.setSelection(cursorPosition + 1); //光标位置加1
        }

        if(type === 2){
            let quill = quillRef.current.editor;//获取到编辑器本身
            const cursorPosition = quill.selection.savedRange.index//获取当前光标位置
            quill.insertEmbed(cursorPosition, "video", item[0].url, 'user');//插入视频
            quill.setSelection(cursorPosition + 1); //光标位置加1
        }
    }

    return (
        <>
            <ReactQuill
                ref={quillRef}
                style={{height: height, marginBottom: 50}}
                theme="snow"
                value={content}
                onChange={(e)=>{
                    setContent(e)
                    onChange(e)
                }}
                modules={module}
            />
            <File ref={selectRef} onChange={imageInsert}/>
        </>
    )

}

export default Editor;
