import logo from '../assets/logo.png';
import logoIcon from '../assets/logo-icon.png';
import logoFull from '../assets/logo-full.png'
import logoDark from '../assets/logo-dark.png'

const Version = 'v1.0.0';
const Copyright = '2023 大连青益联连青团';

const baseUrl = 'https://serve.dlqingtuan.com';
const Api = baseUrl + '/api/v1/';
const UploadApi = baseUrl + '/api/v1/admin/storage/upload';
const FileApi = baseUrl
const WebSocketApi = 'wss://serve.dlqingtuan.com/api/v1/web/socket';
const AMapKey = 'd92abef081d4acf3d2ec2140afcf8b4b';


const Logo = {
    full: logoFull,
    icon: logoIcon,
    black: logo,
    dark:logoDark
}

export {Version, Api, Copyright, Logo, AMapKey, WebSocketApi,FileApi, UploadApi};
