/**
 * Notes: video.js 播放器
 * User: 李强
 * DateTime: 2021/10/7 11:26 上午
 * Ide: PhpStorm
 */
import React, {useState, useRef,  forwardRef, useImperativeHandle} from "react";
import AMapLoader from '@amap/amap-jsapi-loader';
import {Modal, Row, Col, Input, Typography, message, Flex} from "antd";
import {Icon} from "../../config/Icon";
import {AMapKey} from "../../config/Config";

const {Text} = Typography

interface AMapProps {
    onChange: (e: { location: number[]; address: string; }) => void;
}


const AMap = forwardRef((props: AMapProps, ref) => {
    const {onChange} = props;
    const viewRef = useRef<any>(null);
    const mapRef = useRef<any>(null);
    const [location, setLocation] = useState([0, 0])
    const [address, setAddress] = useState('')
    const [open, setOpen] = useState(false)

    useImperativeHandle(ref, () => ({
        onOpen: () => {
            setOpen(true);
                if (mapRef.current) {
                        onClickMap()
                } else {
                    initMap()
                }
        },
    }));




    const initMap = () => {
        AMapLoader.load({
            key: AMapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
            version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins: ['AMap.PlaceSearch'], //插件列表
        }).then((AMap) => {
            const map = new AMap.Map(viewRef.current, {
                resizeEnable: true, //是否监控地图容器尺寸变化
                zoom: 14, //初始化地图层级
            });
            mapRef.current = {AMap, map}
            onClickMap()
        }).catch(async () => {
           message.error('高德网络错误，请稍后重试！')
        });
    }


    const onCancel = () => {
        onChange({address, location})
        setOpen(false)
    };


    const onSearch = (value: string) => {
        setAddress(value)
        const {AMap, map} = mapRef.current
        let placeSearch = new AMap.PlaceSearch({
            city: '全国',
            map: map
        })
        placeSearch.search(value);
    }

    const onClickMap = () => {
        const {AMap, map} = mapRef.current
        map.on('click', (e: any) => {
            const mapIcon = new AMap.Icon({
                // 图标尺寸
                size: new AMap.Size(30, 30),
                // 图标的取图地址
                image: Icon.map,
                // 图标所用图片大小
                imageSize: new AMap.Size(30, 30),
            });
            const marker = new AMap.Marker({
                icon: mapIcon,
                position: [e.lnglat.getLng(), e.lnglat.getLat()],
                offset: new AMap.Pixel(-13, -30)
            });
            marker.setMap(map);
            setLocation([e.lnglat.getLng(), e.lnglat.getLat()])
        });
    }



    return (
        <Modal title='位置选择' width='auto' centered maskClosable={true} open={open} afterClose={()=>setOpen(false)}
               onCancel={()=>setOpen(false)} onOk={onCancel}>
            <Row className='mb-4' gutter={16}>
                <Col span={12}>
                    <Flex align='center' gap='middle'>
                        <Text>地址搜索</Text>
                        <Input.Search onSearch={onSearch} placeholder="输入地址搜索" enterButton/>
                    </Flex>
                </Col>
                <Col span={12}>
                    <Flex align='center' gap='middle'>
                        <Text>经度|纬度</Text>
                        <Input value={location.join(',')} disabled/>
                    </Flex>
                </Col>
            </Row>
            <div style={{width: 800, height: 460}} ref={viewRef}></div>
        </Modal>
    );
})

export default AMap;
