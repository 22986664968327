/**
 * Created by devin on 2021/4/21.
 */

import React from 'react'

import {
    Card,
    Col,
    Row,
    Space,
    Button,
    Typography,
    Statistic,
    Table,
    DatePicker
} from 'antd';
import {
    UsergroupAddOutlined,
    UserAddOutlined,
    FileProtectOutlined,
    FileTextOutlined,
} from '@ant-design/icons';
import {Column, Pie} from '@ant-design/plots';
import {useRecoilValue} from "recoil";
import {useNavigate} from 'react-router-dom';
import {algorithmState, authState} from "../../store";
import dayjs from "dayjs";
import homeBg from '../../assets/hande-zusammen-team.jpg';


const {Title, Text, Link} = Typography;

const Home = () => {
    const user = useRecoilValue<AuthType.Info | null>(authState);
    const navigate = useNavigate();
    const algorithm = useRecoilValue(algorithmState);
    /**
     * 返回视图
     */
    return (
        <>
            <div className='d-flex justify-content-between align-items-center px-5 rounded position-relative'
                 style={{
                     height: 460,
                     backgroundRepeat: 'no-repeat',
                     backgroundSize: 'cover',
                     backgroundPosition: 'center',
                     backgroundImage: 'url(' + homeBg + ')'
                 }}
            >

                <div>
                    <Text
                        className='text-white'>今天是：{dayjs().format('YYYY年MM月DD日')} {dayjs().format('ddd')}</Text>
                    <Title className='text-white' level={2}>您好，{user?.name}，祝你开心每一天！</Title>
                    <Text
                        className='text-white fs-5'>{user?.description ? user?.description : '信心毅力勇气三者具备，则天下没有做不成的事。'}</Text>
                    <div className='mt-5'>
                        <Space>
                            <Button type="dashed" ghost shape="round">
                                帮助中心
                            </Button>
                            <Button type="dashed" shape="round" ghost onClick={() => navigate('/system/profile')}>
                                个人资料
                            </Button>
                        </Space>

                    </div>
                </div>

            </div>
            <Card style={{marginTop: -15}} className='mb-3'>
                <Row justify="space-between">
                    <Col className="text-center">
                        <Statistic title="今日订单" value={0} prefix={<FileProtectOutlined/>}/>
                    </Col>
                    <Col className="text-center">
                        <Statistic title="今日会员" value={0} prefix={<UserAddOutlined/>}/>
                    </Col>
                    <Col className="text-center">
                        <Statistic title="订单总数" value={0} prefix={<FileTextOutlined/>}/>
                    </Col>
                    <Col className="text-center">
                        <Statistic title="会员总数" value={0} prefix={<UsergroupAddOutlined/>}/>
                    </Col>

                </Row>
            </Card>
            <Row gutter={16} className='mb-3'>
                <Col span={18}>
                    <Card title='年数据统计' extra={
                        <Space size='large'>
                            <Link>会员</Link>
                            <Link>用户</Link>
                            <Link>金额</Link>
                            <DatePicker picker="year"/>
                        </Space>
                    }>

                        <Column
                            data={[
                                {
                                    type: '家具家电',
                                    value: 0.38,
                                },
                                {
                                    type: '粮油副食',
                                    value: 0.52,
                                },
                                {
                                    type: '生鲜水果',
                                    value: 0.61,
                                },
                                {
                                    type: '美容洗护',
                                    value: 0.145,
                                },
                                {
                                    type: '母婴用品',
                                    value: 0.48,
                                },
                                {
                                    type: '进口食品',
                                    value: 0.38,
                                },
                                {
                                    type: '食品饮料',
                                    value: 0.38,
                                },
                                {
                                    type: '家庭清洁',
                                    value: 0.38,
                                },
                            ]}
                            axis={
                                {

                                    x: {
                                        gridStrokeOpacity: 0.2,
                                        tickStroke: "#3ecdf8",
                                        gridStroke: algorithm ? "#fff" : "#000",
                                        labelFill: algorithm ? "#ccc" : "#000",
                                    },
                                    y: {
                                        gridStrokeOpacity: 0.2,
                                        gridStroke: algorithm ? "#fff" : "#000",
                                        tickStroke: "#3ecdf8",
                                        labelFill: algorithm ? "#ccc" : "#000",
                                    },
                                }
                            }
                            xField='type'
                            yField='value'
                            colorField='#3ecdf8'
                            style={{
                                maxWidth: 100,
                            }}
                        />

                    </Card>
                </Col>
                <Col span={6}>
                    <Card style={{height: 610}} title='性别统计'>
                        <Pie

                            appendPadding={10}
                            data={[
                                {
                                    type: '男性',
                                    value: 0.3,
                                },
                                {
                                    type: '女性',
                                    value: 0.7,
                                },
                            ]}
                            angleField='value'
                            colorField='type'
                            legend={{
                                color: {
                                    itemLabelFill: algorithm ? "#ccc" : "#000",
                                },
                            }}
                            label={{
                                text: 'value',
                                labelFill: "#fff",
                                style: {
                                    fontWeight: 'bold',
                                },
                                formatter: '.0%'
                            }
                            }
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={16} className='mb-4'>
                <Col span={12}>
                    <Card title="最新加入会员">
                        <Table columns={[
                            {
                                title: '会员名',
                                dataIndex: 'nickname',
                            },
                            {
                                title: '手机号',
                                dataIndex: 'mobile',
                            },
                            {
                                title: '设备ID',
                                dataIndex: 'title',
                            },
                            {
                                title: '加入日期',
                                dataIndex: 'created_at',
                            },
                        ]} pagination={false} dataSource={[]} size={'middle'}
                        />
                    </Card>
                </Col>

                <Col span={12}>
                    <Card title="最新申请">
                        <Table columns={[
                            {
                                title: '姓名',
                                dataIndex: 'nickname',
                            },
                            {
                                title: '电话',
                                dataIndex: 'mobile',
                            },
                            {
                                title: '手机号',
                                dataIndex: 'title',
                            },
                            {
                                title: '申请日期',
                                dataIndex: 'created_at',
                            },
                        ]} pagination={false} dataSource={[]} size={'middle'}
                        />
                    </Card>
                </Col>
            </Row>
        </>
    )
}
export default Home;
