/**
 * Created by devin on 2021/4/21.
 */

import React, {useEffect, useRef, useState} from 'react'

import {useRecoilState, useRecoilValue} from 'recoil';

import {App, Button, Card, Col, Input, Popconfirm, Row, Space, Table} from 'antd';
import {DeleteOutlined, EditOutlined, PlusOutlined,} from '@ant-design/icons';
import type {ColumnsType} from 'antd/es/table';
import {useHttps, usePermission} from "../../../hooks";
import {departmentState , treeDepartmentState} from "../../../store";
import Create from "./Create";
import Edit from "./Edit";

const Department = () => {
    const { message } = App.useApp();
    const {destroy, get} = useHttps();
    const [loading, setLoading] = useState(true)
    const [department, setDepartment] = useRecoilState<SystemType.Department[]>(departmentState);
    const treeDepartment = useRecoilValue<SystemType.Department[]>(treeDepartmentState);
    const [searchOpen, setSearchOpen] = useState(false)
    const [current, setCurrent] = useState(1)
    const [search, setSearch] = useState<SystemType.Department[]>([])
    const createRef = useRef<any>(null);
    const editRef = useRef<any>(null);
    const {isPermission} = usePermission();


    useEffect(() => {
        get('admin/department', null, true)
            .then((res:Request.Response<SystemType.Department[]>) => {
                if (res.mounted) {
                    setLoading(false)
                    setDepartment(res.data);
                }
            })
            .catch(async (e:Request.Error) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message)
                }
            });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get, setDepartment]);



    /**
     * 自定义表格
     * @type {Object}
     */

    const columns: ColumnsType<SystemType.Department> = [{
        title: '部门名称', dataIndex: 'name'
    }, {
        title: '排序', dataIndex: 'sort', width: 120,
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.sort -b.sort,
    }, {
        title: '联系人', dataIndex: 'linkman',
    }, {
        title: '联系电话', dataIndex: 'tel',
    }, {
        title: '邮箱', dataIndex: 'email',
    },{
        title: '操作', hidden:!isPermission('department.post'), dataIndex: 'action', render: (key, item) => {
            return (<Space>
                <Button    onClick={() => editRef.current.onOpen(item.id)}
                        type="dashed" size='small' icon={<EditOutlined/>}/>
                <Popconfirm  title="确定要删除部门及员工?"
                            onConfirm={() => onDelete(item.id)}>
                    <Button type="dashed"
                            size='small' icon={<DeleteOutlined/>}/>
                </Popconfirm>
            </Space>)
        }
    },];

    /**
     * 删除列表操作
     * @param {number} id  数组id
     */
    const onDelete = (id: number) => {
        setLoading(true);
        destroy('admin/department/' + id)
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    let dataSource = [...department]
                    const select = dataSource.findIndex((value) => value.id === id);
                    dataSource.splice(select, 1);
                    setDepartment(dataSource)
                }
            })
            .catch(async (e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });

    };


    /**
     * 搜索
     * @param e
     */
    const onSearch = (e: string) => {
        setCurrent(1)
        if (e.length === 0) {
            setSearch([])
            setSearchOpen(false)
            return
        }
        const searchData: SystemType.Department[] = department.filter((el) => el.name.toLowerCase().indexOf(e.toLowerCase()) > -1)
        setSearchOpen(true)
        setSearch(searchData)
    }



    /**
     * 返回视图
     */

    return (<>
        <Card>
             <div className='mb-4'>
                 {isPermission('department.post') &&   <Row justify='space-between'>
                   <Col>
                        <Button
                            onClick={() => createRef.current.onOpen()}
                            type="primary"
                            icon={<PlusOutlined/>}
                        >
                            添加部门
                        </Button>

                    </Col>
                    <Col>

                                <Input.Search
                                    onSearch={onSearch}
                                    placeholder="输入关键词搜索"
                                    allowClear
                                    enterButton="搜索"
                                />
                    </Col>
                </Row>}

                <div className='mt-4'>
                    <Table
                        loading={loading}
                        rowKey={(record) => record.id}
                        columns={columns}
                        dataSource={searchOpen ? search : treeDepartment}
                        pagination={{
                            total: searchOpen ? search.length : treeDepartment.length,
                            defaultCurrent: 1,
                            current: current,
                            showSizeChanger: true,
                            pageSize: 30,
                            showTotal: total => `总计 ${total} 个菜单`,
                            onChange: (page) => setCurrent(page)
                        }}/>
                </div>
            </div>
        </Card>
        <Create ref={createRef}/>
        <Edit ref={editRef}/>
    </>)
}
export default Department;
