export function randomNumInteger(min: number, max: number) {
    switch (arguments.length) {
        case 1:
            return parseInt(String(Math.random() * min + 1), 10);
        case 2:
            return parseInt(String(Math.random() * (max - min + 1) + min), 10);
        default:
            return 0
    }
}


/**
 * B转换到KB,MB,GB并保留两位小数
 * @param { number } fileSize
 */
export const formatFileSize = (fileSize: number) => {
    let temp;
    if (fileSize < 1024) {
        return fileSize + 'B';
    } else if (fileSize < (1024 * 1024)) {
        temp = fileSize / 1024;
        temp = temp.toFixed(2);
        return temp + 'KB';
    } else if (fileSize < (1024 * 1024 * 1024)) {
        temp = fileSize / (1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'MB';
    } else {
        temp = fileSize / (1024 * 1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'GB';
    }
}
/**
 *  截取字符串并加身略号
 */
export const subText = (str: string, length: number) => {
    if (str.length === 0) {
        return '';
    }
    if (str.length > length) {
        return str.substring(0, length) + "...";
    } else {
        return str;
    }
}


/**
 * 生成唯一字符串Id
 * @param length
 */
export const genGUID = (length: number) => {
    return Number(Math.random().toString().substring(3, length) + Date.now()).toString(36);
}


/**
 * 递归生成树形结构
 */
export const getTreeData = (data: Array<any>, pid: number | string, pidName = 'parent_id', idName = 'id', childrenName = 'children') => {
    let arr = [];
    for (let i = 0; i < data.length; i++) {
        if (data[i][pidName] === pid) {
            const treeData = getTreeData(data, data[i][idName], pidName, idName, childrenName);
            let children: { [p: string]: any[] | undefined } = {
                [childrenName]: treeData.length !== 0 ? treeData : undefined
            };
            arr.push({...data[i], ...children});
        }
    }
    return arr;
}


/**
 * 遍历树节点
 */
export const foreachTree = (data: Array<any>, childrenName = 'children', callback: (arrayElement: any) => void) => {
    for (let i = 0; i < data.length; i++) {
        callback(data[i]);
        if (data[i][childrenName] && data[i][childrenName].length > 0) {
            foreachTree(data[i][childrenName], childrenName, callback);
        }
    }
}


/**
 * 追溯父节点
 */
export const traceParentNode = (pid: number | string, data: Array<any>, rootPid: number | string, pidName = 'parent_id', idName = 'id', childrenName = 'children') => {
    let arr: Array<any> = [];
    foreachTree(data, childrenName, (node) => {
        if (node[idName] === pid) {
            arr.push(node);
            if (node[pidName] !== rootPid) {
                arr = arr.concat(traceParentNode(node[pidName], data, rootPid, pidName, idName));
            }
        }
    });
    return arr;
}


/**
 * 寻找所有子节点
 */
export const traceChildNode = (id: string | number | bigint, data: Array<any>, pidName = 'parent_id', idName = 'id', childrenName = 'children') => {
    let arr: Array<any> = [];
    foreachTree(data, childrenName, (node) => {
        if (node[pidName] === id) {
            arr.push(node);
            arr = arr.concat(traceChildNode(node[idName], data, pidName, idName, childrenName));
        }
    });
    return arr;
}


/**
 * 根据ID寻找子节点
 */
export const traceChildIdNode = (id: string | number, data: Array<any>, idName = 'id', childrenName = 'children') => {
    let item: any = null
    foreachTree(data, childrenName, (node) => {
        if (node[idName] === id) {
            item = node;
        }
    });
    return item;
}

/**
 * 搜索子节点
 */
export const traceSearchNode = (value: string, data: Array<any>, searchData: Array<any> = [], searchName = 'label', childrenName = 'children') => {
    foreachTree(data, childrenName, (node) => {
        const search: boolean = node[searchName].toLowerCase().indexOf(value.toLowerCase()) > -1
        if (search) {
            searchData.push({...node, ...{children: null}});
        }
    });
    return searchData;
}


/**
 * 根据pid生成树形结构
 *  @param { object } items 后台获取的数据
 *  @param { * } id 数据中的id
 *  @param { * } link 生成树形结构的依据
 */
export const createTree = (items: any[], id: string | number | null, link = 'pid') => {
    items.filter(item => item[link] === id).map(item => ({...item, children: createTree(items, item.id)}));
};


/**
 * 获取url参数
 * @param {*} name
 * @param {*} origin
 */

export const getUrlParam = (name: string, origin: any = null) => {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    let r;
    if (origin == null) {
        r = window.location.search.substring(1).match(reg);
    } else {
        //@ts-ignore
        r = origin.substring(1).match(reg);
    }
    if (r != null) return decodeURIComponent(r[2]);
    return null;
}


/**
 * 隐藏手机号中间四位
 * @param {*} phone
 * @returns
 */
export const hideMPStrict = (phone: string) => {
    const isPhone = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/g.test(phone)
    if (isPhone) {
        let reg = /^(\d{3})\d{4}(\d{4})$/;
        return phone.replace(reg, '$1****$2');
    }
    return '';
}




/**
 * BASE64 转文件
 * @param base64
 * @param type
 */
export const base64ToFile = (base64: string, type: string) => {
    let binary = atob(base64.split(',')[1]);
    let array = [];
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: type});
}


/**
 * url文件转base64
 * @param url
 */
export const urlToBase64 = (url: string) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
            const reader = new FileReader();
            reader.onloadend = function () {
                if (reader.result && typeof reader.result === 'string') {
                    const data = reader.result.replace(/^data:.+;base64,/, '')
                    return resolve(data)
                }
                return reject("载入文件错误");

            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.send();
    })
}

/**
 * 格式化时间为几天前
 * @param updateTime
 */
export const getUpdateTime =  (updateTime: number)  => {
    if (updateTime === null) {
        return ''
    }
    let now = new Date().getTime()
    let second = Math.floor((now - updateTime) / (1000))
    let minute = Math.floor(second / 60)
    let hour = Math.floor(minute / 60)
    let day = Math.floor(hour / 24)
    let month = Math.floor(day / 31)
    let year = Math.floor(month / 12)
    if (year > 0) {
        return year + '年前'
    } else if (month > 0) {
        return month + '月前'
    } else if (day > 0) {
        let ret = day + '天前'
        if (day >= 7 && day < 14) {
            ret = '1周前'
        } else if (day >= 14 && day < 21) {
            ret = '2周前'
        } else if (day >= 21 && day < 28) {
            ret = '3周前'
        } else if (day >= 28 && day < 31) {
            ret = '4周前'
        }
        return ret
    } else if (hour > 0) {
        return hour + '小时前'
    } else if (minute > 0) {
        return minute + '分钟前'
    } else if (second > 0) {
        return second + '秒前'
    } else {
        return '刚刚'
    }
}
