import React, {useState, forwardRef, useImperativeHandle} from 'react'
import {
    Form,
    Input,
    Radio,
    Spin,
    Modal,
    App, InputNumber,DatePicker
} from "antd";

import {FileSelect} from "../../components";
import {useDict, useHttps, usePermission} from "../../hooks";
import dayjs from "dayjs";

const Edit = forwardRef((props: { onChange: (e: ActivityType.Data) => void; }, ref) => {
    const { message } = App.useApp();
    const [id, setId] = useState(0);
    const [open, setOpen] = useState(false);
    const {put} = useHttps();
    const [thumb, setThumb] = useState<StorageType.Data[]>()
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const {getDict} = useDict();
    const {isPermission} = usePermission();


    useImperativeHandle(ref, () => ({
        onOpen: (item: ActivityType.Data) => {
            setId(item.id)
            setThumb([{
                id: '-1',
                title: 'item.png',
                type: 1,
                mini_type: 'jpg',
                url: item.thumb || '',
                parent_id: '0',
                size: 100,
                created_at: new Date()
            }])
            const defaultValue = [dayjs(item.start_date), dayjs(item.end_date)];
            form.setFieldsValue({...item, ...{date:defaultValue}})
            setOpen(true)

        },
    }));

    /**
     * 更新文章内容
     */
    const onUpdate = async () => {
        try {
            let params = await form.validateFields();
            params.start_date = dayjs(params.date[0]).format('YYYY-MM-DD HH:mm:ss');
            params.end_date = dayjs(params.date[1]).format('YYYY-MM-DD HH:mm:ss');
            setLoading(true)
            put('admin/activity/' + id, params)
                .then(async (res: Request.Response<ActivityType.Data>) => {
                    if (res.mounted) {
                        setLoading(false)
                        props.onChange(res.data)
                        setOpen(false)
                    }

                })
                .catch(async (e: Request.Error) => {
                    if (e.mounted) {
                        setLoading(false)
                        message.error(e.message);
                    }
                });
        } catch (err) {
            return;
        }

    }


    /**
     * 关闭模态框
     */
    const onCancel = () => {
        setLoading(false)
        setOpen(false)
    }

    return (
        <Modal destroyOnClose title="编辑活动" width={960} okText='保存' open={open} onOk={onUpdate} centered
               footer={!isPermission('course.post') ? null : undefined} onCancel={onCancel} okButtonProps={{disabled: loading}}>
            <Spin size="small" spinning={loading}>
                    <Form
                        disabled={!isPermission('course.post')}
                        form={form}
                        labelCol={{span: 4}}
                        wrapperCol={{span: 18}}
                        name="basic"
                        onFinish={onUpdate}
                    >
                        <Form.Item
                            label="活动标题"
                            name="title"
                            rules={[{required: true, message: '请输入标题！'}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="封面图"
                            name='thumb'
                            extra="上传尺寸建议980x460"
                            className="mt-3"
                            rules={[{required: true, message: '请选择缩略图！'}]}
                        >
                            <div className='w-100'>
                                <FileSelect
                                    defaultFileList={thumb}
                                    listType='picture-card'
                                    text='上传封面'
                                    size={1}
                                    type={1}
                                    onChange={(e) => {
                                        form.setFieldsValue({thumb: e[0]?.url || ''})
                                    }}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="活动地点"
                            name="address"
                            rules={[{required: true, message: '请输入活动地点！'}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="报名人数"
                            name="total"
                            rules={[{required: true, message: '请输入报名人数！'}]}
                        >
                            <InputNumber min={1}  />
                        </Form.Item>

                        <Form.Item
                            label="活动日期"
                            name="date"
                            rules={[{required: true, message: '请输入活动日期！'}]}
                        >
                            <DatePicker.RangePicker showTime />
                        </Form.Item>
                        <Form.Item name='content' label="活动介绍">
                            <Input.TextArea/>
                        </Form.Item>

                        <Form.Item name='description' label="报名规则">
                            <Input.TextArea/>
                        </Form.Item>
                        <Form.Item
                            name="status"
                            label="是否显示"
                        >
                            <Radio.Group>
                                {getDict('is_show').map((item, index) => <Radio key={index}
                                                                                value={item.value}>{item.label}</Radio>)}
                            </Radio.Group>
                        </Form.Item>
                    </Form>
            </Spin>
        </Modal>
    )
})
export default Edit;
