/**
 * Notes: 管理员权限
 * User: 李强
 * DateTime: 2021/12/8 15:47
 * Ide: PhpStorm
 */


import React, {useEffect, useState} from 'react'
import {
    Card,
    Row,
    Col,
    Space,
    Button,
    Table,
    Modal,
    Spin,
    Form,
    Input,
    InputNumber,
    App
} from "antd";
import {ColumnsType} from "antd/es/table";
import {useHttps} from "../../../hooks";
import {isWebEnglish} from "../../../utils/check";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import DictTypeEdit from './type'


const Dict = () => {
    const { message, modal } = App.useApp();
    const [loading, setLoading] = useState(true)
    const [spin, setSpin] = useState(false)
    const [data, setData] = useState<SystemType.Dict[]>([])
    const [open, setOpen] = useState(false)
    const [selectId, setSelectId] = useState(0)
    const [selectType, setSelectType] = useState('')
    const [form] = Form.useForm()
    const {post, get, destroy} = useHttps();

    /**
     * 表格
     */
    const columns: ColumnsType<SystemType.Dict> = [
        {
            title: '名称',
            dataIndex: 'name',
        },
        {
            title: '类型',
            dataIndex: 'type',
        },
    ]


    /**
     * 初始化数据
     */

    useEffect(() => {
        get('admin/dict', null, true)
            .then((res: Request.Response<SystemType.Dict[]>) => {
                if (res.mounted) {
                    setLoading(false)
                    setData(res.data)
                    if (res.data.length !== 0){
                        setSelectId(res.data[0].id)
                        setSelectType(res.data[0].type)
                    }

                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message)
                }
            });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get]);


    /**
     * 选择字典
     * @param selectedRowKeys
     * @param selectedRows
     */

    const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: SystemType.Dict[]) => {
        setSelectId(selectedRowKeys[0] as never)
        setSelectType(selectedRows[0].type)
    }

    /**
     * 删除字典
     */

    const onDelete = () => {
        if (selectId === 0) return
        setLoading(true)
        destroy('admin/dict/' + selectId)
            .then(async (res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('删除字典成功！');
                    const dataSource = [...data];
                    const select = dataSource.findIndex((value) => value.id === selectId);
                    dataSource.splice(select, 1);
                    setSelectId(dataSource[0] ? dataSource[0].id : 0)
                    setSelectType(dataSource[0] ? dataSource[0].type : '')
                    setData(dataSource)
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    }

    /**
     * 添加字典
     */
    const onFinish = async () => {
        try {
            let values = await form.validateFields();
            setSpin(true)
            values.parent_id = values.parent_id ? values.parent_id : 0
            post('admin/dict', values)
                .then((res:Request.Response<SystemType.Dict>) => {
                    if (res.mounted) {
                        setSpin(false)
                        message.success('字典添加成功！');
                        const dataSource = [...data];
                        dataSource.unshift(res.data);
                        setOpen(false)
                        setSelectId(res.data.id)
                        setSelectType(res.data.type)
                        setData(dataSource)
                        form.setFieldsValue({name: '', type: '', sort: 0})

                    }
                })
                .catch(async (e) => {
                    if (e.mounted) {
                        setSpin(false)
                        message.error(e.message);
                    }
                });
        } catch (err) {
            return;
        }
    }

    /**
     * 操作提示信息
     */
    const showConfirm = () => {
        modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要删除此字典吗？</div>,
            onOk() {
                onDelete()
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }



    return (
        <Spin spinning={loading}>
            <Row gutter={16}>
                <Col span={9}>
                    <Card>
                        <div className='mb-4'>
                            <Space>
                                <Button  type="primary"  onClick={() => setOpen(true)}>
                                    添加
                                </Button>
                                <Button disabled={selectId === 0} danger onClick={showConfirm}>
                                    删除
                                </Button>
                            </Space>
                        </div>

                        <Table
                            rowKey={(record) => record.id}
                            columns={columns}
                            dataSource={data}
                            rowSelection={{
                                type: 'radio',
                                onChange: onSelectChange,
                                selectedRowKeys:[selectId],
                            }}
                            pagination={{
                                total: data.length,
                                defaultCurrent: 1,
                                pageSize:8,
                                showTotal: total => `总计 ${total} 个字典`,
                            }}/>
                    </Card>
                </Col>
                <Col span={15}>
                    <Card>
                        <DictTypeEdit dictId={selectId} type={selectType} />
                    </Card>
                </Col>
            </Row>
            <Modal destroyOnClose title="添加字典" okText='更新' open={open} onOk={onFinish} centered
                   onCancel={()=>setOpen(false)} okButtonProps={{disabled: loading}}>
                <div className='my-4'>
                    <Spin spinning={spin}>
                        <Form
                            preserve={false}
                            form={form}
                            labelCol={{span: 4}}
                            wrapperCol={{span: 16}}
                            name="basic_dict"
                            initialValues={{
                                sort: 0
                            }}
                        >
                            <Form.Item
                                label="字典名称"
                                name="name"
                                rules={[{required: true, message: '请输入字典名称！'}]}
                            >
                                <Input style={{width: 200}} placeholder='输入字典名称'/>
                            </Form.Item>
                            <Form.Item
                                label="字典类型"
                                name="type"
                                extra='只能包含字母和下划线'
                                rules={[{
                                    required: true,
                                    validator: async (rule, value) => {
                                        const isValue = isWebEnglish(value)
                                        if (!isValue || value.length === 0) {
                                            await Promise.reject('请输入正确类型标识！')
                                        }
                                        await Promise.resolve()
                                    }
                                }]}
                            >
                                <Input style={{width: 200}} placeholder='输入字典类型'/>
                            </Form.Item>
                            <Form.Item
                                label="排序"
                                name="sort"
                            >
                                <InputNumber min={0}/>
                            </Form.Item>
                        </Form>
                    </Spin>
                </div>
            </Modal>
        </Spin>
    )
}
export default Dict;
