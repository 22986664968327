/**
 * Created by devin on 2021/4/21.
 */

import React, {useEffect, useRef, useState} from 'react'
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {
    Avatar,
    Button,
    Card,
    Col,
    Input,
    List,
    Row,
    Select,
    Space,
    Tag,
    Typography,
    Tree,
    Spin, App, Badge
} from 'antd';
import {DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined, UnlockOutlined, PartitionOutlined} from '@ant-design/icons';
import {authState, departmentState, rolesState, treeDepartmentState} from "../../../store";
import {useDict, useHttps, usePermission} from "../../../hooks";
import {Icon} from "../../../config/Icon";
import Create from './Create'
import Edit from './Edit'
import Password from './Password'
import type { TreeProps } from 'antd/es/tree';
import {traceChildNode} from "../../../utils/utils";
const {Text} = Typography;

const Admin = () => {
    const { message,  modal } = App.useApp();
    const user = useRecoilValue(authState)
    const {get, destroy} = useHttps();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<SystemType.Data[]>([])
    const [current, setCurrent] = useState(0)
    const [online, setOnline] = useState<{[index:string]:boolean}>({})
    const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([-1])
    const [search, setSearch] = useState<SystemType.Data[]>([])
    const {getDictValue, getDict} = useDict();
    const {isPermission} = usePermission();
    const setDepartment = useSetRecoilState<SystemType.Department[]>(departmentState);
    const setRoles = useSetRecoilState(rolesState);
    const treeDepartment = useRecoilValue(treeDepartmentState);
    const [searchOpen, setSearchOpen] = useState(false)
    const createRef = useRef<any>()
    const editRef = useRef<any>()
    const passwordRef = useRef<any>()
    /**
     * 获取在线用户
     */
    useEffect(() => {
        get('admin/manage/online', null, true)
            .then((res: Request.Response<SystemType.Online[]>) => {
                if (res.mounted) {
                    const obj:{[index:string]:boolean} = {};
                    for (let i = 0; i < res.data.length; i++) {
                        obj[res.data[i].username] = true;
                    }
                    setOnline(obj)
                }
            });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get]);

    /**
     * 数据请求
     * @param {Object} params
     */


    useEffect(() => {
        get('admin/manage', null, true)
            .then((res: Request.Response<Array<SystemType.Data>>) => {
                if (res.mounted) {
                    setLoading(false);
                    setData(res.data);
                }

            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message)
                }
            });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get]);


    /**
     * 获取部门
     */
    useEffect(() => {
        get('admin/department', null, true)
            .then((res:Request.Response<SystemType.Department[]>) => {
                if (res.mounted) {
                    setDepartment(res.data);
                }
            })
            .catch(async (e:Request.Error) => {
                if (e.mounted) {
                    message.error(e.message)
                }
            });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get, setDepartment]);


    /**
     * 获取角色
     */
    useEffect(() => {
        get('admin/roles', null, true)
            .then((res:Request.Response<SystemType.Roles[]>) => {
                if (res.mounted) {
                    setRoles(res.data);
                }
            })
            .catch(async (e:Request.Error) => {
                if (e.mounted) {
                    message.error(e.message)
                }
            });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [get, setRoles]);


    /**
     * 删除列表操作
     * @param id
     */
    const onDelete = (id: number) => {
        setLoading(true);
        destroy('admin/manage/' + id)
            .then((res) => {
                if (res.mounted) {
                    let dataSource = data.filter((item)=>item.id !== id)
                    setData(dataSource)
                    if(searchOpen){
                        let dataSearchSource =  search.filter((item)=>item.id !== id)
                        setSearch(dataSearchSource)
                    }
                    setLoading(false)
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    };


    /**
     * 操作提示信息
     */
    const showConfirm = (id: number) => {
        modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要删除选中项目吗？</div>,
            onOk() {
                onDelete(id)
            },

        });
    }

    /**
     * 搜索
     * @param e
     */
    const onSearch = async (e: string) => {
        setCurrent(1)
        if (e.length === 0) {
            setSearch([])
            setSearchOpen(false)
            return
        }
        setSelectedKeys([-1])
        const searchData = data.filter((el) => el.name.toLowerCase().indexOf(e.toLowerCase()) > -1)
        setSearchOpen(true)
        setSearch(searchData)
    }

    /**
     * 搜索类型
     * @param e
     */
    const onSearchType = async (e: string|number) => {
        setCurrent(1)
        if (parseInt(e as never) < 0) {
            setSearch([])
            setSearchOpen(false)
            return
        }
        const searchData = data.filter((el) => el.status === e)
        setSearchOpen(true)
        setSearch(searchData)
    }


    /**
     * 搜索部门
     * @param selectedKeys
     */
    const onSearchDepartment:TreeProps['onSelect'] = (selectedKeys) => {

        setCurrent(1)
        if(selectedKeys.length === 0 || parseInt(selectedKeys[0] as never)  < 0){
            setSelectedKeys([-1])
            setSearch([])
            setSearchOpen(false)
            return
        }
        setSelectedKeys(selectedKeys)
        const child = traceChildNode(selectedKeys[0], treeDepartment)
        const childIds = child.map((item)=>item.id);
        let ids = [...selectedKeys, ...childIds]
        const searchData = data.filter((el) => ids.includes(el.department_id))
        setSearchOpen(true)
        setSearch(searchData)
    }

    /**
     * 添加管理员
     * @param params
     */
    const onCreate = (params: SystemType.Data) => {
        const dataSource = [...data]
        dataSource.unshift(params)
        setData(dataSource)
    }

    /**
     * 编辑员工
     * @param params
     */

    const onEdit = (params: SystemType.Data) => {
        const dataSource = [...data]
        const index = dataSource.findIndex((item) => item.id === params.id)
        dataSource[index] = params;
        setData(dataSource)
    }

    /**
     * 返回视图
     */

    return (
        <Spin spinning={loading}>
            <Row justify='space-between'  className='mb-4'>
                <Col>
                    <Button
                        disabled={!isPermission('admin.post')}
                        onClick={() => createRef.current.onOpen()}
                        type="primary"
                        icon={<PlusOutlined/>}
                    >
                        添加员工
                    </Button></Col>
                <Col>
                    <Space>
                        <Space.Compact>
                            <Select style={{width: 140}} defaultValue={'-1'} onChange={onSearchType}>
                                <Select.Option value={'-1'}>全部状态</Select.Option>
                                {getDict('admin_status').map((item)=> <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>)}
                            </Select>
                        </Space.Compact>

                        <Input.Search
                            allowClear
                            onSearch={onSearch}
                            placeholder="输入姓名搜索"
                            enterButton="搜索"
                        />
                    </Space>

                </Col>
            </Row>
            <Row gutter={16} wrap={false}>
                <Col flex="360px">
                    <Card title='按部门筛选'>
                        <Tree
                            showIcon
                            defaultExpandAll
                            showLine
                            height={400}
                            selectedKeys={selectedKeys}
                            icon={<PartitionOutlined />}
                            onSelect={onSearchDepartment}
                            fieldNames={{key: 'id', title: 'name', children: 'children'}}
                            treeData={[ ...[{id: -1,name: "全部部门",parent_id:0}], ...treeDepartment]}
                        />
                    </Card>
                </Col>

                <Col flex="auto">
                    <List
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 1,
                            md: 2,
                            lg: 2,
                            xl: 2,
                            xxl: 3,
                        }}
                        dataSource={searchOpen  ? search : data}
                        pagination={{
                            total: searchOpen ? search.length : data.length,
                            defaultCurrent: 1,
                            current: current,
                            showSizeChanger: true,
                            pageSize: 9,
                            showTotal: total => `总计 ${total} 位员工`,
                            onChange: (page) => setCurrent(page)
                        }}
                        renderItem={item => {
                            const status = getDictValue('admin_status', item.status)
                            return (
                                <List.Item key={item.id} className='p-0'>
                                    <Card
                                        actions={isPermission('admin.post') ? [
                                            <Button type='text' size='small'
                                                    onClick={() => editRef.current.onOpen(item.id)}
                                                    icon={<EditOutlined/>}/>,
                                            <Button type='text' size='small' icon={<UnlockOutlined/>}

                                                    onClick={ () => passwordRef.current.onOpen(item.id)}/>,
                                            <Button block type='text' size='small' icon={<DeleteOutlined/>}
                                                    disabled={user?.id === item.id}
                                                    onClick={user?.id !== item.id  ? () => showConfirm(item.id) : () => {
                                                    }}/>
                                        ] : []}
                                    >
                                        <div className='cursor-pointer' onClick={() => editRef.current.onOpen(item.id)}>
                                        <Card.Meta
                                            avatar={<Badge dot={online.hasOwnProperty(item.id)} title="在线" status="processing"><Avatar size={80} shape="square"
                                                                              src={item.avatar ? item.avatar : Icon.avatar}/></Badge>}
                                            title={item.name}
                                            description={
                                                <Space direction='vertical'>
                                                    <Text type='secondary'>职位：{item.position || '普通员工'}</Text>
                                                    <Text type='secondary'>电话：{item.mobile}</Text>
                                                </Space>}
                                        />
                                        </div>
                                        <div className='position-absolute end-0 top-0 m-3'>
                                            <Tag color={status?.style}>{status?.label}</Tag>
                                        </div>
                                    </Card>
                                </List.Item>
                            )
                        }}
                    />
                </Col>
            </Row>
            <Create ref={createRef} onChange={onCreate}/>
            <Edit ref={editRef} onChange={onEdit}/>
            <Password ref={passwordRef}/>
        </Spin>
    )
}
export default Admin;
