import React from 'react';
import {Button,  Result} from 'antd';


const Error404 = props => {

	return (
		<div>
			<div className='bg-white p-4'>
				<Result
					status="404"
					title="404"
					subTitle="很抱歉，您要查找的页面不存在！"
					extra={<Button onClick={()=> props.history.push('/')}  type="primary">返回首页</Button>}
				/>
			</div>
		</div>


	);
};

export default Error404;
