import {useMemo} from "react";
import {downloadState} from "../store";
import {useRecoilState} from "recoil";
const useElectron = () => {
    const [download , setDownload ]= useRecoilState<any>(downloadState);

    /**
     * 判断是不是electron环境
     */
    const isElectron = useMemo(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        return userAgent.indexOf('electron/') > -1;
    },[])


    /**
     * electron api
     */
    const electron  = useMemo(() => {
         if(isElectron){
             return window.require('electron');
         }
         return null;
    },[isElectron])

    /**
     * 下载文件
     * @param url
     */
    const downloadFile = async (url:string) => {
            const dataSource = [...download]
            //查询是否已下载
            const isDownload = dataSource.find(item => item.downloadPath === url)
            if(isDownload){
                return Promise.reject({
                    code: 0,
                    message: "已添加到下载列表，不要重复提交",
                    status: 403,
                });
            }
            dataSource.push({
                downloadPath: url,
                fileName:  url.split('/').pop(),
                savedPath:'string',
                progress:0,
                status :'start',
                trigger: 'open'
            })
            let newList = Array.from(new Set(dataSource))
            setDownload(newList)
            //生成唯一ID
            electron.ipcRenderer.send('download', url)
            return Promise.resolve({
                code: 1,
                message: "已添加到下载列表",
                status: 200,
            });
    }

    const saveFile = async (file:any,type:string) => {
        electron.ipcRenderer.send('file-save', {file, type})
        return Promise.resolve({
            code: 1,
            message: "文件保存成功",
            status: 200,
        });
    }


    return {downloadFile, electron, isElectron , saveFile};
}

export default useElectron;