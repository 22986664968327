import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {Flex, Modal, Space, Typography} from "antd";
import dayjs from "dayjs";
const {Title , Text} = Typography;

const Details = forwardRef((prop, ref) => {
    const [open, setOpen] = useState(false)
    const [data, setData] = useState<SystemType.Notice>({
        content: "",
        created_at: undefined,
        created_by: '0',
        nickname:'',
        description: "",
        id: 0,
        link: "",
        status: '0',
        thumb: "",
        title: "",
        type: '0'
    })

    useImperativeHandle(ref, () => ({
        onOpen: (notice:SystemType.Notice) => {
            setOpen(true)
            setData(notice)
        },
    }));


    return (
        <Modal destroyOnClose title="消息详情" width={920}  open={open} footer={null} centered onCancel={()=> setOpen(false)} >
            <Flex justify='center' align='flex-start'>
              <div>
                  <div className='text-center'>
                      <Title level={5} >{data.title}</Title>
                  </div>
                  <Space size='large'>
                      <Text type='secondary'>发布日期：{dayjs(data.created_at).format('YYYY-MM-DD HH:ss')}</Text>
                      <Text type='secondary'>发布者：{data.nickname}</Text>
                  </Space>
              </div>
          </Flex>
          <div className='mt-3 px-3' dangerouslySetInnerHTML={{__html:  data.content}}></div>
        </Modal>
    )
})
export default Details;
