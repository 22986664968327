import React, {forwardRef, useImperativeHandle, useState} from "react";
import {
    App,
    Form,
    Input,
    InputNumber,
    Modal,
    Spin,
    TreeSelect,
} from "antd";

import {useHttps} from "../../../hooks";
import {useRecoilState, useRecoilValue} from "recoil";
import {departmentState, treeDepartmentState} from "../../../store";
import {isEmail} from "../../../utils/check";

const Edit = forwardRef((prop, ref) => {
    const { message } = App.useApp();
    const [loading, setLoading] = useState(false)
    const [id, setId] = useState(0)
    const [editOpen, setEditOpen] = useState(false)
    const {put , get} = useHttps();
    const [form] = Form.useForm();
    const [department, setDepartment] = useRecoilState<SystemType.Department[]>(departmentState);
    const treeDepartment = useRecoilValue<SystemType.Department[]>(treeDepartmentState);


    useImperativeHandle(ref, () => ({
        onOpen: (id : number) => {
            setId(id)
            getData(id)
            setEditOpen(true)
        },
    }));

    const  getData = (id:number) => {
        setLoading(true)
        get('admin/department/' + id, null, true)
            .then((res: Request.Response<SystemType.Department>) => {
                if (res.mounted) {
                    res.data.parent_id = res.data.parent_id === 0 ? undefined : res.data.parent_id;
                    form.setFieldsValue(res.data)
                    setLoading(false);
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message)
                }

            });
    };
    /**
     * 添加菜单操作
     */

    const onUpdate = async () => {
        try {
            let values = await form.validateFields();
            setLoading(true);
            values.parent_id = values.parent_id ? values.parent_id : 0
            put('admin/department/' + id, values)
                .then((res:Request.Response<SystemType.Department>) => {
                    if (res.mounted) {
                        let dataSource = [...department]
                        const select = dataSource.findIndex((value) => value.id === id);
                        dataSource[select] = res.data ;
                        setDepartment(dataSource)
                        setLoading(false)
                        setEditOpen(false)
                        form.resetFields()
                    }
                })
                .catch(async (e:Request.Error) => {
                    if (e.mounted) {
                        setLoading(false);
                        message.error(e.message);
                    }
                });
        } catch (err) {
            return;
        }

    };

    /**
     * 关闭模态框
     */
    const onCancel = () => {
        setEditOpen(false)
        form.resetFields()
    }


    return (
        <Modal destroyOnClose title="编辑部门" width={620} okText='更新' open={editOpen} onOk={onUpdate} centered
               onCancel={onCancel}  okButtonProps={{ disabled: loading }}>

            <Spin spinning={loading}>
                <Form
                    form={form}
                    labelCol={{span: 4}}
                    wrapperCol={{span: 16}}
                    name="basic_department_edit"
                    initialValues={{
                        show: false,
                        type: 0,
                        sort:0
                    }}
                >
                    <Form.Item
                        label="部门名称"
                        name="name"
                        rules={[{required: true, message: '请输入部门名称！'}]}
                    >
                        <Input style={{width: 200}} placeholder='输入部门名称'/>
                    </Form.Item>
                    <Form.Item
                        label="排序"
                        name="sort"
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                        label="上级部门"
                        name="parent_id"
                    >
                        <TreeSelect
                            style={{width: 300}}
                            showSearch
                            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                            placeholder="选择上层部门"
                            allowClear
                            fieldNames={{value: 'id', label: 'name', children: 'children'}}
                            treeData={treeDepartment}
                        />
                    </Form.Item>

                    <Form.Item
                        label="联系人"
                        name="linkman"
                        rules={[{
                            required: true,
                            message: '请输入联系人！',
                        }
                        ]}
                    >
                        <Input   style={{width: 100}} placeholder='输入联系人'/>
                    </Form.Item>
                    <Form.Item
                        label="联系电话"
                        name="tel"
                        rules={[{
                            required: true,
                            message: '请输入联系电话！',
                        }
                        ]}
                    >
                        <Input   style={{width: 200}} placeholder='输入联系电话'/>
                    </Form.Item>
                    <Form.Item
                        label="邮箱地址"
                        name="email"
                        rules={[{
                            required: true, validator: async (rule, value) => {
                                const isValue = isEmail(value)
                                if (!isValue) {
                                    await Promise.reject('请输入正确邮箱地址！')
                                }
                                await Promise.resolve()
                            }
                        }]}
                    >
                        <Input   style={{width: 200}} placeholder='输入电子邮箱'/>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )


})

export default Edit;
