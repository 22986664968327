import React, {useMemo} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useRecoilValue} from "recoil";
import {Menu} from 'antd';

import {algorithmState, roleState} from "../../store";
import {getTreeData} from "../../utils/utils";
import menuIcons from "../../routers/icons";


/*
  左侧导航组件
*/
const SideMenu = () => {
    const algorithm = useRecoilValue(algorithmState);
    const { menus }= useRecoilValue(roleState)
    let {pathname, state } = useLocation();
    const navigate = useNavigate();
    const getMenuOpened = () => {
        const url = pathname.split('/').filter(i => i);
        return url.map((urlItem, index) => `/${url.slice(0, index + 1).join('/')}`)

    }


    /**
     *
     * 根据用户权限过滤菜单
     */

    const menuFilter = useMemo(() => {
        if(menus){
            let dataSource = menus.filter((item) => item.is_show);
            const getMenu = dataSource.map((item) => {
                let data: { [index: string]: any } = {
                    icon: menuIcons[item.icon],
                    is_show: item.is_show.toString(),
                    key: item.url
                }
                return {...item, ...data}
            });

            getMenu.sort((a:any, b:any) => a.sort - b.sort);
            getMenu.unshift({
                id:1,
                label: '首页',
                url: '/home',
                type: 'string',
                sort: 1,
                key: '/home',
                slug: 'home',
                icon:menuIcons['DashboardOutlined'],
                parent_id: 0,
                is_show:'true' as any,
                children:[]
            })
            return getTreeData(getMenu, 0)
        }
       return []
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    return (
            <Menu
                style={{backgroundColor:algorithm ? '#141414' :'#fff'}}
                defaultOpenKeys={getMenuOpened()}
                defaultSelectedKeys={state && state.tab ? [state.history] : [pathname]}
                selectedKeys={state && state.tab ? [state.history] :  [pathname]}
                mode="horizontal"
                theme={algorithm ? "dark" : 'light'}
                onClick={(item) => {
                    navigate(item.key)
                }}
                items={menuFilter}
            />
    )
}

export default SideMenu
