/**
 * Created by devin on 2021/4/23.
 */

import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {
    Cascader,
    Form,
    Input,
    Radio,
    Select,
    Spin,
    TreeSelect,
    Modal,
    Row,
    Col, App
} from "antd";
import {FileSelect} from "../../../components";
import {useDict, useHttps} from "../../../hooks";

import {useRecoilValue} from "recoil";
import {rolesState, treeDepartmentState} from "../../../store";
import {isCorrectFormatUsername, isCorrectFormatPassword} from "../../../utils/check";
import {cityTreeData} from "../../../utils/city";


const Create = forwardRef((prop: { onChange: (e: SystemType.Data) => void; }, ref) => {
    const { message } = App.useApp();
    const roles = useRecoilValue(rolesState);
    const [loading, setLoading] = useState(false)
    const {post} = useHttps();
    const [open, setOpen] = useState(false)
    const [form] = Form.useForm();
    const treeDepartment = useRecoilValue<SystemType.Department[]>(treeDepartmentState);
    const {getDict} = useDict();
    useImperativeHandle(ref, () => ({
        onOpen: () => {
            setOpen(true)
        },
    }));

    const onFinish = async () => {
        try {
            let params = await form.validateFields();
            setLoading(true)
            params.roles = roles.filter((item) => params.roles?.includes(item.id))
            params.department_id = params.department_id ? params.department_id : undefined
            post('admin/manage', params)
                .then(async (res: Request.Response<SystemType.Data>) => {
                    if (res.mounted) {
                        setLoading(false)
                        prop.onChange(res.data)
                        setOpen(false)
                    }

                })
                .catch(async (e: Request.Error) => {
                    if (e.mounted) {
                        setLoading(false)
                        message.error(e.message);
                    }
                });
        } catch (err) {
            return;
        }
    }

    const onCancel = () => {
        setOpen(false)
    }


    return (<Modal destroyOnClose title="添加员工" width={920} okText='添加' open={open} onOk={onFinish} centered onCancel={onCancel} okButtonProps={{disabled: loading }} >
        <Spin size="small" spinning={loading}>

                <Form
                    preserve={false}
                    form={form}
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    name="basic_admin_create"
                    initialValues={{
                        status: '0',
                        avatar: '',
                        gender: '0',
                        description: '',
                        address: '',

                    }}
                    onFinish={onFinish}
                >

                    <Row gutter={32}>
                        <Col span={10}>
                    <Form.Item
                        label="登录名"
                        name="username"
                        extra="用户名最少4位由（字母，数字）组成"
                        rules={[{
                            required: true, validator: async (rule, value) => {
                                const isValue = isCorrectFormatUsername(value) //isCorrectFormatUsername(value)
                                if (!isValue) {
                                    await Promise.reject('请输入正确用户名！')
                                }
                                await Promise.resolve()
                            }
                        }]}
                    >
                        <Input  placeholder='请输登陆名'/>
                    </Form.Item>
                            <Form.Item
                                label="登陆密码"
                                name='password'
                                extra="最少6位，包括大小写字母，数字，特殊字符"
                                rules={[{
                                    required: true, validator: async (rule, value) => {
                                        const isValue = isCorrectFormatPassword(value)
                                        if (!isValue) {
                                            await Promise.reject('请输入正确密码格式！')
                                        }
                                        await Promise.resolve()
                                    }
                                }]}
                            >
                                <Input.Password  placeholder="输入登陆密码"/>
                            </Form.Item>

                            <Form.Item
                                label="姓名"
                                name="name"
                                rules={[{required: true, message: '请输入姓名！'}]}
                            >
                                <Input  placeholder='输入真实姓名'/>
                            </Form.Item>
                            <Form.Item
                                label="性别"
                                name="gender"
                            >
                                <Radio.Group >
                                    {getDict('gender').map((item) => <Radio key={item.value} value={item.value}>{item.label}</Radio>)}
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="头像"
                                name='avatar'
                                extra="上传尺寸建议200x200"
                                className="mt-3"
                            >
                                <div className='w-100'>
                                    <FileSelect
                                        listType='picture-card'
                                        text='上传头像'
                                        size={1}
                                        type={1}
                                        onChange={(e) => {
                                            form.setFieldsValue({avatar: e[0]?.url || ''})
                                        }}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item
                                label="手机号"
                                name="mobile"
                                rules={[{required: true, message: '请输入手机号！'}]}
                            >
                                <Input  placeholder='输入手机号码'/>
                            </Form.Item>

                            <Form.Item
                                label="身份证号码"
                                name="card_id"
                            >
                                <Input maxLength={20} placeholder='请填写身份证号码！'/>
                            </Form.Item>

                        </Col>
                        <Col span={14}>
                            <Form.Item
                                name="roles"
                                label="选择角色"
                                rules={[{required: true, message: '请指定员工角色！'}]}
                            >
                                <Select mode='multiple' placeholder="选择角色">
                                    {roles.map((tag) => {
                                        return (<Select.Option key={tag.id} value={tag.id}>{tag.name}</Select.Option>);
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="所属部门"
                                name="department_id"
                            >
                                <TreeSelect
                                    showSearch
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    placeholder="选择部门"
                                    allowClear
                                    fieldNames={{value: 'id', label: 'name', children: 'children'}}
                                    treeData={treeDepartment}
                                />
                            </Form.Item>
                            <Form.Item
                                label="职称"
                                name="position"
                                rules={[{required: true, message: '请输入职称！'}]}
                            >
                                <Input placeholder='输入职位或职称'/>
                            </Form.Item>
                            <Form.Item
                                label="所在城市"
                                name="city"
                            >
                                <Cascader
                                    placeholder='输入所在城市'
                                    options={cityTreeData}
                                />
                            </Form.Item>

                            <Form.Item
                                label="详细地址"
                                name="address"
                            >
                                <Input maxLength={50} placeholder="请输入精准到门牌号"/>
                            </Form.Item>
                            <Form.Item
                                label="企业邮箱"
                                name="email"
                            >
                                <Input type='email' maxLength={20} placeholder='请填写邮箱地址！'/>
                            </Form.Item>
                            <Form.Item name='description' label="个人介绍">
                                <Input.TextArea/>
                            </Form.Item>
                            <Form.Item
                                name="status"
                                label="职位状态"
                                extra="只有在职中和已休假的员工才可以登陆！"
                            >
                                <Radio.Group >
                                    {getDict('admin_status').map((item)=> <Radio key={item.value} value={item.value}>{item.label}</Radio>)}
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
        </Spin>
    </Modal>)
})
export default Create;
