import React, {useState} from 'react'
import {Col, Row, Tag, Space, Tooltip, Switch, Button} from "antd";
import {algorithmState, websocketState} from "../../store";
import {
    QuestionCircleOutlined,
    DesktopOutlined, MinusOutlined, BorderOutlined, CloseOutlined
} from '@ant-design/icons';
import {useRecoilValue, useSetRecoilState} from "recoil";
import SideMenu from './SideMenu'
import TopNotice from "./TopNotice";
import TopUser from "./TopUser";
import {useElectron, useWebSocket} from "../../hooks";
import {Logo} from "../../config/Config";


/*
  头部组件
*/
const TopHeader = () => {
    const {electron, isElectron} = useElectron()
    const setAlgorithm = useSetRecoilState(algorithmState);
    const websocketStatus = useRecoilValue(websocketState);
    const  {reconnect} = useWebSocket()
    const tagColor = ['warning', 'success','processing','error']
    const algorithm = useRecoilValue(algorithmState);

    const confirm = (e:boolean) => {
        setAlgorithm(e)
        if(e){
            localStorage.setItem('algorithm', "1")
        }else{
            localStorage.removeItem('algorithm')
        }
    };

    const onControl = (type:string)=> {
        if(type === 'minimize'){
            electron.ipcRenderer.send('windows-control', 'minimize')
        }
        if(type==='restore'){
            electron.ipcRenderer.send('windows-control', 'restore')
        }
        if(type === 'quit'){
            sessionStorage.removeItem("token")
            sessionStorage.removeItem("auth")
            sessionStorage.removeItem("role")
            electron.ipcRenderer.send('windows-control', 'quit')
        }

    }



    return (
        <div className='position-absolute w-100 top-0 start-0 z-2 px-3' style={{backgroundColor:algorithm ? '#141414' :'#fff'}}>
            <Row>
                <Col flex='220px' className='electron-container'>
                        <img
                            alt='logo'
                            style={{height:40}}
                            src={algorithm ? Logo.black:Logo.dark}
                        />
                </Col>
                <Col flex='auto'>
                    <SideMenu />
                </Col>
                <Col >
                    <Space size='large' className='electron-no-drag'>
                        <TopNotice/>
                        <Switch checkedChildren="明亮" checked={algorithm} unCheckedChildren="暗黑"  onChange={confirm} />
                        <Tooltip title="使用文档" className="cursor"><QuestionCircleOutlined/></Tooltip>
                        <Tag icon={<DesktopOutlined />} onClick={websocketStatus.key === 3 ? reconnect : ()=>{}}  color={tagColor[websocketStatus.key]} className='me-0 cursor-pointer'>{websocketStatus.value}</Tag>
                        <TopUser/>
                        {isElectron && <Space size='large' >
                        <Button size='small' type="text" icon={<MinusOutlined />} onClick={()=> onControl('minimize')}/>
                        <Button size='small' type="text" icon={<BorderOutlined />} onClick={()=> onControl('restore')} />
                        <Button size='small'  type="text" icon={<CloseOutlined />} onClick={()=> onControl('quit')} />
                        </Space>}
                    </Space>
                </Col>
            </Row>


        </div>
    )
}
export default TopHeader
