/**
 * Notes: 文件选择组件
 * User: 李强
 * DateTime: 2021/10/5 9:37 上午
 * Ide: PhpStorm
 */

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {App, Flex, RadioChangeEvent, UploadProps} from 'antd';
import {
    Badge,
    Button,
    Checkbox,
    Col,
    Form,
    Image,
    Input,
    List,
    Modal,
    Popover,
    Progress,
    Radio,
    Row,
    Space,
    Spin,
    Typography,
    Upload,
    Card,
    Empty
} from "antd";

import {
    BarsOutlined,
    CheckCircleFilled,
    CloseCircleFilled,
    CloseOutlined,
    CloudDownloadOutlined,
    DeleteOutlined,
    EditOutlined,
    EllipsisOutlined,
    ExclamationCircleFilled,
    FolderAddOutlined,
    FolderOpenOutlined,
    PlayCircleFilled,
    SwapOutlined,
    SyncOutlined,
    UploadOutlined,
    VerticalAlignTopOutlined,
    RightOutlined,
} from "@ant-design/icons";
import {useRecoilValue} from "recoil";
import {Item, Menu, Separator, useContextMenu} from 'react-contexify';
import "react-contexify/dist/ReactContexify.css";
import {Video} from '../../components'
import {algorithmState, tokenState} from "../../store";
import dayjs from "dayjs";
import {useHttps, usePermission, useFile} from "../../hooks";
import FileMove from "./FileMove";

import type {UploadFile} from 'antd/es/upload/interface';
import {formatFileSize, randomNumInteger, subText} from "../../utils/utils";
import './style.css';
import {UploadApi} from "../../config/Config";

const {Text, Paragraph, Link} = Typography
const Storage: React.FC = () => {
    const { message, modal } = App.useApp();

    const algorithm = useRecoilValue(algorithmState);
    const token = useRecoilValue<string | null>(tokenState);
    const {get, post, destroy, put} = useHttps();
    const {getIcon ,downloadFile}=useFile()
    const [loading, setLoading] = useState(true)
    const [selectType, setSelectType] = useState(0)
    const [data, setData] = useState<Array<StorageType.Data>>([])
    const [editId, setEditId] = useState('0')
    const [selectData, setSelectData] = useState<Array<StorageType.Data>>([])
    const [storageRouter, setStorageRouter] = useState<Array<StorageType.Data>>([{
        id: '0',
        title: '全部文件',
        url: '#',
        size: 0,
        type: 0,
        sort: 1,
        mini_type: 'file',
        parent_id: '0',
        filename: '#',
        created_at: new Date()
    }]);

    const [current, setCurrent] = useState(1)

    const [uploadParentId, setUploadParentId] = useState('0');
    const [uploadOpen, setUploadOpen] = useState(false)
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const playerRef = useRef<any>();
    const [videoOpen, setVideoOpen] = useState(false);
    const [hasMore, setHasMore] = useState(false)
    const {show} = useContextMenu();
    const {isPermission} = usePermission();
    const [form] = Form.useForm();
    const [refreshing, setRefreshing] = useState(false);
    const contentRef = useRef<any>(null) // 初始值
    const fileMoveRef = useRef<any>()
    const perPage = 50;
    let dblclick: NodeJS.Timeout | null;

    /**
     * 获取当前父ID
     */
    const getParentId = useMemo((): string => {
        const endData = storageRouter[storageRouter.length - 1];
        return endData.id;
    }, [storageRouter])


    /**
     * 获取文件
     */
    const getData = useCallback((params: any) => {
        params.per_page = perPage;
        get('admin/storage/query', params, true)
            .then((res: Request.Response<Array<StorageType.Data>>) => {
                if (res.mounted) {
                    console.log(res.data)
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setHasMore(res.data.length < perPage)
                    setRefreshing(false)
                    if (params.page === 1) {
                        setData(res.data);
                    } else {
                        setData((r) => r.concat(res.data))
                    }
                }

            })
            .catch(async (e) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message)
                }
            });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[get])


    useEffect(() => {
        getData({page: 1, parent_id: '0'});
    }, [getData])

    /**
     * 刷新文件
     */
    const getRefreshUpload =  () => {
        if (getParentId !== '-1' || !loading) {
            setLoading(true)
            setTimeout(() => {
                get('admin/storage/query', {page:1,per_page:perPage, parent_id: getParentId}, true)
                    .then((res: Request.Response<Array<StorageType.Data>>) => {
                        if (res.mounted) {
                            setCurrent(res.meta.current_page)
                            setData(res.data);
                            setLoading(false);
                        }

                    })
            }, 3000);
        }
    }
    /**
     * 项目右键菜单
     * @param event
     * @param params
     */
    const onItemMenu = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, params: StorageType.Data) => {
        event.stopPropagation();
        //如果是未保存的新建文件夹将返回
        if (params.cache) {
            return
        }
        //设置选中
        if (!isSelect(params) && isPermission('storage.post')) {

            onSelectChange(params)
        }

        // 选中效果
        show({
            event,
            props: params,
            id: "itemId"
        })
    }

    /**
     * 空白区右键菜单
     * @param event
     */
    const onMenu = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        if (!isPermission('storage.post')) {
            return;
        }
        // 选中效果
        show({
            event,
            props: data,
            id: "menuId"
        })
    }

    /**
     * 点击菜单回调
     * @param params
     */

    const onItemClick = async (params: any) => {
        if (params.id === 'move') {
            fileMoveRef.current.onOpen()
            return
        }
        if (params.id === 'remove') {
            showDeleteConfirm()
            return
        }

        if (params.id === 'edit') {
            onEdit(params.props)
            return
        }

        if (params.id === 'open') {
            onPreviewType(params.props)
            return
        }
        if (params.id === 'download') {
            if (params.props.type === 0) {
                message.error('不支持文件夹下载')
                return
            }
            downloadFile(params.props.url)
            return
        }

        if (params.id === 'file') {
            onCreate()
            return
        }
        if (params.id === 'select') {
            onSelectAll()
            return
        }

        if (params.id === 'refresh') {
            setLoading(true)
            getData({page: 1, parent_id: getParentId});
            return
        }
    }


    /**
     * 编辑文件标题
     * @param params
     */
    const onEdit = (params: StorageType.Data) => {
        deleteCacheFile()
        setSelectData([params])
        setEditId(params.id)
        form.setFieldsValue({title: params.title})
    }
    /**
     * 从服务端删除文件
     */
    const onDelete = () => {
        if (selectData.length === 0) {
            return
        }
        setLoading(true);
        //获取全部id
        let ids = selectData.map((item) => item.id);
        //提交删除
        destroy('admin/storage', ids)
            .then((res) => {
                if (res.mounted) {
                    setSelectData([])
                    getData({page: 1, parent_id: getParentId})
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    }
    /**
     * 更新文件
     * @param params
     * @param index
     */
    const onUpdate = async (params: StorageType.Data, index: number) => {
        try {
            let values = await form.validateFields();
            setLoading(true)
            put('admin/storage/' + params.id, values.title)
                .then(async (res: Request.Response<StorageType.Data>) => {
                    if (res.mounted) {
                        setLoading(false)
                        message.success('更新成功！');
                        const dataSource = [...data];
                        dataSource[index] = res.data;
                        setEditId('0')
                        setData(dataSource)
                    }
                })
                .catch(async (e: Request.Error) => {
                    if (e.mounted) {
                        setLoading(false)
                        message.error(e.message);
                    }
                });

        } catch (err) {
            return;
        }
    }

    /**
     * 移动文件
     */
    const onMove = async (params: StorageType.Data, items: StorageType.Data[]) => {
        fileMoveRef.current.onCancel()
        const isPath = selectData.some((s) => items.some((i) => i.id === s.id) || s.parent_id === params.id)
        if (isPath) {
            await message.error('不能将文件移动到自身或其子目录下')
            return
        }
        const ids = selectData.map((s) => s.id);
        setLoading(true)
        put('admin/storage?parent_id=' + params.id, ids)
            .then(async (res: Request.Response<StorageType.Data>) => {
                if (res.mounted) {
                    setSelectData([])
                    getData({page: 1, parent_id: getParentId})
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    }

    /**
     * 保存文件
     * @param params
     * @param index
     */
    const onFinish = async (params: StorageType.Data, index: number) => {

        try {
            let values = await form.validateFields();
            params.title = values.title
            setLoading(true)
            post('admin/storage', {...params, ...{created_at :dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')} })
                .then(async (res: Request.Response<StorageType.Data>) => {
                    if (res.mounted) {
                        setLoading(false)
                        message.success('添加成功！');
                        const dataSource = [...data];
                        dataSource[index] = res.data;
                        setData(dataSource)
                    }
                })
                .catch(async (e: Request.Error) => {
                    if (e.mounted) {
                        setLoading(false)
                        message.error(e.message);
                    }
                });

        } catch (err) {
            return;
        }
    }

    /**
     * 创建文件
     */
    const onCreate = () => {
        const dataSource = [...data];
        //先判断是否存在缓存文件夹如果存在返回
        const isFile = dataSource.some((i) => i.cache)
        if (isFile) {
            return;
        }
        const id = randomNumInteger(1000000, 9999999).toString();
        //插入最顶部
        dataSource.unshift({
            id: id,
            title: '新建文件夹',
            url: '#',
            size: 0,
            type: 0,
            sort: 1,
            filename: '#',
            mini_type: 'file',
            parent_id: getParentId === '-1' ? '0' : getParentId,
            created_at: new Date(),
            cache: true
        })
        form.setFieldValue('title', '新建文件夹')
        setEditId(id);
        setData(dataSource)
    }

    /**
     * 删除前确认
     */
    const showDeleteConfirm = () => {
        modal.confirm({
            title: "确定要删除这" + selectData.length + "项吗?",
            icon: <ExclamationCircleFilled/>,
            content: '删除后将无法恢复！',
            centered: true,
            okText: '删除',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                onDelete();
            },
        });
    };

    /**
     * 选择触发
     * @param params
     */

    const onSelectChange = (params: StorageType.Data) => {
        //删除缓存id
        setEditId('0')
        const select = selectData.findIndex((value: any) => value.id === params.id);
        const dataSource = [...selectData];
        if (select > -1) {
            dataSource.splice(select, 1);
        } else {
            dataSource.push(params);
        }
        setSelectData(dataSource)
    }

    /**
     * 面包屑导航
     * @param params
     * @param index
     */
    const onRouterChange = (params: StorageType.Data, index: number) => {
        let dataSource = [...storageRouter];
        dataSource.splice(index + 1, dataSource.length)
        const parentId = dataSource[dataSource.length - 1];
        setStorageRouter(dataSource)
        setSelectType(0)
        setSelectData([])
        setLoading(true)
        getData({page: 1, parent_id: parentId.id})
    }



    /**
     * 删除缓存的新建文件夹
     */
    const deleteCacheFile = () => {
        let dataSource = [...data];
        const select = dataSource.filter((i: any) => !i.cache);
        setData(select)
        setEditId('0')
    }

    /**
     * 删除下载中的文件
     * @param params
     */
    const onDeleteFileList = (params: UploadFile) => {
        let dataSource = fileList.filter((i) => i.uid !== params.uid)
        setFileList(dataSource)
    }

    /**
     * 获取下载中的文件数
     */
    const getUploadCount = useMemo((): number => {
        let dataSource = fileList.filter((i) => i.status === 'uploading')
        return dataSource.length
    }, [fileList])


    /**
     * 验证是否已选择
     * @returns {boolean}
     * @param params
     */
    const isSelect = (params: StorageType.Data): boolean => {
        const select = selectData.findIndex((value: any) => value.id === params.id);
        return select > -1
    }

    /**
     * 全选
     */
    const onSelectAll = () => {
        if (selectData.length === 0) {
            let dataSource = [...data];
            const select = dataSource.filter((i: any) => !i.cache);
            setSelectData(select)
        } else {
            setSelectData([])
        }
    }

    /**
     * 项目点击事件
     * @param params
     */
    const onPreviewType = (params: StorageType.Data) => {
        if (params.cache) {
            return
        }
        if (dblclick) {
            clearTimeout(dblclick);
        }
        if (params.type === 1) {
            setPreviewUrl(params.url)
            setPreviewOpen(true)
            return;
        }
        if (params.type === 2) {
            playerRef.current.onOpen(params.url, params.title)
            return;
        }

        if (params.type === 4) {
            playerRef.current.onOpen(params.url, params.title, 0)
            return;
        }

        if (params.type === 0) {
            dblclick = setTimeout(() => {
                let dataSource = [...storageRouter];
                dataSource.push(params)
                setStorageRouter(dataSource)
                setSelectData([])
                setLoading(true)
                getData({page: 1, parent_id: params.id})
            }, 300)
            return;
        }
        downloadFile(params.url)
    }

    /**
     * OSS上传数据
     * @returns {*}
     */
    const getExtraData: UploadProps['data'] = () => {
        return {
            'parent_id': getParentId === '-1' ? '0' : getParentId,
        };
    };

    /**
     * 上传前回调
     * @param file
     * @returns {*}
     */
    const beforeUpload: UploadProps['beforeUpload'] = async file => {
        //打开上传列表
        if (file.size > 104857600) {
            message.error('上传文件不能大于 100MB!');
            return Upload.LIST_IGNORE;
        }
        //上传前写入当前页面父ID
        setUploadParentId(getParentId)
        setUploadOpen(true)
    }

    /**
     * 上传回调
     * @returns {*}
     * @param info
     */
    const uploadChange: UploadProps['onChange'] = ({file, fileList}) => {
        console.log(file)
        setFileList([...fileList])
        //判断当前父ID是否是上传的的父ID或正在加载中返回
        if (uploadParentId !== getParentId) {
            return;
        }

        if (file.status !== 'done') {
                return;
        }
        //如果成功刷新数据
         getRefreshUpload();

    }

    /**
     * 滚动到底部刷新数据
     */

    const onRefresh = useCallback((e: React.UIEvent<HTMLElement, UIEvent>) => {
        //如果数据正在请求中或无更多数据返回
        if (!contentRef || hasMore || refreshing) {
            return;
        }
        if (e.currentTarget.clientHeight + e.currentTarget.scrollTop >= e.currentTarget.scrollHeight) {
            const pageNum = current + 1
            //判断是否选中文件类型
            let params: any = {
                page: pageNum
            }
            if (getParentId === '-1') {
                params.type = selectType
            } else {
                params.parent_id = getParentId
            }
            setRefreshing(true)
            getData(params);
        }

    }, [current, getData, getParentId, hasMore, refreshing, selectType])

    /**
     * 根据类型筛选文件
     * @param value
     */
    const onSelectType = ({target: {value}}: RadioChangeEvent) => {
        let dataSource = [...storageRouter];
        dataSource.length = 1;
        setSelectType(value)
        const title: any = {
            1: '全部图片',
            2: '全部视频',
            3: '全部文档',
            4: '全部音乐'
        }
        const params: any = {
            page: 1
        }
        if (value === 0) {
            params.parent_id = 0
        } else {
            params.type = value;
            //修改给面包屑
            dataSource.push({
                id: '-1',
                title: title[value],
                url: '#',
                size: 0,
                type: value,
                sort: 1,
                filename: '#',
                mini_type: '#',
                parent_id: '0',
                created_at: new Date(),
                cache: true
            })
        }
        //添加面包屑
        setStorageRouter(dataSource)
        setSelectData([])
        //发起请求
        setLoading(true)
        getData(params);

    }

    /**
     * 搜索文件
     * @param value
     */
    const onSearch = (value: string) => {
        if (value.length === 0) {
            return
        }
        let dataSource = [...storageRouter];
        dataSource.length = 1;
        //修改给面包屑
        dataSource.push({
            id: '-1',
            title: '搜索：' + value,
            url: '#',
            size: 0,
            type: 0,
            sort: 1,
            filename: '#',
            mini_type: 'search',
            parent_id: '0',
            created_at: new Date(),
            cache: true
        })

        setSelectData([])
        setSelectType(0)
        setStorageRouter(dataSource)
        //发起请求
        setLoading(true)
        getData({page: 1, title: value});
    };



    return (
        <Card>
             <div className='mb-4'>
                 {isPermission('storage.post') && <Row justify="space-between">
                    <Col>
                          <Space size="middle">
                            <Upload
                                headers={{
                                    authorization: 'Bearer ' + token,
                                }}
                                multiple
                                maxCount={24}
                                fileList={fileList}
                                showUploadList={false}
                                accept='.bmp,.jpg,.jpeg,.png,.tiff,.gif,.svg,.webp,.mp4,.avi,.mov,.wmv,.asf,.navi,.3gp,.mkv,.f4v,.rmvb,.webm,.txt,.doc,.docx,.xls,.rtf,.wpd,.pdf,.ppt,.csv,.pptx.mp3,.wma,.wav, mod,.ra,.cd,.md,.asf,.aac,.vqf,.ape,.mid,.ogg,.m4a,.vqf,.zip,.rar,.7z,.bz'
                                action={UploadApi}
                                onChange={uploadChange}
                                data={getExtraData}
                                beforeUpload={beforeUpload}
                            >
                                <Button
                                    type="primary"
                                    icon={<VerticalAlignTopOutlined/>}
                                >
                                    上传文件
                                </Button>
                            </Upload>
                            <Button
                                type="dashed"
                                icon={<FolderAddOutlined/>}
                                onClick={onCreate}
                            >
                                新建文件夹
                            </Button>
                        </Space>
                    </Col>

                    <Col>
                        <Space size='large'>
                            <Radio.Group defaultValue={0} value={selectType} optionType="button" buttonStyle="solid"
                                         onChange={onSelectType}>
                                <Radio.Button value={0}>全部</Radio.Button>
                                <Radio.Button value={1}>图片</Radio.Button>
                                <Radio.Button value={2}>视频</Radio.Button>
                                <Radio.Button value={3}>文档</Radio.Button>
                                <Radio.Button value={4}>音乐</Radio.Button>
                            </Radio.Group>
                            <Input.Search placeholder="搜索我的文件" allowClear enterButton onSearch={onSearch}/>
                            {isPermission('storage.post') && <Popover
                                placement="bottomRight"
                                content={
                                    <div className='storage-upload-list'>
                                        <List
                                            split
                                            dataSource={fileList}
                                            renderItem={(item, index) => {

                                                return (
                                                    <List.Item
                                                        key={index}
                                                        actions={[<Button type="dashed" shape="circle" size='small'
                                                                          onClick={() => onDeleteFileList(item)}
                                                                          icon={<CloseOutlined/>}/>]}
                                                    >
                                                        <List.Item.Meta
                                                            avatar={<img src={getIcon(item.name, true)}
                                                                         alt='img'
                                                                         style={{
                                                                             width: 38,
                                                                             height: 38,
                                                                             marginTop: 7
                                                                         }}/>}
                                                            title={<Text ellipsis
                                                                         style={{marginTop: 0}}>{item.name}</Text>}
                                                            description={<Progress
                                                                status={item.status === 'error' ? 'exception' : undefined}
                                                                style={{marginInlineEnd: 0, marginBottom: 0}}
                                                                percent={parseInt(item.percent?.toString() || '0', 10)}
                                                                size="small"/>}
                                                        />
                                                    </List.Item>
                                                )
                                            }}

                                        />
                                    </div>}
                                title='上传列表'
                                trigger="click"
                                open={uploadOpen}
                                onOpenChange={(e) => setUploadOpen(e)}
                            >
                                <Badge size="small" count={getUploadCount}>
                                    <Button
                                            type={uploadOpen ? 'primary' : 'dashed'} shape="circle"
                                            icon={<UploadOutlined/>}/>
                                </Badge>
                            </Popover>}
                        </Space>
                    </Col>
                </Row>}
                <div className='my-3'>
                    <Space>
                        {storageRouter.map((item: any, index: number) => {
                            const isRoot = storageRouter.length  === index + 1;
                            return (
                                <div key={index}> {isRoot ? <Text type='secondary'>{subText(item.title, 8)}</Text> : <Space align="center"><Link onClick={() => onRouterChange(item, index)}>{subText(item.title, 8)}</Link><RightOutlined className='mx-2' /></Space>}</div>
                            )
                        })}
                    </Space>
                </div>
                {isPermission('storage.post') &&  <Space size='small'>
                    <Button icon={<BarsOutlined/>} size='small'
                            disabled={data.length === 0 || loading }
                            onClick={onSelectAll}>全选</Button>
                    <Button className='ms-2' icon={<SwapOutlined/>} onClick={() => fileMoveRef.current.onOpen()}
                            size='small'
                            disabled={selectData.length === 0 || loading }>移动</Button>
                    <Button className='ms-2' icon={<DeleteOutlined/>} size='small' onClick={showDeleteConfirm}
                            disabled={selectData.length === 0 || loading }>删除</Button>
                    <Text type='secondary'> 已选择{selectData.length}项</Text>
                </Space>}
            </div>
            <Spin size="large" spinning={loading}>
                <div onScroll={onRefresh} className='storage-content pe-3' style={{height: "calc(100vh - 400px)"}}
                     onContextMenu={onMenu}>
                    <Form form={form} component={false}>
                        {data.length === 0 && !loading && <Empty  image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        <Flex wrap="wrap" gap="middle">
                            {data.map((item, index) => {
                                const isChecked = isSelect(item);
                                let sClass = item.cache ? 'storage-create' : 'storage';
                                sClass += isChecked ? ' storage-checked' : '';
                                sClass +=  algorithm ? ' storage-dark ' + sClass : '';
                                return (
                                    <div style={{width: 205}} className={sClass}
                                         id={`${item.id}`} key={item.id} onContextMenu={(e) => onItemMenu(e, item)}>
                                        <div className='storage-header'>
                                            {isPermission('storage.post') && <div className='storage-item-edit'>
                                                <Row justify="space-between">
                                                    <Col>
                                                        {!item.cache && editId !== item.id &&
                                                            <Checkbox checked={isChecked}
                                                                      onChange={() => onSelectChange(item)}/>}
                                                    </Col>
                                                    <Col>
                                                        {editId === item.id || item.cache ?
                                                            <Space size='small'>
                                                                <CloseCircleFilled onClick={deleteCacheFile}/>
                                                                <CheckCircleFilled
                                                                    onClick={() => item.cache ? onFinish(item, index) : onUpdate(item, index)}/>
                                                            </Space> :
                                                            <EllipsisOutlined style={{fontSize: 18}}
                                                                              onClick={(e) => onItemMenu(e, item)}/>}
                                                    </Col>
                                                </Row>
                                            </div>}
                                        </div>
                                        <div className='storage-content-item' style={{height: 192}}
                                             onClick={() => onPreviewType(item)}>
                                            <Flex style={{height: 84}} justify='center' align='center' className='text-center mb-2'>
                                                {(() => {
                                                    if (item.type === 1) {
                                                        return <img
                                                            alt='img'
                                                            src={item.url + "@240X240_r.jpg"}
                                                            className='w-auto h-auto mh-100 mw-100'/>
                                                    }
                                                    if (item.type === 2) {
                                                        return (<div style={{height: 84}} className='position-relative'>
                                                            <div className='position-absolute d-table w-100 h-100'>
                                                                <PlayCircleFilled style={{fontSize: 18}}
                                                                                  className="d-table-cell align-middle text-white opacity-75"/>
                                                            </div>
                                                            <img alt='img' src={item.url + "@thumb.jpg"}  className='w-auto h-auto mh-100 mw-100'/>
                                                        </div>)
                                                    }
                                                    return <img alt='img' src={getIcon(item.mini_type)}
                                                                style={{width: 64, height: 64}}
                                                                className='align-middle'/>
                                                })()}
                                            </Flex>

                                            {editId === item.id || item.cache ?
                                                <Form.Item
                                                    name="title"
                                                    style={{margin: 0}}
                                                    initialValue={item.title}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '文件夹名称必须填写',
                                                        },
                                                    ]}
                                                >
                                                    <Input onClick={(e) => e.stopPropagation()} value={item.title}
                                                           autoFocus/>
                                                </Form.Item>
                                                : <Paragraph style={{marginBottom: 0}}
                                                             ellipsis={{rows: 2}}>{item.title}</Paragraph>}
                                            <Text className='d-block' style={{fontSize: 10}}
                                                  type={"secondary"}>{item.mini_type === 'file' ? dayjs(item.created_at).format('YYYY-MM-DD') : formatFileSize(item.size)}</Text>

                                        </div>
                                    </div>
                                )
                            })}


                        </Flex>

                        {!loading && !hasMore && data.length >= perPage ?
                            <div className='text-center py-4'><Spin size="small"/></div> : null}

                    </Form>
                </div>
            </Spin>
            <Modal width={980} centered maskClosable={true} open={videoOpen} footer={null}
                   onCancel={() => setVideoOpen(false)}>
                <div>
                    <video controls autoPlay width="100%" height={480}/>
                </div>
            </Modal>

            <Menu id='itemId' className={`${algorithm ? 'contexify-dark' : 'contexify'}`}>
                {isPermission('storage.post') &&
                    <Item id="move" onClick={onItemClick}><Space size='middle'><SwapOutlined/>移动</Space></Item>}
                {isPermission('storage.post') &&
                    <Item id="remove" onClick={onItemClick}><Space size='middle'><DeleteOutlined/>删除</Space></Item>}
                <Item id="download" onClick={onItemClick}><Space
                    size='middle'><CloudDownloadOutlined/>下载</Space></Item>
                <Separator/>
                {isPermission('storage.post') &&
                    <Item id="edit" onClick={onItemClick}><Space size='middle'><EditOutlined/>重命名</Space></Item>}
                <Item id="open" onClick={onItemClick}><Space size='middle'><FolderOpenOutlined/>打开</Space></Item>
            </Menu>

            <Menu id='menuId'>
                <Item id="file" onClick={onItemClick}><Space size='middle'><FolderAddOutlined/>创建文件夹</Space></Item>
                <Item id="select" disabled={data.length === 0} onClick={onItemClick}><Space
                    size='middle'><VerticalAlignTopOutlined/>选择全部</Space></Item>
                <Separator/>
                <Item id="refresh" onClick={onItemClick}><Space size='middle'><SyncOutlined/>刷新</Space></Item>
            </Menu>

            <Image
                width={200}
                className='d-none'
                src={previewUrl || ''}
                preview={{
                    visible: previewOpen,
                    src: previewUrl || '',
                    onVisibleChange: (value) => {
                        setPreviewUrl(null)
                        setPreviewOpen(value);
                    },
                }}
            />
            <Video autoPlay={true} ref={playerRef}/>
            <FileMove ref={fileMoveRef} onChange={onMove}/>
        </Card>
    )
}
export default Storage;
