/**
 * Notes: 管理员权限
 * User: 李强
 * DateTime: 2021/12/8 15:47
 * Ide: PhpStorm
 */


import React, {useState, useRef, useEffect} from 'react'
import {Button, message,Form, Input, Table, Popconfirm, Space} from "antd";
import {DeleteOutlined , EditOutlined, CheckOutlined, CloseOutlined} from "@ant-design/icons";
import type { ColumnsType } from 'antd/es/table';
import {useRecoilState} from "recoil";
import {articleCategoryState} from "../../../store";
import {useHttps, usePermission} from "../../../hooks";

const ArticleCategory = () => {
    const [category, setCategory] = useRecoilState(articleCategoryState);
    const [loading, setLoading] = useState(true)
    const [sendLoading, setSendLoading] = useState(false)
    const {put, destroy, post, get} = useHttps();
    const [editItem, setEditItem] = useState(0)
    const formRef = useRef<any>();
    const editFormRef = useRef<any>();
    const {isPermission} = usePermission();

    /**
     * 分类数据
     * @param {Object} params
     */

    useEffect(() => {
        get('admin/article/category', null, true)
            .then((res:Request.Response<ArticleType.Category[]>) => {
                if (res.mounted) {
                    setLoading(false)
                    setCategory(res.data);
                }
            })
            .catch(async (e:Request.Error) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message)
                }
            });
    }, [get, setCategory]);



    /**
     * 更新权限
     */

    const onUpdate = async (params:ArticleType.Category) => {

        try {
            let values = await editFormRef.current.validateFields();
            values.id = params.id;
            values.count = params.count
            const dataSource = [...category];
            const select: number = dataSource.findIndex((v) => v.id === params.id);
            dataSource[select] = values;
            setCategory(dataSource)
            setLoading(true)
            put('admin/article/category/' + params.id, values)
                .then((res) => {
                    if (res.mounted) {
                        setLoading(false)
                        setEditItem(0)
                        message.success('分类已更新！');
                        editFormRef.current.setFieldsValue({name: '', url: '', slug: ''});
                    }
                })
                .catch((e) => {
                    if (e.mounted) {
                        setLoading(false)
                        message.error(e.message);
                    }
                });
        }catch (err) {
             return;
      }
    };

    /**
     * 删除权限
     * @param id
     */


    const onDelete = (id:number) => {
        setLoading(true)
        destroy('admin/article/category/' + id)
            .then(async (res) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('删除分类成功！');
                    const dataSource = [...category];
                    const select = dataSource.findIndex((value) => value.id === id);
                    dataSource.splice(select, 1);
                    setCategory(dataSource)
                }
            })
            .catch(async (e) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    }

    /**
     * 添加权限
     * @param params
     */


    const onFinish = (params:[index:any]) => {
        setSendLoading(true)
        post('admin/article/category', params)
            .then(async ( res:Request.Response<ArticleType.Category>) => {
                if (res.mounted) {
                    setSendLoading(false)
                    message.success('分类添加成功！');
                    const dataSource = [...category];
                    dataSource.unshift(res.data);
                    setCategory(dataSource)
                    formRef.current.setFieldsValue({name: '',url: '', slug: ''})
                }

            })
            .catch(async (e:Request.Error) => {
                if (e.mounted) {
                    setSendLoading(false)
                    message.error(e.message);
                }
            });
    }



    /**
     * 表格设置
     * @param item
     */

    const columns:ColumnsType<ArticleType.Category> = [

        {
            title: 'ID',
            dataIndex: 'id',
            width: 100,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.id -b.id,
            render: (text) => {
                return (
                    <div>
                    <span>{text}</span>
                    </div>
                )

            },
        },
        {
            title: '分类名称',
            dataIndex: 'name',
            width: 180,
            render: (text, item) => {
                return (
                    <div>
                        {editItem === item.id ?
                            <Form.Item
                                name='name'
                                style={{ margin: 0 }}
                                rules={[
                                    {
                                        required: true,
                                        message: `请输入分类名称`,
                                    },
                                ]}
                            >
                                <Input  maxLength={8} />
                            </Form.Item>

                            : <div>{text}</div>}
                    </div>
                )

            },
        },
        {
            title: '操作',
            key: 'action',
            width: 100,
            hidden:!isPermission('article.post'),
            render: (key, item) => {
                return (editItem !== item.id ?
                            <Space>
                            <Button
                                onClick={()=> {
                                editFormRef.current.setFieldsValue({ name: item.name});
                                setEditItem(item.id)
                            }} type="dashed" size='small' icon={<EditOutlined />}/>
                                <Popconfirm title="删除分类及分类下文章，你确定吗?" onConfirm={() => onDelete(item.id)}>
                                    <Button type="dashed" size='small' icon={<DeleteOutlined/>}/>
                                </Popconfirm>
                            </Space> :
                            <Space>
                                <Button type="primary" danger size='small' onClick={()=> {
                                    editFormRef.current.setFieldsValue({ name: item.name});
                                    setEditItem(0)
                                }} icon={<CloseOutlined />}/>
                                <Button type="primary" size='small' onClick={()=> onUpdate(item)} icon={<CheckOutlined />}/>
                            </Space>
                )
            }
        },
    ];




    return (
        <div>
            {isPermission('article.post')  &&  <div className='mb-4'>
                    <Form
                        layout='inline'
                        initialValues={{description: ''}}
                        ref={formRef}
                        name="basic"
                        onFinish={onFinish}
                    >
                        <Form.Item label="分类名称" name="name" rules={[{required: true, message: '请输入分类名称！'}]}>
                            <Input maxLength={8} placeholder="请输入分类名称"/>
                        </Form.Item>
                        <Form.Item>
                            <Button disabled={!isPermission('article.post')} loading={sendLoading} className='me-3' type="primary" htmlType="submit">
                                添加
                            </Button>
                        </Form.Item>
                    </Form>
            </div> }
            <div className='p-2' style={{width:780}}>
                <Form  ref={editFormRef} component={false}>
                        <Table
                            loading={loading}
                            rowKey={(record) => record.id}
                            columns={columns}
                            dataSource={category}
                            pagination={{
                                total: category.length,
                                defaultCurrent: 1,
                                showTotal: total => `总计 ${total} 个分类`,
                            }}/>
                </Form>
            </div>
        </div>
    )
}
export default ArticleCategory;
