import React, {forwardRef, useImperativeHandle, useState} from 'react'

import {Form, Input, Radio, Spin, Modal, App, InputNumber, DatePicker} from "antd";
import {FileSelect} from "../../components";
import {useDict, useHttps} from "../../hooks";
import dayjs from "dayjs";

const Create = forwardRef((props: { onChange: (e: ActivityType.Data) => void; }, ref) => {
    const {message} = App.useApp();
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const {post} = useHttps();
    const [form] = Form.useForm();
    const {getDict} = useDict();

    useImperativeHandle(ref, () => ({
        onOpen: () => {
            setOpen(true)
        },
    }));


    const onFinish = async () => {
        try {
            let params = await form.validateFields();
            params.start_date = dayjs(params.date[0]).format('YYYY-MM-DD HH:mm:ss');
            params.end_date = dayjs(params.date[1]).format('YYYY-MM-DD HH:mm:ss');
            setLoading(true)
            post('admin/activity', params)
                .then(async (res: Request.Response<ActivityType.Data>) => {
                    if (res.mounted) {
                        setLoading(false)
                        props.onChange(res.data)
                        setOpen(false)
                    }

                })
                .catch(async (e) => {
                    if (e.mounted) {
                        setLoading(false)
                        message.error(e.message);
                    }
                });
        } catch (err) {
            return;
        }
    }

    const onCancel = () => {
        setOpen(false)
    }


    return (
        <Modal destroyOnClose title="添加活动" width={960} okText='添加' open={open} onOk={onFinish} centered
               onCancel={onCancel} okButtonProps={{disabled: loading}}>
            <Spin size="small" spinning={loading}>

                <Form
                    preserve={false}
                    form={form}
                    labelCol={{span: 4}}
                    wrapperCol={{span: 18}}
                    name="basic"
                    initialValues={{
                        status: '1',
                        title: "",
                        thumb: "",
                        description: '',
                        total: 10
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="活动标题"
                        name="title"
                        rules={[{required: true, message: '请输入标题！'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="封面图"
                        name='thumb'
                        extra="上传尺寸建议980x460"
                        className="mt-3"
                        rules={[{required: true, message: '请选择缩略图！'}]}
                    >
                        <div className='w-100'>
                            <FileSelect
                                listType='picture-card'
                                text='上传封面'
                                size={1}
                                type={1}
                                onChange={(e) => {
                                    form.setFieldsValue({thumb: e[0]?.url || ''})
                                }}
                            />
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="活动地点"
                        name="address"
                        rules={[{required: true, message: '请输入活动地点！'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="报名人数"
                        name="total"
                        rules={[{required: true, message: '请输入报名人数！'}]}
                    >
                        <InputNumber min={1}/>
                    </Form.Item>

                    <Form.Item
                        label="活动日期"
                        name="date"
                        rules={[{required: true, message: '请输入活动日期！'}]}
                    >
                        <DatePicker.RangePicker showTime/>
                    </Form.Item>
                    <Form.Item name='content' label="活动介绍">
                        <Input.TextArea/>
                    </Form.Item>

                    <Form.Item name='description' label="报名规则">
                        <Input.TextArea/>
                    </Form.Item>
                    <Form.Item
                        name="status"
                        label="是否显示"
                    >
                        <Radio.Group>
                            {getDict('is_show').map((item, index) => <Radio key={index}
                                                                            value={item.value}>{item.label}</Radio>)}
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
})
export default Create;
