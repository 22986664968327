import {atom, selector} from "recoil";
import {getTreeData} from "../utils/utils";


/**
 * 侧边栏菜单
 */
const algorithmState = atom<boolean>({
    key: 'algorithm',
    default:(() => {
      const isAlgorithm = localStorage.getItem("algorithm")
      return isAlgorithm != null
    })(),
});


/**
 * 用户token
 */
const tokenState = atom<string | null>({
    key: 'tokenState',
    default: (() => sessionStorage.getItem("token"))(),
});


/**
 * 树菜单
 */
const treeMenuState = selector({
    key: 'treeMenuState',
    get: ({get}) => {
        const dataSource = get(permissionState);
        return getTreeData(dataSource, 0)
    },
});


/**
 * 部门
 */
const departmentState = atom<SystemType.Department[]>({
    key: 'departmentState',
    default: [],
});

/**
 * 树部门
 */
const treeDepartmentState = selector({
    key: 'treeDepartmentState',
    get: ({get}) => {
        const dataSource = get(departmentState);
        return getTreeData(dataSource, 0)
    },
});


/**
 * 用户信息
 */
const authState = atom<AuthType.Info | null>({
    key: 'authState',
    default: (() => {
        const data = sessionStorage.getItem("auth");
        if (data) {
            const getUser = JSON.parse(data);
            if (typeof getUser === 'object') {
                return getUser;
            }
        }
        return null;
    })(),
});

/**
 * 获取用户权限
 */
const roleState = selector<{ roles: string[], permission: string[], menus: SystemType.Permission[] }>({
    key: 'roleState',
    get: ({get}) => {
        let dataSource = {
            roles: [],
            permission: [],
            menus: []
        }
        const token = get(tokenState);
        if (!token) {
            return dataSource;
        }
        const data = sessionStorage.getItem("role");
        if (data) {
            const getRoles = JSON.parse(data);
            if (typeof getRoles === 'object') {
                let roles: string[] = [];
                let permission: string[] = []
                let menus: SystemType.Permission[] = [];
                let isMenu: { [index: string]: boolean | undefined } = {};
                //遍历角色
                for (let r = 0; r < getRoles.length; r++) {
                    if (!roles.includes(getRoles[r].slug)) {
                        roles.push(getRoles[r].slug)
                    }
                    //遍历权限
                    for (let p = 0; p < getRoles[r].permission.length; p++) {
                        if (!permission.includes(getRoles[r].permission[p].slug)) {
                            permission.push(getRoles[r].permission[p].slug)
                        }
                        if (!isMenu[getRoles[r].permission[p].url]) {
                            isMenu[getRoles[r].permission[p].url] = true
                            menus.push(getRoles[r].permission[p])
                        }

                    }
                }
                return {...dataSource, ...{roles, permission, menus}}
            }
        }
        return dataSource;
    }
});




/**
 * 字典列表
 */
const dictTypeState = atom<SystemType.DictType | null>({
    key: 'dictTypeState',
    default: (() => {
        const data = localStorage.getItem("dictType");
        if (data) {
            const getDictType = JSON.parse(data);
            if (typeof getDictType === 'object') {
                return getDictType;
            }
        }
        return null;
    })(),
    dangerouslyAllowMutability:true
});


/**
 * 角色列表
 */
const rolesState = atom<SystemType.Roles[]>({
    key: 'rolesState',
    default: [],
});

/**
 * 权限列表
 */
const permissionState = atom<SystemType.Permission[]>({
    key: 'permissionState',
    default: [],
});




const websocketState = atom<{ key: number; value: string; }>({
    key: 'websocketState',
    default: {key:0 ,value: '正在连接中' }
});
const websocketDataState = atom<any>({
    key: 'websocketDataState',
    default: {action:'none'}
});
const downloadState  = atom<any>({
    key: 'downloadState',
    default: [],
    dangerouslyAllowMutability:true
});

const userTagState = atom<UserType.Tag[]>({
    key: 'userTagState',
    default: []
});

/**
 * 文章分类
 */
const articleCategoryState = atom<ArticleType.Category[]>({
    key: 'articleCategoryState',
    default: [],
});

export {
    userTagState,
    downloadState,
    websocketState,
    websocketDataState,
    treeMenuState,
    tokenState,
    authState,
    algorithmState,
    rolesState,
    permissionState,
    roleState,
    treeDepartmentState,
    departmentState,
    dictTypeState,
    articleCategoryState
};
