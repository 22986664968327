import {useRef, useEffect} from 'react'
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {websocketState, websocketDataState, tokenState} from "../store";
import {WebSocketApi} from "../config/Config";
const useWebsocket = () => {
    const token = useRecoilValue<string | null>(tokenState);

    const ws = useRef<WebSocket | null>(null)
    // socket 数据
    const setWebsocketData = useSetRecoilState(websocketDataState);
    //  socket 状态
    const [readyState , setReadyState] = useRecoilState(websocketState);

    const creatWebSocket = () => {
        const stateArr = [
            {key: 0, value: '连接中'},
            {key: 1, value: '已连接'},
            {key: 2, value: '关闭中'},
            {key: 3, value: '未连接'},
        ]
        try {
            ws.current = new WebSocket(WebSocketApi + '/' + token)
            ws.current.onopen = () => {
                setReadyState(stateArr[ws.current?.readyState ?? 0])
            }
            ws.current.onclose = () => {
                setReadyState(stateArr[ws.current?.readyState ?? 0])
            }
            ws.current.onerror = () => {
                setReadyState(stateArr[ws.current?.readyState ?? 0])
            }
            ws.current.onmessage = (e) => {
                setWebsocketData({...JSON.parse(e.data)})
            }

        } catch (error) {
            console.log(error)
        }
    }

    //关闭浏览器
    useEffect(()=>{
        if(readyState.key === 3){
            setTimeout(()=> reconnect() , 3000)
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[readyState])


    const webSocketInit = () => {
        if (!ws.current || ws.current.readyState === 3) {
            creatWebSocket()
        }
    }

    //  关闭 WebSocket
    const closeWebSocket = () => {
        ws.current?.close()
    }

    // 发送数据
    const sendMessage = (str: string) => {
        ws.current?.send(str)
    }

    //重连
    const reconnect = () => {
        try {
            closeWebSocket()
            ws.current = null
            creatWebSocket()
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        webSocketInit()
        return () => {
            ws.current?.close()
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ws])


    return {
        closeWebSocket,
        reconnect,
        sendMessage,
    }
}
export default useWebsocket