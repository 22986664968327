import {useRecoilValue} from "recoil";
import {roleState} from "../store";


const usePermission = () => {

    const { roles, permission, menus }= useRecoilValue<any>(roleState)

    /**
     * 验证权限
     * @param slug
     */
    const isPermission = (slug: string): boolean => {
        return permission.includes(slug as any);
    }


    /**
     * 验证角色
     * @param slug
     */
    const isRole = (slug: string): boolean => {
        return roles.includes(slug as any);
    }



    return {isPermission, isRole, roles, permission, menus};
}

export default usePermission;