/**
 * Notes: 个人资料
 * User: 李强
 * DateTime: 2021/12/10 10:29
 * Ide: PhpStorm
 */

import React, {forwardRef, useImperativeHandle, useState} from 'react'
import {
    Descriptions,
    message,
    Modal,
    Divider,
    Image,
    Spin,
    Button
} from 'antd';

import {useHttps} from "../../hooks";
import dayjs from "dayjs";
import {ExclamationCircleOutlined} from "@ant-design/icons";


/*
  用户管理
*/
const Account = forwardRef((props: { onChange?: (e: UserType.Data) => void; }, ref) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<ArchivesType.Data | null>(null)
    const [loading, setLoading] = useState(false)
    const {get, put, destroy} = useHttps();


    /**
     * 获取数据
     * @param id
     */

    const getData = (id: number) => {
        setLoading(true)
        get('admin/archives/' + id, null, true)
            .then((res: Request.Response<ArchivesType.Data>) => {
                if (res.mounted) {
                    setData(res.data);
                    setLoading(false)
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    setOpen(false)
                    message.error(e.message)
                }
            });
    };



    useImperativeHandle(ref, () => ({
        onOpen: (id: number) => {
            getData(id)
            setOpen(true)

        },
    }));


    /**
     * 关闭模态框
     */
    const onCancel = () => {
        setLoading(false)
        setOpen(false)
    }


    const onUpdate = (status:string) => {
        setLoading(true);
        put('admin/archives?status='+ status , [data?.id])
            .then(async (res) => {
                if (res.mounted) {
                    setLoading(false)
                    if(data){
                        message.success("状态更新成功")
                        setData( {...data, ...{status:status}})
                    }
                }
            })
            .catch(async (e:Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    }

    /**
     * 操作提示信息
     */
    const showConfirm = () => {
        Modal.confirm({
            centered: true,
            icon: <ExclamationCircleOutlined/>,
            content: <div>确定要删除此信息吗？</div>,
            onOk() {
                onDelete()
            },
            onCancel() {
                Modal.destroyAll()
            },
        });
    }


    /**
     * 删除信息
     */
    const onDelete = () => {
        setLoading(true);
        destroy('admin/archives', [data?.id])
            .then((res) => {
                if (res.mounted) {
                    setLoading(false)
                    setOpen(false)
                }
            })
            .catch(async (e:Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message);
                }
            });
    }


    return (
        <Modal destroyOnClose width={1100} open={open}   footer={(_, { OkBtn, CancelBtn }) => (
            <>
                <Button disabled={data?.status === '0'} onClick={() => onUpdate('0')} className='me-2'
                        type='primary' danger>
                    撤回报道
                </Button>
                <Button disabled={data?.status === '2'} onClick={() => onUpdate('2')}>
                    通过申请
                </Button>
                <Button danger onClick={showConfirm}>
                    删除
                </Button>
                <CancelBtn />
            </>
        )} centered onCancel={onCancel} >
            <Spin spinning={loading}>
                <div>
                    <Descriptions title="申请信息">
                        <Descriptions.Item label="真实姓名">{data?.name}</Descriptions.Item>
                        <Descriptions.Item label="性别">{data?.gender === '1' ? '男' : '女'}</Descriptions.Item>
                        <Descriptions.Item
                            label="出生日期">{dayjs(data?.birth).format('YYYY-MM-DD')}</Descriptions.Item>
                        <Descriptions.Item label="毕业学校">{data?.school ? data?.school : '未填写'}</Descriptions.Item>
                        <Descriptions.Item
                            label="专业班级">{data?.classes ? data?.classes : '未填写'}</Descriptions.Item>
                        <Descriptions.Item label="联系电话">{data?.tel}</Descriptions.Item>
                        <Descriptions.Item
                            label="身份证号">{data?.card_id ? data?.card_id : '未填写'}</Descriptions.Item>

                        <Descriptions.Item label="是否留在大连">{data?.is_dalian ? '是' : '否'}</Descriptions.Item>
                        <Descriptions.Item
                            label="是否能做到下列承诺">{data?.is_promise ? '是' : '否'}</Descriptions.Item>
                        <Descriptions.Item label="是否能线下报道">{data?.is_live ? '是' : '否'}</Descriptions.Item>
                        <Descriptions.Item span={2}
                                           label="“智慧团建”线上转接是否已经申请并且转出单位已审批">{data?.is_wisdom ? '是' : '否'}</Descriptions.Item>
                        <Descriptions.Item
                            label="团组织名称">{data?.group_name ? data?.group_name : '未填写'}</Descriptions.Item>
                        <Descriptions.Item
                            label="政治面貌">{data?.politics ? data?.politics : '未填写'}</Descriptions.Item>
                        <Descriptions.Item
                            label="是否需要党关系转接">{data?.is_transfer ? '是' : '否'}</Descriptions.Item>
                        <Descriptions.Item label="民族">{data?.minority ? data?.minority : '未填写'}</Descriptions.Item>
                        <Descriptions.Item label="现阶段状态">{data?.works ? data?.works : '未填写'}</Descriptions.Item>
                        <Descriptions.Item
                            label="单位名称">{data?.company ? data?.company : '未填写'}</Descriptions.Item>
                        <Descriptions.Item label="考取意向">{data?.ideal ? data?.ideal : '未填写'}</Descriptions.Item>
                        <Descriptions.Item
                            label="考取学院">{data?.ideal_school ? data?.ideal_school : '未填写'}</Descriptions.Item>
                        <Descriptions.Item
                            label="毕业年份">{data?.graduation ? data?.graduation : '未填写'}</Descriptions.Item>
                        <Descriptions.Item
                            label="紧急联系人姓名">{data?.sos_name ? data?.sos_name : '未填写'}</Descriptions.Item>
                        <Descriptions.Item
                            label="紧急联系人电话">{data?.sos_tel ? data?.sos_tel : '未填写'}</Descriptions.Item>
                        <Descriptions.Item
                            label="紧急联系人关系">{data?.sos_relation ? data?.sos_relation : '未填写'}</Descriptions.Item>
                    </Descriptions>
                    <Divider/>
                    <Descriptions title="附件信息">

                        <Descriptions.Item label="介绍信照片">
                            {data?.letter_files && <div className='d-flex flex-wrap'>
                                {data?.letter_files.map((tag, index) => {
                                    return (
                                        <div className='ms-2'>
                                            <Image
                                                style={{objectFit:'cover'}}
                                                key={index}
                                                width={100}
                                                height={100}
                                                src={tag.url}
                                            /></div>
                                    );
                                })}
                            </div>
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="近期免冠照片">
                            {data?.photo && <div className='d-flex flex-wrap'>
                                {data?.photo.map((tag, index) => {
                                    return (
                                        <Image
                                            style={{objectFit:'cover'}}
                                            key={index}
                                            width={100}
                                            height={100}
                                            src={tag.url}
                                        />
                                    );
                                })}
                            </div>
                            }
                        </Descriptions.Item>

                        <Descriptions.Item label="签名">
                            <div className='ms-2'>
                                <Image
                                    style={{objectFit:'cover'}}
                                    width={100}
                                    height={100}
                                    src={data?.sign}
                                /></div>
                        </Descriptions.Item>
                    </Descriptions>
                </div>

            </Spin>
        </Modal>

    )
})

export default Account

