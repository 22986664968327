import React, {useState,useImperativeHandle, forwardRef} from 'react'
import {
    Form,
    Input,
    Radio,
    Spin,
    Modal, App,
} from "antd";

import {useDict, useHttps, usePermission} from "../../../hooks";
import Editor from "../../../components/editor";



const Edit = forwardRef((props: { onChange: (e: SystemType.Notice) => void; }, ref) => {
    const { message } = App.useApp();
    const [id, setId] = useState(0);
    const {put, get} = useHttps();
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState('');
    const [form] = Form.useForm()
    const {getDict} = useDict();
    const {isPermission} = usePermission();

    const getData = (id: number) => {
        setLoading(true)
        get('admin/notice/' + id, null, true)
            .then((res: Request.Response<SystemType.Notice>) => {
                if (res.mounted) {
                    setLoading(false);
                    form.setFieldsValue(res.data)
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message)
                    setOpen(false)
                }

            });
    };


    useImperativeHandle(ref, () => ({
        onOpen: (id: number) => {
            setId(id)
            setOpen(true)
            getData(id)
        },
    }));


    /**
     * 更新内容内容
     */
    const onUpdate = async () => {
        try {
            let params = await form.validateFields();
            setLoading(true)
            params.id = id;
            put('admin/notice/' + id, params)
                .then(async (res: Request.Response<SystemType.Notice>) => {
                    if (res.mounted) {
                        setLoading(false)
                        props.onChange(res.data)
                        setOpen(false)
                    }
                })
                .catch(async (e: Request.Error) => {
                    if (e.mounted) {
                        setLoading(false)
                        message.error(e.message);
                    }
                });
        } catch (err) {
            return;
        }
    }


    /**
     * 关闭模态框
     */
    const onCancel = () => {
        setLoading(false)
        setOpen(false)
    }

    return (
        <Modal destroyOnClose title="编辑内容" width={1100} okText='保存' open={open} onOk={onUpdate} centered
               onCancel={onCancel} footer={!isPermission('notice.post') ? null : undefined} okButtonProps={{ disabled: loading }}>
            <Spin size="small" spinning={loading}>
                        <Form
                            disabled={!isPermission('notice.post')}
                            form={form}
                            labelCol={{span: 4}}
                            wrapperCol={{span: 16}}
                            name="basic_notice_edit"
                            onFinish={onUpdate}
                        >
                            <Form.Item
                                label="内容标题"
                                name="title"
                                rules={[{required: true, message: '请输入标题！'}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name="type"
                                label="选择类型"
                            >
                                <Radio.Group>
                                    {getDict('notice').map((item, index) => <Radio key={index}
                                                                                   value={item.value}>{item.label}</Radio>)}
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="内容详情" name="content"
                                       rules={[{
                                           required: true,
                                           message: '请输入20字符以上内容。！',
                                           validator: async (rule, value) => {
                                               if (value.length <= 20) {
                                                   return Promise.reject()
                                               }
                                           }
                                       }
                                       ]}
                            >
                                <Editor
                                    height={280}
                                    disabled={!isPermission('notice.post')}
                                    value={content}
                                    onChange={(val) => {
                                        setContent(val)
                                        form.setFieldsValue({content: val})
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="status"
                                label="是否显示"
                            >
                                <Radio.Group>
                                    {getDict('is_show').map((item, index) => <Radio key={index} value={item.value}>{item.label}</Radio>)}
                                </Radio.Group>
                            </Form.Item>
                        </Form>
            </Spin>
        </Modal>
    )
})
export default Edit;
