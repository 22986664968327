import {useRecoilValue} from "recoil";
import {dictTypeState} from "../store";
import {useCallback} from "react";



const useDict = () => {
    
    const dictType = useRecoilValue(dictTypeState);

    /**
     * 根据类型获取字典数组
     * @param type
     */
     const getDict = useCallback((type:string) => {
         if(dictType){
             return dictType[type]  ||  [];
         }
       return [];
     },[dictType])



    /**
     * 获取字段数组值
     * @param type
     * @param value
     */
    const getDictValue  = useCallback((type:string, value:number|string) => {
        if(dictType) {
            const data = dictType[type] || [];
            return data.find((item) => item.value === value + '')
        }

        return null;
    },[dictType])



    return {getDict , getDictValue}
}

export default useDict;