import React from 'react';
import * as Icons from '@ant-design/icons';


const Icon: { [key:string]:any } = {

    AccountBookOutlined: <Icons.AccountBookOutlined />,
    AimOutlined: <Icons.AimOutlined />,
    AlertOutlined: <Icons.AlertOutlined />,
    AlibabaOutlined: <Icons.AlibabaOutlined />,
    AlignCenterOutlined: <Icons.AlignCenterOutlined />,
    AlignLeftOutlined: <Icons.AlignLeftOutlined />,
    AlignRightOutlined: <Icons.AlignRightOutlined />,
    AlipayCircleOutlined: <Icons.AlipayCircleOutlined />,
    AlipayOutlined: <Icons.AlipayOutlined />,
    AliwangwangOutlined: <Icons.AliwangwangOutlined />,
    AliyunOutlined: <Icons.AliyunOutlined />,
    AmazonOutlined: <Icons.AmazonOutlined />,
    AndroidOutlined: <Icons.AndroidOutlined />,
    AntCloudOutlined: <Icons.AntCloudOutlined />,
    AntDesignOutlined: <Icons.AntDesignOutlined />,
    ApartmentOutlined: <Icons.ApartmentOutlined />,
    ApiOutlined: <Icons.ApiOutlined />,
    AppleOutlined: <Icons.AppleOutlined />,
    AppstoreAddOutlined: <Icons.AppstoreAddOutlined />,
    AppstoreOutlined: <Icons.AppstoreOutlined />,
    AreaChartOutlined: <Icons.AreaChartOutlined />,
    ArrowDownOutlined: <Icons.ArrowDownOutlined />,
    ArrowLeftOutlined: <Icons.ArrowLeftOutlined />,
    ArrowRightOutlined: <Icons.ArrowRightOutlined />,
    ArrowUpOutlined: <Icons.ArrowUpOutlined />,
    ArrowsAltOutlined: <Icons.ArrowsAltOutlined />,
    AudioMutedOutlined: <Icons.AudioMutedOutlined />,
    AudioOutlined: <Icons.AudioOutlined />,
    AuditOutlined: <Icons.AuditOutlined />,
    BackwardOutlined: <Icons.BackwardOutlined />,
    BankOutlined: <Icons.BankOutlined />,
    BarChartOutlined: <Icons.BarChartOutlined />,
    BarcodeOutlined: <Icons.BarcodeOutlined />,
    BarsOutlined: <Icons.BarsOutlined />,
    BehanceOutlined: <Icons.BehanceOutlined />,
    BehanceSquareOutlined: <Icons.BehanceSquareOutlined />,
    BellOutlined: <Icons.BellOutlined />,
    BgColorsOutlined: <Icons.BgColorsOutlined />,
    BlockOutlined: <Icons.BlockOutlined />,
    BoldOutlined: <Icons.BoldOutlined />,
    BookOutlined: <Icons.BookOutlined />,
    BorderBottomOutlined: <Icons.BorderBottomOutlined />,
    BorderHorizontalOutlined: <Icons.BorderHorizontalOutlined />,
    BorderInnerOutlined: <Icons.BorderInnerOutlined />,
    BorderLeftOutlined: <Icons.BorderLeftOutlined />,
    BorderOuterOutlined: <Icons.BorderOuterOutlined />,
    BorderOutlined: <Icons.BorderOutlined />,
    BorderRightOutlined: <Icons.BorderRightOutlined />,
    BorderTopOutlined: <Icons.BorderTopOutlined />,
    BorderVerticleOutlined: <Icons.BorderVerticleOutlined />,
    BorderlessTableOutlined: <Icons.BorderlessTableOutlined />,
    BoxPlotOutlined: <Icons.BoxPlotOutlined />,
    BranchesOutlined: <Icons.BranchesOutlined />,
    BugOutlined: <Icons.BugOutlined />,
    BuildOutlined: <Icons.BuildOutlined />,
    BulbOutlined: <Icons.BulbOutlined />,
    CalculatorOutlined: <Icons.CalculatorOutlined />,
    CalendarOutlined: <Icons.CalendarOutlined />,
    CameraOutlined: <Icons.CameraOutlined />,
    CarOutlined: <Icons.CarOutlined />,
    CaretDownOutlined: <Icons.CaretDownOutlined />,
    CaretLeftOutlined: <Icons.CaretLeftOutlined />,
    CaretRightOutlined: <Icons.CaretRightOutlined />,
    CaretUpOutlined: <Icons.CaretUpOutlined />,
    CarryOutOutlined: <Icons.CarryOutOutlined />,
    CheckCircleOutlined: <Icons.CheckCircleOutlined />,
    CheckOutlined: <Icons.CheckOutlined />,
    CheckSquareOutlined: <Icons.CheckSquareOutlined />,
    ChromeOutlined: <Icons.ChromeOutlined />,
    CiCircleOutlined: <Icons.CiCircleOutlined />,
    CiOutlined: <Icons.CiOutlined />,
    ClearOutlined: <Icons.ClearOutlined />,
    ClockCircleOutlined: <Icons.ClockCircleOutlined />,
    CloseCircleOutlined: <Icons.CloseCircleOutlined />,
    CloseOutlined: <Icons.CloseOutlined />,
    CloseSquareOutlined: <Icons.CloseSquareOutlined />,
    CloudDownloadOutlined: <Icons.CloudDownloadOutlined />,
    CloudOutlined: <Icons.CloudOutlined />,
    CloudServerOutlined: <Icons.CloudServerOutlined />,
    CloudSyncOutlined: <Icons.CloudSyncOutlined />,
    CloudUploadOutlined: <Icons.CloudUploadOutlined />,
    ClusterOutlined: <Icons.ClusterOutlined />,
    CodeOutlined: <Icons.CodeOutlined />,
    CodeSandboxOutlined: <Icons.CodeSandboxOutlined />,
    CodepenCircleOutlined: <Icons.CodepenCircleOutlined />,
    CodepenOutlined: <Icons.CodepenOutlined />,
    CoffeeOutlined: <Icons.CoffeeOutlined />,
    ColumnHeightOutlined: <Icons.ColumnHeightOutlined />,
    ColumnWidthOutlined: <Icons.ColumnWidthOutlined />,
    CommentOutlined: <Icons.CommentOutlined />,
    CompassOutlined: <Icons.CompassOutlined />,
    CompressOutlined: <Icons.CompressOutlined />,
    ConsoleSqlOutlined: <Icons.ConsoleSqlOutlined />,
    ContactsOutlined: <Icons.ContactsOutlined />,
    ContainerOutlined: <Icons.ContainerOutlined />,
    ControlOutlined: <Icons.ControlOutlined />,
    CopyOutlined: <Icons.CopyOutlined />,
    CopyrightCircleOutlined: <Icons.CopyrightCircleOutlined />,
    CopyrightOutlined: <Icons.CopyrightOutlined />,
    CreditCardOutlined: <Icons.CreditCardOutlined />,
    CrownOutlined: <Icons.CrownOutlined />,
    CustomerServiceOutlined: <Icons.CustomerServiceOutlined />,
    DashOutlined: <Icons.DashOutlined />,
    DashboardOutlined: <Icons.DashboardOutlined />,
    DatabaseOutlined: <Icons.DatabaseOutlined />,
    DeleteColumnOutlined: <Icons.DeleteColumnOutlined />,
    DeleteOutlined: <Icons.DeleteOutlined />,
    DeleteRowOutlined: <Icons.DeleteRowOutlined />,
    DeliveredProcedureOutlined: <Icons.DeliveredProcedureOutlined />,
    DeploymentUnitOutlined: <Icons.DeploymentUnitOutlined />,
    DesktopOutlined: <Icons.DesktopOutlined />,
    DiffOutlined: <Icons.DiffOutlined />,
    DingdingOutlined: <Icons.DingdingOutlined />,
    DingtalkOutlined: <Icons.DingtalkOutlined />,
    DisconnectOutlined: <Icons.DisconnectOutlined />,
    DislikeOutlined: <Icons.DislikeOutlined />,
    DollarCircleOutlined: <Icons.DollarCircleOutlined />,
    DollarOutlined: <Icons.DollarOutlined />,
    DotChartOutlined: <Icons.DotChartOutlined />,
    DoubleLeftOutlined: <Icons.DoubleLeftOutlined />,
    DoubleRightOutlined: <Icons.DoubleRightOutlined />,
    DownCircleOutlined: <Icons.DownCircleOutlined />,
    DownOutlined: <Icons.DownOutlined />,
    DownSquareOutlined: <Icons.DownSquareOutlined />,
    DownloadOutlined: <Icons.DownloadOutlined />,
    DragOutlined: <Icons.DragOutlined />,
    DribbbleOutlined: <Icons.DribbbleOutlined />,
    DribbbleSquareOutlined: <Icons.DribbbleSquareOutlined />,
    DropboxOutlined: <Icons.DropboxOutlined />,
    EditOutlined: <Icons.EditOutlined />,
    EllipsisOutlined: <Icons.EllipsisOutlined />,
    EnterOutlined: <Icons.EnterOutlined />,
    EnvironmentOutlined: <Icons.EnvironmentOutlined />,
    EuroCircleOutlined: <Icons.EuroCircleOutlined />,
    EuroOutlined: <Icons.EuroOutlined />,
    ExceptionOutlined: <Icons.ExceptionOutlined />,
    ExclamationCircleOutlined: <Icons.ExclamationCircleOutlined />,
    ExclamationOutlined: <Icons.ExclamationOutlined />,
    ExpandAltOutlined: <Icons.ExpandAltOutlined />,
    ExpandOutlined: <Icons.ExpandOutlined />,
    ExperimentOutlined: <Icons.ExperimentOutlined />,
    ExportOutlined: <Icons.ExportOutlined />,
    EyeInvisibleOutlined: <Icons.EyeInvisibleOutlined />,
    EyeOutlined: <Icons.EyeOutlined />,
    FacebookOutlined: <Icons.FacebookOutlined />,
    FallOutlined: <Icons.FallOutlined />,
    FastBackwardOutlined: <Icons.FastBackwardOutlined />,
    FastForwardOutlined: <Icons.FastForwardOutlined />,
    FieldBinaryOutlined: <Icons.FieldBinaryOutlined />,
    FieldNumberOutlined: <Icons.FieldNumberOutlined />,
    FieldStringOutlined: <Icons.FieldStringOutlined />,
    FieldTimeOutlined: <Icons.FieldTimeOutlined />,
    FileAddOutlined: <Icons.FileAddOutlined />,
    FileDoneOutlined: <Icons.FileDoneOutlined />,
    FileExcelOutlined: <Icons.FileExcelOutlined />,
    FileExclamationOutlined: <Icons.FileExclamationOutlined />,
    FileGifOutlined: <Icons.FileGifOutlined />,
    FileImageOutlined: <Icons.FileImageOutlined />,
    FileJpgOutlined: <Icons.FileJpgOutlined />,
    FileMarkdownOutlined: <Icons.FileMarkdownOutlined />,
    FileOutlined: <Icons.FileOutlined />,
    FilePdfOutlined: <Icons.FilePdfOutlined />,
    FilePptOutlined: <Icons.FilePptOutlined />,
    FileProtectOutlined: <Icons.FileProtectOutlined />,
    FileSearchOutlined: <Icons.FileSearchOutlined />,
    FileSyncOutlined: <Icons.FileSyncOutlined />,
    FileTextOutlined: <Icons.FileTextOutlined />,
    FileUnknownOutlined: <Icons.FileUnknownOutlined />,
    FileUnknownTwoTone: <Icons.FileUnknownTwoTone />,
    FileWordOutlined: <Icons.FileWordOutlined />,
    FileZipOutlined: <Icons.FileZipOutlined />,
    FilterOutlined: <Icons.FilterOutlined />,
    FireOutlined: <Icons.FireOutlined />,
    FlagOutlined: <Icons.FlagOutlined />,
    FolderAddOutlined: <Icons.FolderAddOutlined />,
    FolderOpenOutlined: <Icons.FolderOpenOutlined />,
    FolderOutlined: <Icons.FolderOutlined />,
    FolderViewOutlined: <Icons.FolderViewOutlined />,
    FontColorsOutlined: <Icons.FontColorsOutlined />,
    FontSizeOutlined: <Icons.FontSizeOutlined />,
    ForkOutlined: <Icons.ForkOutlined />,
    FormOutlined: <Icons.FormOutlined />,
    FormatPainterOutlined: <Icons.FormatPainterOutlined />,
    ForwardOutlined: <Icons.ForwardOutlined />,
    FrownOutlined: <Icons.FrownOutlined />,
    FullscreenExitOutlined: <Icons.FullscreenExitOutlined />,
    FullscreenOutlined: <Icons.FullscreenOutlined />,
    FunctionOutlined: <Icons.FunctionOutlined />,
    FundOutlined: <Icons.FundOutlined />,
    FundProjectionScreenOutlined: <Icons.FundProjectionScreenOutlined />,
    FundViewOutlined: <Icons.FundViewOutlined />,
    FunnelPlotOutlined: <Icons.FunnelPlotOutlined />,
    GatewayOutlined: <Icons.GatewayOutlined />,
    GifOutlined: <Icons.GifOutlined />,
    GiftOutlined: <Icons.GiftOutlined />,
    GithubOutlined: <Icons.GithubOutlined />,
    GitlabOutlined: <Icons.GitlabOutlined />,
    GlobalOutlined: <Icons.GlobalOutlined />,
    GoldOutlined: <Icons.GoldOutlined />,
    GoogleOutlined: <Icons.GoogleOutlined />,
    GooglePlusOutlined: <Icons.GooglePlusOutlined />,
    GroupOutlined: <Icons.GroupOutlined />,
    HddOutlined: <Icons.HddOutlined />,
    HeartOutlined: <Icons.HeartOutlined />,
    HeatMapOutlined: <Icons.HeatMapOutlined />,
    HighlightOutlined: <Icons.HighlightOutlined />,
    HistoryOutlined: <Icons.HistoryOutlined />,
    HolderOutlined: <Icons.HolderOutlined />,
    HomeOutlined: <Icons.HomeOutlined />,
    HourglassOutlined: <Icons.HourglassOutlined />,
    Html5Outlined: <Icons.Html5Outlined />,
    IdcardOutlined: <Icons.IdcardOutlined />,
    IeOutlined: <Icons.IeOutlined />,
    ImportOutlined: <Icons.ImportOutlined />,
    InboxOutlined: <Icons.InboxOutlined />,
    InfoCircleOutlined: <Icons.InfoCircleOutlined />,
    InfoOutlined: <Icons.InfoOutlined />,
    InsertRowAboveOutlined: <Icons.InsertRowAboveOutlined />,
    InsertRowBelowOutlined: <Icons.InsertRowBelowOutlined />,
    InsertRowLeftOutlined: <Icons.InsertRowLeftOutlined />,
    InsertRowRightOutlined: <Icons.InsertRowRightOutlined />,
    InstagramOutlined: <Icons.InstagramOutlined />,
    InsuranceOutlined: <Icons.InsuranceOutlined />,
    InteractionOutlined: <Icons.InteractionOutlined />,
    IssuesCloseOutlined: <Icons.IssuesCloseOutlined />,
    ItalicOutlined: <Icons.ItalicOutlined />,
    KeyOutlined: <Icons.KeyOutlined />,
    LaptopOutlined: <Icons.LaptopOutlined />,
    LayoutOutlined: <Icons.LayoutOutlined />,
    LeftCircleOutlined: <Icons.LeftCircleOutlined />,
    LeftOutlined: <Icons.LeftOutlined />,
    LeftSquareOutlined: <Icons.LeftSquareOutlined />,
    LikeOutlined: <Icons.LikeOutlined />,
    LineChartOutlined: <Icons.LineChartOutlined />,
    LineHeightOutlined: <Icons.LineHeightOutlined />,
    LineOutlined: <Icons.LineOutlined />,
    LinkOutlined: <Icons.LinkOutlined />,
    LinkedinOutlined: <Icons.LinkedinOutlined />,
    Loading3QuartersOutlined: <Icons.Loading3QuartersOutlined />,
    LoadingOutlined: <Icons.LoadingOutlined />,
    LockOutlined: <Icons.LockOutlined />,
    LoginOutlined: <Icons.LoginOutlined />,
    LogoutOutlined: <Icons.LogoutOutlined />,
    MacCommandOutlined: <Icons.MacCommandOutlined />,
    MailOutlined: <Icons.MailOutlined />,
    ManOutlined: <Icons.ManOutlined />,
    MedicineBoxOutlined: <Icons.MedicineBoxOutlined />,
    MediumOutlined: <Icons.MediumOutlined />,
    MediumWorkmarkOutlined: <Icons.MediumWorkmarkOutlined />,
    MehOutlined: <Icons.MehOutlined />,
    MenuFoldOutlined: <Icons.MenuFoldOutlined />,
    MenuOutlined: <Icons.MenuOutlined />,
    MenuUnfoldOutlined: <Icons.MenuUnfoldOutlined />,
    MergeCellsOutlined: <Icons.MergeCellsOutlined />,
    MessageOutlined: <Icons.MessageOutlined />,
    MinusCircleOutlined: <Icons.MinusCircleOutlined />,
    MinusOutlined: <Icons.MinusOutlined />,
    MinusSquareOutlined: <Icons.MinusSquareOutlined />,
    MobileOutlined: <Icons.MobileOutlined />,
    MoneyCollectOutlined: <Icons.MoneyCollectOutlined />,
    MonitorOutlined: <Icons.MonitorOutlined />,
    MoreOutlined: <Icons.MoreOutlined />,
    NodeCollapseOutlined: <Icons.NodeCollapseOutlined />,
    NodeExpandOutlined: <Icons.NodeExpandOutlined />,
    NodeIndexOutlined: <Icons.NodeIndexOutlined />,
    NotificationOutlined: <Icons.NotificationOutlined />,
    NumberOutlined: <Icons.NumberOutlined />,
    OneToOneOutlined: <Icons.OneToOneOutlined />,
    OrderedListOutlined: <Icons.OrderedListOutlined />,
    PaperClipOutlined: <Icons.PaperClipOutlined />,
    PartitionOutlined: <Icons.PartitionOutlined />,
    PauseCircleOutlined: <Icons.PauseCircleOutlined />,
    PauseOutlined: <Icons.PauseOutlined />,
    PayCircleOutlined: <Icons.PayCircleOutlined />,
    PercentageOutlined: <Icons.PercentageOutlined />,
    PhoneOutlined: <Icons.PhoneOutlined />,
    PicCenterOutlined: <Icons.PicCenterOutlined />,
    PicLeftOutlined: <Icons.PicLeftOutlined />,
    PicRightOutlined: <Icons.PicRightOutlined />,
    PictureOutlined: <Icons.PictureOutlined />,
    PieChartOutlined: <Icons.PieChartOutlined />,
    PlayCircleOutlined: <Icons.PlayCircleOutlined />,
    PlaySquareOutlined: <Icons.PlaySquareOutlined />,
    PlusCircleOutlined: <Icons.PlusCircleOutlined />,
    PlusOutlined: <Icons.PlusOutlined />,
    PlusSquareOutlined: <Icons.PlusSquareOutlined />,
    PoundCircleOutlined: <Icons.PoundCircleOutlined />,
    PoundOutlined: <Icons.PoundOutlined />,
    PoweroffOutlined: <Icons.PoweroffOutlined />,
    PrinterOutlined: <Icons.PrinterOutlined />,
    ProfileOutlined: <Icons.ProfileOutlined />,
    ProjectOutlined: <Icons.ProjectOutlined />,
    PropertySafetyOutlined: <Icons.PropertySafetyOutlined />,
    PullRequestOutlined: <Icons.PullRequestOutlined />,
    PushpinOutlined: <Icons.PushpinOutlined />,
    QqOutlined: <Icons.QqOutlined />,
    QrcodeOutlined: <Icons.QrcodeOutlined />,
    QuestionCircleOutlined: <Icons.QuestionCircleOutlined />,
    QuestionOutlined: <Icons.QuestionOutlined />,
    RadarChartOutlined: <Icons.RadarChartOutlined />,
    RadiusBottomleftOutlined: <Icons.RadiusBottomleftOutlined />,
    RadiusBottomrightOutlined: <Icons.RadiusBottomrightOutlined />,
    RadiusSettingOutlined: <Icons.RadiusSettingOutlined />,
    RadiusUpleftOutlined: <Icons.RadiusUpleftOutlined />,
    RadiusUprightOutlined: <Icons.RadiusUprightOutlined />,
    ReadOutlined: <Icons.ReadOutlined />,
    ReconciliationOutlined: <Icons.ReconciliationOutlined />,
    RedEnvelopeOutlined: <Icons.RedEnvelopeOutlined />,
    RedditOutlined: <Icons.RedditOutlined />,
    RedoOutlined: <Icons.RedoOutlined />,
    ReloadOutlined: <Icons.ReloadOutlined />,
    RestOutlined: <Icons.RestOutlined />,
    RetweetOutlined: <Icons.RetweetOutlined />,
    RightCircleOutlined: <Icons.RightCircleOutlined />,
    RightOutlined: <Icons.RightOutlined />,
    RightSquareOutlined: <Icons.RightSquareOutlined />,
    RiseOutlined: <Icons.RiseOutlined />,
    RobotOutlined: <Icons.RobotOutlined />,
    RocketOutlined: <Icons.RocketOutlined />,
    RollbackOutlined: <Icons.RollbackOutlined />,
    RotateLeftOutlined: <Icons.RotateLeftOutlined />,
    RotateRightOutlined: <Icons.RotateRightOutlined />,
    SafetyCertificateOutlined: <Icons.SafetyCertificateOutlined />,
    SafetyOutlined: <Icons.SafetyOutlined />,
     SaveOutlined: <Icons.SaveOutlined />,
    ScanOutlined: <Icons.ScanOutlined />,
    ScheduleOutlined: <Icons.ScheduleOutlined />,
    ScissorOutlined: <Icons.ScissorOutlined />,
    SearchOutlined: <Icons.SearchOutlined />,
    SecurityScanOutlined: <Icons.SecurityScanOutlined />,
    SelectOutlined: <Icons.SelectOutlined />,
    SendOutlined: <Icons.SendOutlined />,
    SettingOutlined: <Icons.SettingOutlined />,
    ShakeOutlined: <Icons.ShakeOutlined />,
    ShareAltOutlined: <Icons.ShareAltOutlined />,
    ShopOutlined: <Icons.ShopOutlined />,
    ShoppingCartOutlined: <Icons.ShoppingCartOutlined />,
    ShoppingOutlined: <Icons.ShoppingOutlined />,
    ShrinkOutlined: <Icons.ShrinkOutlined />,
    SisternodeOutlined: <Icons.SisternodeOutlined />,
    SketchOutlined: <Icons.SketchOutlined />,
    SkinOutlined: <Icons.SkinOutlined />,
    SkypeOutlined: <Icons.SkypeOutlined />,
    SlackOutlined: <Icons.SlackOutlined />,
    SlackSquareOutlined: <Icons.SlackSquareOutlined />,
    SlidersOutlined: <Icons.SlidersOutlined />,
    SmallDashOutlined: <Icons.SmallDashOutlined />,
    SmileOutlined: <Icons.SmileOutlined />,
    SnippetsOutlined: <Icons.SnippetsOutlined />,
    SolutionOutlined: <Icons.SolutionOutlined />,
    SortAscendingOutlined: <Icons.SortAscendingOutlined />,
    SortDescendingOutlined: <Icons.SortDescendingOutlined />,
    SoundOutlined: <Icons.SoundOutlined />,
    SplitCellsOutlined: <Icons.SplitCellsOutlined />,
    StarOutlined: <Icons.StarOutlined />,
    StepBackwardOutlined: <Icons.StepBackwardOutlined />,
    StepForwardOutlined: <Icons.StepForwardOutlined />,
    StockOutlined: <Icons.StockOutlined />,
    StopOutlined: <Icons.StopOutlined />,
    StrikethroughOutlined: <Icons.StrikethroughOutlined />,
    SubnodeOutlined: <Icons.SubnodeOutlined />,
    SwapLeftOutlined: <Icons.SwapLeftOutlined />,
    SwapOutlined: <Icons.SwapOutlined />,
    SwapRightOutlined: <Icons.SwapRightOutlined />,
    SwitcherOutlined: <Icons.SwitcherOutlined />,
    SyncOutlined: <Icons.SyncOutlined />,
    TableOutlined: <Icons.TableOutlined />,
    TabletOutlined: <Icons.TabletOutlined />,
    TagOutlined: <Icons.TagOutlined />,
    TagsOutlined: <Icons.TagsOutlined />,
    TaobaoCircleOutlined: <Icons.TaobaoCircleOutlined />,
    TaobaoOutlined: <Icons.TaobaoOutlined />,
    TeamOutlined: <Icons.TeamOutlined />,
    ThunderboltOutlined: <Icons.ThunderboltOutlined />,
    ToTopOutlined: <Icons.ToTopOutlined />,
    ToolOutlined: <Icons.ToolOutlined />,
    TrademarkCircleOutlined: <Icons.TrademarkCircleOutlined />,
    TrademarkOutlined: <Icons.TrademarkOutlined />,
    TransactionOutlined: <Icons.TransactionOutlined />,
    TranslationOutlined: <Icons.TranslationOutlined />,
    TrophyOutlined: <Icons.TrophyOutlined />,
    TwitterOutlined: <Icons.TwitterOutlined />,
    UnderlineOutlined: <Icons.UnderlineOutlined />,
    UndoOutlined: <Icons.UndoOutlined />,
    UngroupOutlined: <Icons.UngroupOutlined />,
    UnlockOutlined: <Icons.UnlockOutlined />,
    UnorderedListOutlined: <Icons.UnorderedListOutlined />,
    UpCircleOutlined: <Icons.UpCircleOutlined />,
    UpOutlined: <Icons.UpOutlined />,
    UpSquareOutlined: <Icons.UpSquareOutlined />,
    UploadOutlined: <Icons.UploadOutlined />,
    UsbOutlined: <Icons.UsbOutlined />,
    UserAddOutlined: <Icons.UserAddOutlined />,
    UserDeleteOutlined: <Icons.UserDeleteOutlined />,
    UserOutlined: <Icons.UserOutlined />,
    UserSwitchOutlined: <Icons.UserSwitchOutlined />,
    UsergroupAddOutlined: <Icons.UsergroupAddOutlined />,
    UsergroupDeleteOutlined: <Icons.UsergroupDeleteOutlined />,
    VerifiedOutlined: <Icons.VerifiedOutlined />,
    VerticalAlignBottomOutlined: <Icons.VerticalAlignBottomOutlined />,
    VerticalAlignMiddleOutlined: <Icons.VerticalAlignMiddleOutlined />,
    VerticalAlignTopOutlined: <Icons.VerticalAlignTopOutlined />,
    VerticalLeftOutlined: <Icons.VerticalLeftOutlined />,
    VerticalRightOutlined: <Icons.VerticalRightOutlined />,
    VideoCameraAddOutlined: <Icons.VideoCameraAddOutlined />,
    VideoCameraOutlined: <Icons.VideoCameraOutlined />,
    WalletOutlined: <Icons.WalletOutlined />,
    WarningOutlined: <Icons.WarningOutlined />,
    WechatOutlined: <Icons.WechatOutlined />,
    WeiboCircleOutlined: <Icons.WeiboCircleOutlined />,
    WeiboOutlined: <Icons.WeiboOutlined />,
    WeiboSquareOutlined: <Icons.WeiboSquareOutlined />,
    WhatsAppOutlined: <Icons.WhatsAppOutlined />,
    WifiOutlined: <Icons.WifiOutlined />,
    WindowsOutlined: <Icons.WindowsOutlined />,
    WomanOutlined: <Icons.WomanOutlined />,
    YahooOutlined: <Icons.YahooOutlined />,
    YoutubeOutlined: <Icons.YoutubeOutlined />,
    YuqueOutlined: <Icons.YuqueOutlined />,
    ZhihuOutlined: <Icons.ZhihuOutlined />,
    ZoomInOutlined: <Icons.ZoomInOutlined />,
    ZoomOutOutlined: <Icons.ZoomOutOutlined />,
};

export default Icon;
