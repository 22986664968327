import React from 'react'
import {
    Space,
    Typography,
    Dropdown,
    Avatar,
    MenuProps,
} from "antd";
import {authState} from "../../store";
import {UserOutlined, SettingOutlined} from '@ant-design/icons';
import {useRecoilValue} from "recoil";
import {useNavigate} from 'react-router-dom';
import {Icon} from "../../config/Icon";
import {subText} from "../../utils/utils";

const {Text} = Typography

const TopUser = () => {
    const navigate = useNavigate();
    const user = useRecoilValue<AuthType.Info | null>(authState);

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        if (e.key === '2') {
            navigate('/system/profile')
        }
        if (e.key === '3') {
            sessionStorage.removeItem("token")
            sessionStorage.removeItem("auth")
            sessionStorage.removeItem("role")
            window.location.href='/';
        }
    };

    /**
     * 头像下拉菜单项
     */
    const menuProps = {
        items: [
            {
                label: ' 您好：' + user?.name || '匿名用户',
                key: '1',

            },
            {
                label: '个人中心',
                key: '2',
                icon: <UserOutlined/>
            },
            {
                label: ' 退出登录',
                key: '3',
                icon: <SettingOutlined/>,
            },
        ],
        onClick: handleMenuClick,
    };

    return (
        <Dropdown menu={menuProps} placement="bottomRight" arrow trigger={["click"]}>
            <Space style={{cursor: 'pointer', height: 34}}>
                <Avatar size="small" src={user?.avatar ? user.avatar+'@240X240_r.jpg' : Icon.avatar}/>{user && <Text>{subText(user.name , 3) || '匿名'}</Text>}
            </Space>
        </Dropdown>
    )
}
export default TopUser
