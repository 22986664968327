
/**
 *  验证网址(支持端口和"?+参数"和"#+参数)
 *  @param { string } value
 */
export const isRightWebsite = (value:string) => /^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/g.test(value);

/**
 *  验证统一社会信用代码
 *  @param { string } value
 */
export const isCreditCode = (value:string) => /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/g.test(value);


/**
 *  验证大于等于0, 小于等于150, 支持小数位出现5, 如145.5, 用于判断考卷分数
 *  @param { string } value
 */
export const isGrade = (value:string)=> /^150$|^(?:\d|[1-9]\d|1[0-4]\d)(?:.5)?$/g.test(value);


/**
 *  验证版本号格式必须为X.Y.Z
 *  @param { string } value
 */
export const isVersion = (value:string) => /^\d+(?:\.\d+){2}$/g.test(value);

/**
 *  验证视频链接地址（视频格式可按需增删）
 *  @param { string } value
 */
export const isVideoUrl = (value:string) => /^https?:\/\/(.+\/)+.+(\.(swf|avi|flv|mpg|rm|mov|wav|asf|3gp|mkv|rmvb|mp4))$/i.test(value);

/**
 *  验证图片链接地址（图片格式可按需增删）
 *  @param { string } value
 */
export const isImageUrl = (value:string) => /^https?:\/\/(.+\/)+.+(\.(gif|png|jpg|jpeg|webp|svg|psd|bmp|tif))$/i.test(value);

/**
 *  验证24小时制时间（HH:mm:ss）
 *  @param { string } value
 */
export const is24Hour = (value:string) => /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/g.test(value);

/**
 *  验证12小时制时间（hh:mm:ss）
 *  @param { string } value
 */
export const  is12Hour = (value:string) => /^(?:1[0-2]|0?[1-9]):[0-5]\d:[0-5]\d$/g.test(value);


/**
 *  验证数字/货币金额（支持负数、千分位分隔符）
 * @param { string } value
 */
export const isMoneyAll = (value:string) => /^-?\d+(,\d{3})*(\.\d{1,2})?$/g.test(value);

/**
 *  验证数字/货币金额 (只支持正数、不支持校验千分位分隔符)
 * @param { string } value
 */
export const isMoney = (value:string) => /(?:^[1-9]([0-9]+)?(?:\.[0-9]{1,2})?$)|(?:^(?:0){1}$)|(?:^[0-9]\.[0-9](?:[0-9])?$)/g.test(value);

/**
 *  验证银行卡号（10到30位, 覆盖对公/私账户, 参考微信支付）
 * @param { string } value
 */
export const isAccountNumber = (value:string) => /^[1-9]\d{9,29}$/g.test(value);

/**
 *  验证中文姓名
 * @param { string } value
 */
export const isChineseName = (value:string) => /^(?:[\u4e00-\u9fa5·]{2,16})$/g.test(value);

/**
 *  验证英文姓名
 * @param { string } value
 */
export const isEnglishName = (value:string) => /(^[a-zA-Z]{1}[a-zA-Z\s]{0,20}[a-zA-Z]{1}$)/g.test(value);

/**
 *  验证车牌号(新能源+非新能源)
 * @param { string } value
 */
export const isLicensePlateNumber = (value:string) => /^(?:[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领 A-Z]{1}[A-HJ-NP-Z]{1}(?:(?:[0-9]{5}[DF])|(?:[DF](?:[A-HJ-NP-Z0-9])[0-9]{4})))|(?:[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领 A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9 挂学警港澳]{1})$/g.test(value);

/**
 *  验证手机号中国(宽松), 只要是13,14,15,16,17,18,19开头即可
 * @param { string } value
 */
export const isMPRelaxed = (value:string) => /^(?:(?:\+|00)86)?1[3-9]\d{9}$/g.test(value);


/**
 * 验证日期
 * @param { string } value
 */
export const isDate = (value:string) => /^\d{4}(-)(1[0-2]|0?\d)\1([0-2]\d|\d|30|31)$/g.test(value);

/**
 *  验证email(邮箱)
 * @param { string } value
 */
export const isEmail = (value:string) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(value);

/**
 *  验证座机电话(国内),如: 0341-86091234
 * @param { string } value
 */
export const isLandlineTelephone = (value:string) => /^\d{3}-\d{8}$|^\d{4}-\d{7}$/g.test(value);

/**
 *  身份证号, 支持1/2代(15位/18位数字)
 * @param { string } value
 */
export const isIDCard = (value:string) => /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/g.test(value);

/**
 *  验证护照（包含香港、澳门）
 * @param { string } value
 */
export const isPassport = (value:string) => /(^[EeKkGgDdSsPpHh]\d{8}$)|(^(([Ee][a-fA-F])|([DdSsPp][Ee])|([Kk][Jj])|([Mm][Aa])|(1[45]))\d{7}$)/g.test(value);

/**
 *  验证帐号是否合法(字母开头，允许5-16字节，允许字母数字下划线组合
 * @param { string } value
 */
export const isWebAccount = (value:string) => /^[a-zA-Z]\w{4,15}$/g.test(value);


/**
 *  验证帐号是否合法(字母开头，允许5-16字节，允许字母数字下划线组合
 * @param { string } value
 */
export const isWebEnglish = (value:string) => /^[a-zA-Z]\w{2,30}$/g.test(value);
/**
 *  验证中文/汉字
 * @param { string } value
 */
export const isChinese = (value:string) => /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])+$/g.test(value);

export const isChineseCharacter = (value:string) => /.*[\u4e00-\u9fa5]+.*$/.test(value)



/**
 * 验证小数
 * @param { string } value
 */
export const isDecimal = (value:string) => /^\d+\.\d+$/g.test(value);

/**
 * 验证数字
 * @param { string } value
 */
export const isNumber = (value:string) => /^\d{1,}$/g.test(value);


/**
 *  验证数字和字母组成
 * @param { string } value
 */
export const isNumAndStr = (value:string) => /^[A-Za-z0-9]+$/g.test(value);

/**
 *  验证英文字母
 * @param { string } value
 */
export const isEnglish = (value:string) => /^[a-zA-Z]+$/g.test(value);

/**
 *  验证大写英文字母
 * @param { string } value
 */
export const isCapital = (value:string) => /^[A-Z]+$/g.test(value);

/**
 * 验证小写英文字母组成
 * @param { string } value
 */
export const isLowercase = (value:string) => /^[a-z]+$/g.test(value);

/**
 * 验证密码强度，最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符
 * @param { string } value
 */
export const isCorrectFormatPassword = (value:string) => /^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@#$%^&*? ])\S*$/g.test(value);

/**
 * 验证用户名，4到16位（字母，数字，下划线，减号）
 * @param { string } value
 */
export const isCorrectFormatUsername = (value:string) => /^[a-zA-Z0-9_-]{4,16}$/g.test(value);

/**
 * 验证邮政编码(中国)
 * @param { string } value
 */
export const isPostcode = (value:string) => /^(0[1-7]|1[0-356]|2[0-7]|3[0-6]|4[0-7]|5[1-7]|6[1-7]|7[0-5]|8[013-6])\d{4}$/g.test(value);

/**
 * 验证中文和数字
 * @param { string } value
 */
export const isCHNAndEN = (value:string) => /^((?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])|(\d))+$/g.test(value);

/**
 * 验证不能包含字母
 * @param { string } value
 */
export const isNoWord = (value:string) => /^[^A-Za-z]*$/g.test(value);

/**
 * 验证坐标
 * @param { string } value
 */
export const isLng = (value:string) =>  /^-?((0|1?[0-8]?[0-9]?)(([.][0-9]{1,10})?)|180(([.][0]{1,10})?))$/.test(value);
export const isLat = (value:string) =>  /^-?((0|[1-8]?[0-9]?)(([.][0-9]{1,10})?)|90(([.][0]{1,10})?))$/.test(value);
/**
 * 名称正则校验，规则：名称支持中文、英文、数字以及符号：_（）—.- 且不能以 . 开头
 * @param { string } value
 */
export const nameReg = (value:string) => {
    return value.match(/^(?![.])[\u4e00-\u9fa5_a-zA-Z0-9-—()（）.]+$/g)
}