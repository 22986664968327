/**
 * Notes: 个人资料
 * User: 李强
 * DateTime: 2021/12/10 10:29
 * Ide: PhpStorm
 */

import React, {useState} from 'react'
import {
    Descriptions,
    Button,
    Modal,
    Spin,
    Skeleton,
    Card, Typography, Tag, Form, Input, Radio, Row, Col, Cascader, App, Flex
} from 'antd';

import {useParams} from 'react-router-dom'
import {useDict, useHttps} from "../../../hooks";
import {cityTreeData} from "../../../utils/city";
import dayjs from "dayjs";
import {isCorrectFormatPassword, isMPRelaxed} from "../../../utils/check";
import {FileSelect} from "../../../components";
import {useRecoilState} from "recoil";
import {authState} from "../../../store";
const {Title, Text} = Typography

/*
  用户管理
*/
const Index = () => {
    const {message} = App.useApp();
    const {id} = useParams<any>();
    const [loading, setLoading] = useState(false)
    const [spin, setSpin] = useState(false);
    const [avatarSpin, setAvatarSpin] = useState(false);
    const {getDict} = useDict();
    const {put} = useHttps();
    const [form] = Form.useForm();
    const [passOpen, setPassOpen] = useState(false);
    const [passError, setPassError] = useState('');
    const [pass, setPass] = useState('');
    const [user, setUser] = useRecoilState(authState)

    /**
     *
     * @param params
     */
    const onUpdate = (params:any) => {
        setSpin(true)
        put('admin/profile', params)
            .then(async (res:Request.Response<AuthType.Info>) => {
                if (res.mounted) {
                    setSpin(false)
                    sessionStorage.setItem("auth", JSON.stringify(res.data))
                    sessionStorage.setItem("role", JSON.stringify(res.data.roles));
                    setUser(res.data);
                    message.success('资料更新成功！')
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    message.error(e.message);
                    setSpin(false)
                }
            });
    };
    const onPassword = () => {
        const isValue = isCorrectFormatPassword(pass)
        if (!isValue) {
            setPassError('密码格式不正确！')
            return
        }
        setPassOpen(false)
        setLoading(true)
        put('admin/profile/password/' + id, {password: pass})
            .then(async (res: Request.Response<SystemType.Data>) => {
                if (res.mounted) {
                    setLoading(false)
                    message.success('密码更新成功！');
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false)
                    message.error(e.message);
                }
            });
    }

    /**
     * 更新头像
     * @param params
     */
    const onAvatar = (params:StorageType.Data[]) => {
        if (params.length !== 0) {
            setAvatarSpin(true)
            put('admin/profile/avatar', params[0].url)
                .then(async (res: Request.Response<SystemType.Data>) => {
                    if (res.mounted) {
                        const avatar:any = {...user, ...{avatar: params[0].url}}
                        sessionStorage.setItem("auth", JSON.stringify(avatar))
                        setUser(avatar)
                        setAvatarSpin(false)
                        message.success('头像更新成功！');
                    }
                })
                .catch(async (e: Request.Error) => {
                    if (e.mounted) {
                        setAvatarSpin(false)
                        message.error(e.message);
                    }
                });
        }
    }




    /**
     * 加载骨架
     */
    if (loading) {
        return (
            <Card>

                <Flex align='flex-start'>
                    <div style={{width: 320}}>
                        <Skeleton.Input className='mb-4 d-block'/>
                        <Skeleton.Avatar size={110} shape='square' className='mb-4'/>
                        <Skeleton/>
                    </div>
                    <div className='px-5 flex-grow-1'>
                        <Skeleton.Input className='mb-4 d-block'/>
                        <Skeleton className='mb-4'/>
                        <Skeleton.Input className='mb-4 d-block'/>
                        <Skeleton paragraph={{rows: 6}}/>
                    </div>
                    <div className='px-5'>
                        <Skeleton.Input className='mb-4 d-block'/>
                    </div>
                </Flex>
            </Card>
        )
    }

    return (

            <Spin size="large" spinning={spin}>
                <Row gutter={16}>
                    <Col flex="0 1 420px">
                        <Card title='基本信息'>
                            <div className='text-center mb-5'>
                                <Spin spinning={avatarSpin}>
                                    <FileSelect
                                        defaultFileList={user?.avatar ? [{
                                            id: '-1',
                                            title: 'avatar.png',
                                            type: 1,
                                            mini_type: 'jpg',
                                            url: user.avatar,
                                            parent_id: '0',
                                            size: 100,
                                            created_at: new Date()
                                        }] : []}
                                        listType='picture-card'
                                        text='上传头像'
                                        size={1}
                                        type={1}
                                        onChange={onAvatar}
                                    />
                                </Spin>



                                <Title level={5}>{user?.name}</Title>
                                {user?.department && <Text type='secondary'>{user.department.name}</Text>}

                            </div>
                        <Descriptions column={1} labelStyle={{
                            width: 80,
                            marginBottom: 10
                        }}>

                            <Descriptions.Item label="用户ID">{user?.id}</Descriptions.Item>
                            <Descriptions.Item label="登录名">{user?.username}</Descriptions.Item>
                            <Descriptions.Item label="职称">{user?.position}</Descriptions.Item>
                            <Descriptions.Item label="所属角色">  {user?.roles?.map((item) => <Tag key={item.id} color="green">{item.name}</Tag>)}</Descriptions.Item>
                            <Descriptions.Item label="创建日期">{dayjs(user?.created_at).format('YYYY-MM-DD')}</Descriptions.Item>
                        </Descriptions>
                        </Card>
                    </Col>


                    <Col flex="1 1 200px">
                        <Card title='编辑个人资料' extra={
                            <Button onClick={() => setPassOpen(true)}>
                                修改密码
                            </Button>
                       }>

                                <Form
                                    form={form}
                                    labelCol={{span: 4}}
                                    wrapperCol={{span: 16}}
                                    name="basic_profile"
                                    onFinish={onUpdate}
                                    initialValues={{
                                        name : user?.name,
                                        gender : user?.gender,
                                        mobile : user?.mobile,
                                        card_id : user?.card_id,
                                        city : user?.city,
                                        address: user?.address,
                                        email: user?.email,
                                        description: user?.description,
                                    }}
                                >

                                    <Form.Item
                                        label="姓名"
                                        name="name"
                                        rules={[{required: true, message: '请输入姓名！'}]}
                                    >
                                        <Input style={{width: 200}} placeholder='输入真实姓名'/>
                                    </Form.Item>
                                    <Form.Item
                                        label="性别"
                                        name="gender"
                                    >
                                        <Radio.Group>
                                            {getDict('gender').map((item) => <Radio key={item.value} value={item.value}>{item.label}</Radio>)}
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label="手机号"
                                        name="mobile"
                                        rules={[{
                                            required: false, validator: async (rule, value) => {
                                                const isValue = isMPRelaxed(value)
                                                if (!isValue) {
                                                    await Promise.reject('请输入正确手机号！')
                                                }
                                                await Promise.resolve()
                                            }
                                        }]}
                                    >
                                        <Input style={{width: 200}} placeholder='输入手机号码'/>
                                    </Form.Item>
                                    <Form.Item
                                        wrapperCol={{span: 5}}
                                        label="身份证号码"
                                        name="card_id"
                                    >
                                        <Input maxLength={20} placeholder='请填写身份证号码！'/>
                                    </Form.Item>
                                    <Form.Item
                                        label="所在城市"
                                        name="city"
                                        wrapperCol={{span: 5}}
                                    >
                                        <Cascader
                                            placeholder="选择所在城市"
                                            options={cityTreeData}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="详细地址"
                                        wrapperCol={{span:8}}
                                        name="address"
                                    >
                                        <Input maxLength={50} placeholder="请输入精准到门牌号"/>
                                    </Form.Item>
                                    <Form.Item
                                        wrapperCol={{span: 5}}
                                        label="企业邮箱"
                                        name="email"
                                    >
                                        <Input type='email' maxLength={20} placeholder='请填写邮箱地址！'/>
                                    </Form.Item>
                                    <Form.Item name='description' label="个人介绍">
                                        <Input.TextArea/>
                                    </Form.Item>
                                    <Form.Item wrapperCol={{offset: 4}}>
                                        <Button className='me-3' type="primary" htmlType="submit">
                                            保存
                                        </Button>
                                    </Form.Item>
                                </Form>
                        </Card>

                    </Col>
                </Row>
                <Modal title="修改密码" open={passOpen} centered onOk={onPassword} onCancel={() => {
                    setPassError('')
                    setPassOpen(false)
                }}>
                    <Input.Password value={pass} onChange={(e) => setPass(e.target.value)} placeholder="输入登陆密码"/>
                    <div className='mt-2'>
                        <div className='mb-2 text-muted fs-7'>最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符
                        </div>
                        <Text type="danger">{passError}</Text>
                    </div>
                </Modal>
            </Spin>


    )
}

export default Index

