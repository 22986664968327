import avatar from '../assets/default-avatar.png';
import loginWechat from '../assets/login-wechat.png';
import loginTiktok from '../assets/login-tiktok.png';
import loginMobile from '../assets/login-mobile.png';
import fileType from '../assets/file-type.png';
import map from '../assets/map-48.png';
import message from '../assets/message.png';
import notice from '../assets/notice.png';
import openAiLogo from '../assets/logo-openai.png';
import discordLogo from '../assets/discord.png';
import placeholderImage from '../assets/placeholder-image.png';

export const Icon = {
    fileType,
    map,
    message,
    notice,
    avatar,
    loginWechat,
    loginTiktok,
    loginMobile,
    openAiLogo,
    discordLogo,
    placeholderImage,
}

