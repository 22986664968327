/**
 * Notes: video.js 播放器
 * User: 李强
 * DateTime: 2021/10/7 11:26 上午
 * Ide: PhpStorm
 */
import React, {useImperativeHandle, useState, useRef, forwardRef} from "react";

import {Modal} from "antd";
import {subText} from "../../utils/utils";

interface VideoProps {
    src?:string;

    open?:boolean;

    title?:string;

    autoPlay?:boolean;

    type?:number
}


const Video = forwardRef((props:VideoProps,ref ) => {
    const videoRef = useRef<any>(null);
    const [open, setOpen] = useState(false);
    const [src, setSrc] = useState(props.src)
    const [title, setTitle] = useState(props.title)
    const [type, setType] = useState(props.type)

    useImperativeHandle(ref, () => ({
        // changeVal 就是暴露给父组件的方法
        onOpen: (src:string , title:string, type = 1) => {
            setSrc(src)
            setTitle(title)
            setType(type)
            setOpen(true);
            if (videoRef.current){
                videoRef.current.play();
            }
        },
    }));

    const onClose = () => {
        if (videoRef.current){
            videoRef.current.pause();
        }
    }

    const onCancel = () => {
        setOpen(false);
    };

    return (
        <Modal destroyOnClose title={subText(title||'', 20)} width='auto' centered maskClosable={true}  open={open} footer={null}  afterClose={onClose} onCancel={onCancel}>
                {type === 1 ? <video ref={videoRef} src={src} controls autoPlay={props.autoPlay} className='w-auto' height={580} /> : <audio className='m-3' style={{width:400}} autoPlay={props.autoPlay} controls  ref={videoRef} src={src}></audio> }
        </Modal>
    );
})

export default Video;
