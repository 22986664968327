/**
 * Created by devin on 2021/4/21.
 */

import React, {useEffect, useState, useCallback, useRef} from 'react'

import {
    Badge,
    Button,
    Divider,
    Input,
    message,
    Select,
    Space,
    Table,
    Typography,
    Avatar,
    Card, Tag
} from 'antd';
import type {ColumnsType, TablePaginationConfig} from 'antd/es/table';
import {Icon} from '../../config/Icon';

import {useDict, useHttps} from "../../hooks";
import {hideMPStrict} from "../../utils/utils";
import dayjs from "dayjs";
import Account from './Account'

const {Text} = Typography;


interface UserParams {
    page?: number;
    per_page?: number;
    start_date?: string;
    end_date?: string;
    status?: string;
    search?: string;
    tag_id?: number;
    platform?: string;
}

/*
  用户管理
*/
const User = () => {
    const {get} = useHttps();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<Array<UserType.Data>>([])
    const [total, setTotal] = useState(0)
    const [current, setCurrent] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [selectPlatform, setSelectPlatform] = useState("non")
    const [selectTags, setSelectTags] = useState(0)
    const [selectDate, setSelectDate] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>()
    const [selectStatus, setSelectStatus] = useState('-1')
    const {getDictValue} = useDict();
    const accountRef =useRef<any>()
    /**
     * 数据请求
     * @param {Object} params
     */
    const getData = useCallback((params:any) => {
        get('admin/users', params, true)
            .then((res: Request.Response<UserType.Data[]>) => {
                if (res.mounted) {
                    setLoading(false);
                    setCurrent(res.meta.current_page)
                    setTotal(res.meta.total)
                    setPerPage(res.meta.per_page)
                    setData(res.data);
                }
            })
            .catch(async (e: Request.Error) => {
                if (e.mounted) {
                    setLoading(false);
                    message.error(e.message)
                }
            });
    }, [get])

    useEffect(() => {
        getData({page: 1, per_page: 10});
    }, [getData]);




    /**
     * 表格操作触发
     * @param pagination
     */
    const onPaginationChange = (pagination: TablePaginationConfig) => {

        const params: UserParams = {
            page: pagination.current,
            per_page: pagination.pageSize,
        }
        //按日期区间查询
        if (selectDate) {
            const startDate = selectDate[0]?.format('YYYY-MM-DD');
            const endDate = selectDate[1]?.format('YYYY-MM-DD');
            params.start_date = startDate
            params.end_date = endDate
        }
        //按状态查询
        if (selectStatus !== '-1') {
            params.status = selectStatus;
        }

        //搜索
        if (search.length !== 0) {
            params.search = search;
        }

        //按设备查询
        if (selectTags !== 0) {
            params.tag_id = selectTags;
        }
        //按平台查询
        if (selectPlatform !== "non") {
            params.platform = selectPlatform;
        }
        setLoading(true);
        getData(params);
    }



    /**
     * 重置
     */
    const onReset = () => {
        setSelectDate(null)
        setSelectStatus('-1')
        setSearch('')
        setSelectTags(0)
        setSelectPlatform("non")
        setLoading(true)
        getData({page: 1, per_page: perPage});
    }



    /**
     * 自定义表格
     * @type {Object}
     */

    const columns: ColumnsType<UserType.Data> = [
        {
            title: '用户名',
            dataIndex: 'nickname',
            render: (nickname, item) => {
                return (
                        <Space className='cursor-pointer'  onClick={() => accountRef.current.onOpen(item.id)}>
                            <Badge color="blue" dot={item.card_id != null} >
                                <Avatar size="large" shape='square' src={item.avatar ? item.avatar : Icon.avatar}/>
                            </Badge>
                            <Text>{nickname}</Text>
                        </Space>

                )
            }
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            render: (mobile) => {
                return (
                    <Text>{hideMPStrict(mobile)}</Text>
                )
            }
        },
        {
            title: '设备型号',
            dataIndex: 'platform',
            render: () => {
                return (
                    <Text>未知</Text>

                )
            }
        },
        {
            title: '设备ID',
            dataIndex: 'platform',
            render: () => {
                return (
                    <Text>未知</Text>

                )
            }
        },

        {
            title: '状态',
            dataIndex: 'status',
            render: (status) => {
                const dict = getDictValue('user_status', status)
                return (
                    <Tag color={dict?.style}>{dict?.label}</Tag>
                )
            }
        },
        {
            title: '注册日期',
            dataIndex: 'created_at',
        },
    ];

    return (
        <Card>
            <div className='mb-4 w-100'>
                            <Space>
                                <Select style={{width:150}} defaultValue={'-1'} value={selectStatus} onChange={setSelectStatus}>
                                    <Select.Option value={'-1'}>全部用户</Select.Option>
                                </Select>
                                <Select style={{width:150}} defaultValue={0} value={selectTags} onChange={setSelectTags}>
                                    <Select.Option value={0}>全部标签</Select.Option>
                                </Select>
                                <Select style={{width:150}}  defaultValue={"non"} value={selectPlatform} onChange={setSelectPlatform}>
                                    <Select.Option value={"non"}>全部型号</Select.Option>
                                </Select>
                                <Input.Search
                                    value={search}
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                    onSearch={() => {
                                        onPaginationChange( {current: 1, pageSize: perPage})
                                    }}
                                    placeholder="手机号、昵称"
                                    allowClear
                                    enterButton="筛选"
                                />
                                <Button onClick={onReset}>
                                    重置
                                </Button>
                            </Space>
                <Divider/>

                <Table
                    size="middle"
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        size: 'default',
                        total: total,
                        current: current,
                        showTotal: total => `总计 ${total} 位用户`,
                        pageSize: perPage,
                    }}
                    onChange={(pagination) => onPaginationChange(pagination)}
                    rowKey={(record) => record.id.toString()}
                />
            </div>
            <Account ref={accountRef} />
        </Card>
    )
}

export default User
