
import code from '../assets/icon/type-code.png';
import excel from '../assets/icon/type-excel.png';
import img from '../assets/icon/type-img.png';
import pdf from '../assets/icon/type-pdf.png';
import music from '../assets/icon/type-music.png';
import other from '../assets/icon/type-other.png';
import ppt from '../assets/icon/type-ppt.png';
import txt from '../assets/icon/type-txt.png';
import word from '../assets/icon/type-word.png';
import zip from '../assets/icon/type-zip.png';
import video from '../assets/icon/type-video.png';
import wps from '../assets/icon/type-wps.png';
import file from '../assets/icon/type-file.png';
import {base64ToFile, urlToBase64} from "../utils/utils";
import {useElectron} from "./index";



 const useFile = () => {

     const imgType = ["bmp", "jpg", "jpeg", "png", "tiff", "gif", "svg", "webp"]
     const videoType = ["mp4", "avi", "mov", "wmv", "asf", "navi", "3gp", "mkv", "f4v", "rmvb", "webm"]
     const musicType = ["mp3", "wma", "wav", "mod", "ra", "cd", "md", "asf", "aac", "vqf", "ape", "mid", "ogg", "m4a", "vqf"]
     const docType = ["txt", "doc", "docx", "xls", "rtf", "wpd", "pdf", "ppt","csv","pptx"]
     const zipType = ["zip", "rar", "7z", "bz"];
     const fontType = ["ttf", "woff", "woff2", "eot"];
     const codeType = ["html", "htm", "js", "java", "php", "css", "go", "py", "ts", "c"]

     const {saveFile,isElectron} = useElectron();

     const getIcon  = (type:string, suffix =false) => {
         type = suffix ? getTypeName(type) : type;
         if (type === 'file') {
             return file
         }
         if (imgType.includes(type)) {
             return img
         }
         if (videoType.includes(type)) {
             return video
         }
         if (musicType.includes(type)) {
             return music
         }
         if (type === 'txt') {
             return txt
         }
         if (type === 'wpd') {
             return wps
         }
         if (type === 'pdf') {
             return pdf
         }
         if (type === 'doc' || type === 'docx') {
             return word
         }
         if (type === 'ppt' || type === 'ppt') {
             return ppt
         }
         if (type === 'xls' || type === 'csv') {
             return excel
         }
         const zipType = ["zip", "rar", "7z", "bz"]
         if (zipType.includes(type)) {
             return zip
         }
         const codeType = ["html", "htm", "js", "java", "php", "css", "go", "py", "ts", "c"]
         if (codeType.includes(type)) {
             return code
         }
         return other;
     }



     const getFileType = (url:string) => {
         const type = getTypeName(url);
         if (imgType.includes(type)) {
             return 1
         }
         if (videoType.includes(type)) {
             return 2
         }
         if (docType.includes(type)) {
             return 3
         }
         if (musicType.includes(type)) {
             return 4
         }
         if (zipType.includes(type)) {
             return 5
         }
         if (fontType.includes(type)) {
             return 6
         }
         if (codeType.includes(type)) {
             return 7
         }
       return 0
     }

     const getTypeName = (url:string) =>{
         return url.slice(url.lastIndexOf(".") + 1);
     }


     const getThumb = (url:string, size="@240x240_r.jpg") =>{
        const type =  getFileType(url);
        if(type === 1){
            return url + size
        }
         if(type === 2){
             return url + "@thumb.jpg"
         }
        return getIcon(url, true);
     }


     const downloadFile = (url: string) => {
         urlToBase64(url).then(async (res) => {
             if (typeof res !== 'string') {
                 return
             }
             const miniType = getTypeName(url)
             if (isElectron) {
                 await saveFile(res,miniType)
             } else {
                 const link = document.createElement('a') // 创建a标签
                 const blob = base64ToFile('data:application/' + miniType + ';base64,' + res, 'data:application/' + miniType)
                 const objectUrl = URL.createObjectURL(blob)
                 const reportName = 'file_' + new Date().getTime() + '.' + miniType
                 link.href = objectUrl
                 link.download = reportName
                 link.click()
                 URL.revokeObjectURL(objectUrl)
             }
         }).catch(async () => {
             return
         });

     }
     const isImage = (url: string) => {
         url = url.slice(url.lastIndexOf(".") +1 );
         return imgType.includes(url);

     }



     return {getIcon, getFileType, getThumb, getTypeName, downloadFile, isImage} ;
}
export default useFile;
