import {useEffect, useRef, useCallback} from 'react';
import {useRecoilValue} from "recoil";
import {tokenState} from "../store";
import {Api} from "../config/Config";



const useFetch = () => {
    const abortController = useRef<any>();
    const mounted = useRef(true);
    const token = useRecoilValue<string | null>(tokenState);
    /**
     * fetch 请求封装
     * @param url
     * @param options
     * @returns {Promise<{[p: string]: *}>}
     */
    const request = async (url: string, options: Request.Option): Promise<Request.Response<any>> => {
        console.log('开始请求')
        let response: any;
        abortController.current = new AbortController();
        try {
            console.log("走到秦秋")
            response = await fetch(url, {
                signal: abortController.current.signal,
                ...options
            });
        } catch (error: any) {
            return Promise.reject({
                code: 0,
                message: error.message,
                status: error.status,
                mounted: mounted.current
            });
        }
        const json = await response.json();

        if (json.status === 401) {
            sessionStorage.removeItem("token")
            sessionStorage.removeItem("auth")
            sessionStorage.removeItem("role")
            window.location.href='/';
        }
        if (json.code === 0 || json.status >= 300) {
            json.message = json.message ? json.message : '网络错误请稍后重试';
            return Promise.reject({...json, mounted: mounted.current});
        }

        return Promise.resolve({
            ...json,
            mounted: mounted.current
        });
    }


    /**
     * 对象转URL字符串
     * @param params
     * @returns {string}
     */
    const urlEncode = (params: [index: string]) => {
        const data = [];
        for (let attr in params) {
            data.push(`${attr}=${params[attr]}`);
        }
        return '?' + data.join('&');
    }


    /**
     * get 请求
     * @type {function(*, *=, *=): Promise<{[p: string]: *}>}
     */
    const get = useCallback((url: string, params?: any | null, auth: boolean = false): Promise<Request.Response<any>> => {
        const config: Request.Option = {
            method: 'GET',
        }
        //如果需要认证
        if (auth) {
            config.headers = {authorization: 'Bearer ' + token,}
        }
        //如果存在参数
        if (params) {
            url = url + urlEncode(params);
        }

        return request(Api + url, config)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])


    /**
     * post 请求
     * @type {(function(*, *=): (Promise<never>))|*}
     */
    const post = useCallback((url: string, params?: any, auth = true): Promise<Request.Response<any>> => {
        //如果不存在参数

        const config: Request.Option = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        }
        if (auth) {
            config.headers.authorization = 'Bearer ' + token;
        }

        if (params) {
            config.body = JSON.stringify(params)
        }

        return request(Api + url, config)
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    /**
     * post 请求
     * @type {(function(*, *=): (Promise<never>))|*}
     */
    const upload = useCallback((url: string, file: any): Promise<Request.Response<any>> => {
        //如果不存在参数
        const config: Request.Option = {
            method: 'POST',
            headers: {
                authorization: 'Bearer ' + token,
            },
            body: file
        }
        return request(Api + url, config)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])



    /**
     * put 请求
     * @type {(function(*, *=): (Promise<never>))|*}
     */
    const put = useCallback((url: string, params?: any): Promise<Request.Response<any>> => {
        //如果不存在参数
        if (!params) {
            return Promise.reject({
                code: 0,
                message: '修改参数未输入！',
                status: 503,
                mounted: mounted.current
            });
        }
        const config: Request.Option = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                authorization: 'Bearer ' + token,
            },
            body: typeof params === 'string' ? params : JSON.stringify(params)
        }
        return request(Api + url, config)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])


    /**
     * delete 请求
     * @type {function(*, *=): Promise<{[p: string]: *}>}
     */
    const destroy = useCallback((url: string, params?: any): Promise<Request.Response<any>> => {
        const config: Request.Option = {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                authorization: 'Bearer ' + token,
            },
        }
        //如果存在参数
        if (params) {
            config.body = JSON.stringify(params);
        }
        return request(Api + url, config)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    /**
     * 取消请求
     */
    const abort = () => {
        mounted.current = false;
        if (abortController.current) {
            abortController.current.abort();
        }
    }


    useEffect(() => {
        return () => abort()
    }, []);
    return {get, post, put, destroy,upload, request, abort};
}


export default useFetch;
